/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function toggleTheme(value: string): void {
	value == 'dark' ? enableDarkTheme() : disableDarkTheme();
	aeroval.settings.theme = value;
}

function enableDarkTheme(): void {
	// logos
	document.querySelectorAll('.full-logo').forEach((element) => {
		(element as HTMLImageElement).style.filter = 'invert(1)'
	});
	document.querySelectorAll('.simple-logo').forEach((element) => {
		(element as HTMLImageElement).style.filter = 'invert(1)'
	});

	// classes
	document.querySelectorAll('.bg-light').forEach((element) => {
		element.classList.remove('bg-light');
		element.classList.add('bg-dark');
	});

	document.querySelectorAll('.btn-light').forEach((element) => {
		element.classList.remove('btn-light');
		element.classList.add('btn-dark');
	});

	document.documentElement.setAttribute('data-bs-theme', 'dark');
}

function disableDarkTheme(): void {
	// logos
	document.querySelectorAll('.full-logo').forEach((element) => {
		(element as HTMLImageElement).style.filter = 'invert(0)'
	});
	document.querySelectorAll('.simple-logo').forEach((element) => {
		(element as HTMLImageElement).style.filter = 'invert(0)'
	});

	// classes
	document.querySelectorAll('.bg-dark').forEach((element) => {
		element.classList.remove('bg-dark');
		element.classList.add('bg-light');
	});

	document.querySelectorAll('.btn-dark').forEach((element) => {
		element.classList.remove('btn-dark');
		element.classList.add('btn-light');
	});
	document.documentElement.setAttribute('data-bs-theme', 'light');
}
