import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faInfoCircle);

const infoCircle = icon({ prefix: 'fas', iconName: 'info-circle' });

import * as Highcharts from 'highcharts';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import 'highcharts/modules/offline-exporting';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import { Popover } from 'bootstrap';

import * as utils from '../../../utils';
import { plotScatterPlotSingleModel } from '../scatter-plot-single-model';

import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global';
import { TSData, TSWeeklyData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotTimeSeriesSingleModel(data: TSData | TSWeeklyData, div: string, plotScat: boolean = false, tsType: string = 'regular'): void {
	if (tsType === 'regular') {
		plotTimeSeries(data as TSData, div, plotScat);
	} else if (tsType === 'weekly') {
		plotWeeklyTimeSeries(data as TSWeeklyData, div, plotScat);
	}
}

function plotTimeSeries(data: TSData, div: string, plotScat: boolean = false): void {
	const modelData = data[aeroval.model.dir];
	if (!modelData) return;

	if (typeof aeroval.experiment.additional_ts_tabs != 'undefined') {
		var div = 'nav-ts';
		var otherDivs = aeroval.experiment.additional_ts_tabs;
	} else {
		div = 'evaluation-ts';
		otherDivs = [];
	}

	// time series
	const series = [] as any;

	// get time series for all primary and secondary frequencies
	var frequencies = utils.getOrderedFrequencies();
	// remove yearly if single year
	if (frequencies.includes('yearly') && (modelData['yearly_date']?.length ?? 0) <= 1) {
		frequencies = frequencies.filter((item) => item !== 'yearly');
	}
	frequencies = utils.filterFrequencies(frequencies, aeroval.frequency)

	const whichFrequencies = ['primary', 'secondary'];
	const minN = Math.min(frequencies.length, whichFrequencies.length);

	for (let i: number = 0; i < minN; i++) {
		const freq = frequencies[i];
		const whichFreq = whichFrequencies[i] as 'primary' | 'secondary';

		// data arrays
		const obsArray: [number, number][] = [];
		const modArray: [number, number][] = [];

		const modelDataObs = modelData[`${freq}_obs`];
		const modelDataMod = modelData[`${freq}_mod`];
		const modelDataDate = modelData[`${freq}_date`];
		if (modelDataObs && modelDataMod && modelDataDate) {
			for (let j: number = 0; j < modelDataObs.length; j++) {
				obsArray.push([modelDataDate[j], modelDataObs[j]]);
				modArray.push([modelDataDate[j], modelDataMod[j]]);
			}
		}

		if (obsArray.length > 0) {
			// name of the series
			const name = `Observation (${freq})`;

			// change which freq if secondary frequency and the primary frequency is not visible
			const whichStyle = (whichFreq === 'secondary' && aeroval.seriesVisibility[`Observation (${frequencies[i - 1]})`] === false) ?
				'primary' as 'primary' | 'secondary' :
				whichFreq;

			// set visibility
			const visible = name in aeroval.seriesVisibility ? aeroval.seriesVisibility[name] : true;

			// observations
			series.push({
				name,
				data: obsArray,
				color: aeroval.settings.theme === "dark" ? chartsStyle['evaluation-ts'][`obs-${whichStyle}`].colorDarkMode : chartsStyle['evaluation-ts'][`obs-${whichStyle}`].color,
				lineWidth: chartsStyle['evaluation-ts'][`obs-${whichStyle}`].lineWidth * eval(aeroval.settings.lineWidthFactor),
				marker: {
					enabledThreshold: 2,
					radius: chartsStyle['evaluation-ts'][`obs-${whichStyle}`].markerSize * eval(aeroval.settings.lineWidthFactor),
				},
				enableMouseTracking: true,
				visible,
				zIndex: 2,
				id: `obs-${whichFreq}`,
				weight: 1//chartsStyle['evaluation-ts'][`obs-${whichStyle}`].lineWidth
			});
		}

		// model
		if (modArray.length > 0 && aeroval.model.dir != 'dummy') {
			// name of the series
			const name = `Model (${freq})`;

			// change which freq if secondary frequency and the primary frequency is not visible
			const whichStyle = (whichFreq === 'secondary' && aeroval.seriesVisibility[`Observation (${frequencies[i - 1]})`] === false) ?
				'primary' as 'primary' | 'secondary' :
				whichFreq;

			// set visibility
			const visible = name in aeroval.seriesVisibility ? aeroval.seriesVisibility[name] : true;

			series.push({
				name: name,
				data: modArray,
				color: chartsStyle['evaluation-ts'][`mod-${whichStyle}`].color,
				lineWidth: chartsStyle['evaluation-ts'][`mod-${whichStyle}`].lineWidth * eval(aeroval.settings.lineWidthFactor),
				marker: {
					enabledThreshold: 2,
					radius: chartsStyle['evaluation-ts'][`mod-${whichStyle}`].markerSize * eval(aeroval.settings.lineWidthFactor),
				},
				enableMouseTracking: true,
				visible,
				zIndex: 2,
				enabled: false,
				id: `mod-${whichFreq}`,
				weight: 1//chartsStyle['evaluation-ts'][`mod-${whichStyle}`].lineWidth
			});
		}
	}

	// set units
	const axisUnit = modelData.obs_unit != '1' ? `(${modelData.obs_unit})` : ``
	// set softMin for yAxis
	const yAxisSoftMin = aeroval.ranges[aeroval.parameter.dir].scale[0];
	// set title
	const title = getTitle();
	// set subtitle
	const subtitle = aeroval.model.dir != 'dummy' ? `${aeroval.observation} - ${aeroval.model.name}` : aeroval.observation;
	// set xAxis limits
	const xAxisMin = aeroval?.dateMin?.valueOf();
	const xAxisMax = aeroval?.dateMax?.valueOf();
	// verticalLineWidth
	const gridLineWidth = eval(aeroval.settings.verticalGridLines) ? 2 : 0;

	const syncing = false;
	Highcharts.chart(
		{
			title: {
				text: title,
			},
			subtitle: {
				text: subtitle,
			},
			chart: {
				renderTo: div,
				type: aeroval.settings.lineStyle,
				zooming: {
					type: 'x',
				},
				resetZoomButton: {
					position: {
						align: 'center', // by default
						verticalAlign: 'top', // by default
					},
				},
				events: {
					load: function () {
						utils.highlightSeason(div);
					}
				}
			},
			boost: {
				useGPUTranslations: false,
				usePreallocated: true,
			},
			tooltip: {
				shared: true,
				valueDecimals: 3,
			},
			yAxis: {
				title: {
					text: `${aeroval.parameter.name} ${axisUnit}`,
				},
				softMin: yAxisSoftMin,
				type: aeroval.axisType,
			},
			xAxis: {
				type: 'datetime',
				title: {
					text: 'Time',
				},
				gridLineWidth: gridLineWidth,
				plotBands: aeroval.highlightedSeasons,
				min: xAxisMin,
				max: xAxisMax,
				events: {
					setExtremes: function (event) {
						const scatterplot = aeroval.cfg.webdisp_opts.hide_charts?.includes('scatterplot') ? false : true;
						setZoomState(event, scatterplot);
						if (!syncing) utils.syncZoom(otherDivs, event);
					},
				},
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'top',
				floating: true,
				y: 40,
			},
			series,
			plotOptions: {
				series: {
					label: {
						enabled: false
					},
					boostThreshold: 0,
					events: {
						legendItemClick: function () {
							utils.clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					},
				},
			}
		},
		function (chart) {
			addInfo(data);
			if (aeroval.statistic.dir.includes('trend')) {
				addTrendSingleModel(div);
			}
			// set initial zoom level
			if (aeroval?.zoom?.state === true) {
				chart.xAxis[0].setExtremes(aeroval.zoom.min, aeroval.zoom.max);
				chart.showResetZoom();
			}

			if (plotScat) {
				plotScatterPlotSingleModel();
			}
			utils.setManualExtremes(chart, ['y'])
		}
	);
}

function plotWeeklyTimeSeries(data: TSWeeklyData, div: string, plotScat: boolean = true): void {
	const modelData = data[aeroval.model.dir];
	if (!data || !modelData) return;

	const otherDivs = typeof aeroval.experiment.additional_ts_tabs != 'undefined' ? aeroval.experiment.additional_ts_tabs : [];

	// hourly time series: the key can be All or Annual
	const gKey = aeroval.season == 'All' ? 'yearly' as 'yearly' | 'seasonal' : 'seasonal';
	const sKey = aeroval.season == 'All' ? utils.getDiurnalAllKey(Object.keys(modelData.yearly.obs[aeroval.time])) : aeroval.season;
	const series = [] as any;

	var whichStyle = 'primary' as 'primary' | 'secondary';
	const obsColor = aeroval.settings.theme === "dark" ? chartsStyle['evaluation-ts'][`obs-${whichStyle}`].colorDarkMode : chartsStyle['evaluation-ts'][`obs-${whichStyle}`].color
	const modColor = chartsStyle['evaluation-ts'][`mod-${whichStyle}`].color
	whichStyle = 'secondary'
	const lineWidth = chartsStyle['evaluation-ts'][`obs-${whichStyle}`].lineWidth * eval(aeroval.settings.lineWidthFactor)
	const markerSize = chartsStyle['evaluation-ts'][`obs-${whichStyle}`].markerSize * eval(aeroval.settings.lineWidthFactor)

	const hObs: [number, number][] = [];
	const hMod: [number, number][] = [];

	if (!modelData.time || !sKey) return;
	for (let j in modelData.time) {
		hObs.push([modelData.time[j] as number, modelData[gKey]['obs'][aeroval.time][sKey][j] as number]);
	}

	series.push({
		type: 'line',
		name: 'Observation',
		data: hObs,
		color: obsColor,
		lineWidth: lineWidth,
		dashStyle: 'solid',
		marker: {
			enabled: true,
			radius: markerSize,
			symbol: 'circle',
		},
		enableMouseTracking: true,
		states: {
			inactive: {
				enabled: false,
			},
		},
	});

	for (let j in modelData.time) {
		hMod.push([modelData.time[j], modelData[gKey]['mod'][aeroval.time][sKey][j]]);
	}

	series.push({
		type: 'line',
		name: 'Model',
		data: hMod,
		color: modColor,
		lineWidth: lineWidth,
		dashStyle: 'dash',
		marker: {
			enabled: true,
			radius: markerSize,
			symbol: 'triangle',
		},
		enableMouseTracking: true,
		states: {
			inactive: {
				enabled: false,
			},
		},
	});

	// set unit
	const axisUnit = modelData.obs_unit != '1' ? ` (${modelData.obs_unit})` : `1`
	// set softMin for yAxis
	const softMin = aeroval.ranges[aeroval.parameter.dir].scale[0];
	// set title
	const titleText = getTitle();
	const syncing = false;

	Highcharts.chart(
		{
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.observation} - ${aeroval.model.name}`,
			},
			chart: {
				renderTo: div,
				zooming: {
					type: 'x',
				},
				resetZoomButton: {
					position: {
						align: 'center',
						verticalAlign: 'top',
					},
				},
				backgroundColor: 'transparent',
				events: {
					fullscreenOpen: function () {
						// fullscreen background color
						var fullScreenBackgroundColor = 'white';
						if (aeroval.settings.theme == 'dark') {
							fullScreenBackgroundColor = '#282b34';
						}
						(this as any).update({
							chart: {
								backgroundColor: fullScreenBackgroundColor,
							},
						});
					},
					fullscreenClose: function () {
						(this as any).update({
							chart: {
								backgroundColor: 'transparent',
							},
						});
					},
				},
			},
			tooltip: {
				shared: true,
				valueDecimals: 3,
			},
			credits: {
				enabled: false,
			},
			yAxis: {
				title: {
					text: aeroval.parameter.name + axisUnit,
				},
				softMin,
			},
			xAxis: {
				title: {
					text: 'Weekday',
				},
				min: 1,
				max: 7.99,
				categories: ['', 'M', 'T', 'W', 'T', 'F', 'S', 'S', ''],
				labels: {
					x: 30,
				},
				events: {
					setExtremes: function (event) {
						const scatterplot = aeroval.cfg.webdisp_opts.hide_charts?.includes('scatterplot') ? false : true;
						setZoomState(event, scatterplot);
						if (!syncing) utils.syncZoom(otherDivs, event);
					},
				},
				plotLines: [
					{
						color: '#BEBEBE',
						width: 1,
						value: 1,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 2,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 3,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 4,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 5,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 6,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 7,
					},
					{
						color: '#BEBEBE',
						width: 1,
						value: 8,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 1.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 2.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 3.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 4.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 5.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 6.5,
					},
					{
						color: '#DCDCDC',
						width: 1,
						dashStyle: 'ShortDash',
						value: 7.5,
					},
				],
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'top',
				floating: true,
				y: 40,
			},
			series,
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							utils.clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					},
				},
			},
		},
		function () {
			if (plotScat) {
				plotScatterPlotSingleModel('weekly');
			}
			addInfo(data);
		}
	);
}

export function cleanTrendSingleModel(id: string): void {
	const tsChart = utils.getChartById(id);
	const tsSeries = tsChart?.series;
	if (!tsSeries) return;

	var idsToRemove = [];
	for (const series of tsSeries) {
		const id = series.userOptions.id;
		if (id && id.includes('trend')) {
			idsToRemove.push(id);
		}
	}

	//if still visualize a trend, just delete previous trends. Otherwise, plotTS
	if (aeroval.statistic.dir.includes('trend')) {
		for (const idToRemove of idsToRemove) {
			console.log(tsChart, tsChart.get(idToRemove))
			tsChart.get(idToRemove)?.remove();
		}
	} else if (idsToRemove.length > 0 && aeroval.data.ts) {
		plotTimeSeries(aeroval.data.ts, 'ts');
	}
}

export function addTrendSingleModel(id: string): void {
	const tsChart = utils.getChartById(id);
	const data = aeroval.data.ts?.[aeroval.model.dir];
	if (!tsChart || !data) return;

	cleanTrendSingleModel(id);

	//if selected parameter is a trend, plot it.
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);

	//make lines lighter, thinner, markers smaller
	const tsSeries = tsChart.series;

	for (const series of tsSeries) {
		const seriesId = series.userOptions.id;
		const seriesName = series.userOptions.name;
		//we unselect the daily data
		if (seriesName && seriesName.includes('daily')) {
			series.update({ visible: false } as any, false);
		}
		if (!seriesId || !seriesId.includes('trend')) {
			const newLineWidth = 1;
			const newOpacity = 0.4;
			var newRadius = 2;

			if (Object.keys((series.userOptions as any).marker).includes('radius')) {
				newRadius = (series.userOptions as any).marker.radius - 1;
			}
			series.update(
				{
					lineWidth: newLineWidth,
					marker: {
						radius: newRadius,
					},
					opacity: newOpacity,
				} as any,
				false
			);
		}
	}

	//check if the trend is composed of two parameters (obs and mod)
	if (aeroval.statistic.dir.includes('/')) {
		const firstUnderscrore = aeroval.statistic.dir.indexOf('_');
		const stat1 = aeroval.statistic.dir.split('/')[0] + '_' + aeroval.statistic.dir.slice(firstUnderscrore + 1);
		const stat2 = aeroval.statistic.dir.split('/')[1];
		var stats = [stat1, stat2];
	} else {
		var stats = [aeroval.statistic.dir];
	}

	for (let i: number = 0; i < stats.length; i++) {
		const trendColor = aeroval.settings.theme == 'dark' ? 'rgba(200,200,200,0.9)' : 'rgba(128,128,128,0.9)';
		//if the station is a region, read value from aeroval.data.sub_hm
		if (Object.keys(aeroval.regions).includes(aeroval.station) && aeroval.data.reg_stat) {
			var trendStat = aeroval.data.reg_stat[aeroval.model.dir][aeroval.modVars[aeroval.model.dir]][aeroval.station][keyTime] as any;
		} else if (aeroval.data.map) {
			//else, read it fom the map
			for (const station of aeroval.data.map) {
				if (aeroval.station == station.station_name) {
					var trendStat = station[aeroval.frequency]?.[keyTime] as any;
					break;
				}
			}
		}

		// custom color, if obs-only
		const lineWidth = 3;
		var color = 'rgba(255,153,51,0.9)';
		if (aeroval.model.dir == 'dummy') {
			// if station is region, get trend from reg stats
			color = utils.colStat(trendStat.obs_trend[trendStat.obs_trend.map_var], getScale());
		} else if (stats[i].includes('obs')) {
			color = trendColor;
		}

		//compute trend
		const trend = trendStat[stats[i]];

		if (!trend || !data['monthly_date']) break;

		//creates x axis for monthly time series
		const xArray: number[] = [];
		const y0 = trend.period.split('-')[0];
		const y1 = trend.period.split('-')[1];
		for (let date of data['monthly_date']) {
			if (date >= new Date(y0, 0, 1).valueOf() && date < new Date(y1, 0, 1).valueOf()) {
				xArray.push(date);
			}
		}

		//compute trend equation
		var a: number | null;
		var b = trend['reg0_' + y0];
		if (trend['slp_' + y0] != null) {
			a = (trend['slp_' + y0] / (365 * 24 * 60 * 60 * 1000)) * b;
			var rel_trend = trend['slp_' + y0].toFixed(2);
			var err_trend = trend['slp_' + y0 + '_err'].toFixed(2);
			var pval_trend = trend['pval'].toFixed(2);
			var str_trend = (rel_trend < 0 ? '' : '+') + rel_trend + '+/-' + err_trend + '%/yr; p-val: ' + pval_trend;
		} else {
			a = null;
			var str_trend = 'No trend available';
		}

		//plot line as dashed line if non significant
		const dashStyle = trend['pval'] > 0.1 ? 'Dash' : 'Solid';

		if (a != null) {
			const regTrend = xArray.map(function (x) {
				return ((x - xArray[0]) * (a as number)) / 100 + b;
			});
			const dataArray: any[] = [];
			for (let k = 0; k < xArray.length; k++) {
				dataArray.push([xArray[k], regTrend[k]]);
			}

			const trendsSeries = {
				type: 'line',
				id: `trend-${stats[i]}`,
				name: str_trend,
				data: dataArray,
				color,
				dashStyle,
				lineWidth,
				marker: {
					enabled: false,
					radius: 1.5,
				},
				enableMouseTracking: false,
				zIndex: 10,
			} as any;
			//add it to the chart
			tsChart.addSeries(trendsSeries, false);
		}

		//plot yearly data used for trends Computation
		var trend_data = JSON.parse(trend.data);
		var keys = Object.keys(trend_data);
		var yearly_data = [];
		for (let k in keys) {
			yearly_data.push([parseInt(keys[k]), trend_data[keys[k]]]);
		}

		const yearlySeries = {
			type: 'line',
			id: 'trend-data-' + i,
			data: yearly_data,
			color: color,
			opacity: 0.9,
			lineWidth: 0,
			marker: {
				enabled: true,
				radius: 4,
				symbol: 'circle',
			},
			showInLegend: false,
			enableMouseTracking: false,
			zIndex: 5,
			linkedTo: ':previous',
		} as any;
		//add it to the chart
		tsChart.addSeries(yearlySeries, false);
	}
	tsChart.redraw();
}

function addInfo(data: TSData) {
	const modelData = data[aeroval.model.dir];
	if (!modelData) return;

	var info = {
		obs_unit: {
			title: 'Obs Unit',
			content: undefined,
		},
		mod_unit: {
			title: 'Mod Unit',
			content: undefined,
		},
		obs_freq_src: {
			title: 'Obs Freq',
			content: undefined,
		},
		mod_freq_src: {
			title: 'Mod Freq',
			content: undefined,
		},
		obs_revision: {
			title: 'Obs Revision',
			content: undefined,
		},
		pyaerocom_version: {
			title: 'Pyaerocom',
			content: undefined,
		},
	};

	const keys = Object.keys(modelData);
	var nInfo = 0;
	for (const tsKey of keys) {
		if (tsKey in info) {
			(info as any)[tsKey].content = (modelData as any)[tsKey];
			nInfo++;
		}
	}

	//push all the info fields into html
	var infoHTML = '<ul>';
	if (nInfo > 0) {
		for (let infoKey in info) {
			const thisInfo = (info as any)[infoKey];
			if (thisInfo['title']) {
				infoHTML += "<li class='infobox'><b>" + thisInfo['title'] + '</b>: ' + thisInfo['content'] + '</li>';
			}
		}
	} else {
		infoHTML += 'Information not available yet';
	}
	infoHTML += '</ul>';
	var h = '<a href="#" id="infoBox" data-toggle="popover" class="btn" title="Information" data-bs-content="' + infoHTML + `">${infoCircle.html}</a>`;

	const tsInfo = document.getElementById('tsInfo');
	if (tsInfo instanceof HTMLElement) {
		tsInfo.innerHTML = h;
	}

	//enable popover
	const infoBox = document.querySelector('#infoBox');

	// Check if the element exists
	if (infoBox) {
		// Initialize popover
		new Popover(infoBox, {
			html: true,
			placement: 'bottom',
			trigger: 'focus',
		});
	}
}

function setZoomState(event: Highcharts.AxisSetExtremesEventObject, plotScat = true) {
	if (event.max && event.min) {
		aeroval.zoom = {
			state: true,
			min: event.min,
			max: event.max,
		};
	} else {
		aeroval.zoom = {
			state: false,
			min: undefined,
			max: undefined,
		};
	}

	if (plotScat) {
		plotScatterPlotSingleModel(aeroval.tsType);
	}
}

function getTitle(): string {
	var title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	// add station and time
	title += ` - ${aeroval.station} - ${aeroval.time}`;
	// add season
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}

function getScale(): number[] {
	if (!aeroval.statistic.scale) {
		var scale = aeroval.ranges[aeroval.parameter.dir].scale;
	} else {
		if (aeroval.statistics[aeroval.statistic.dir].unit === 'var') {
			const statScale = aeroval.ranges[aeroval.parameter.dir].scale;
			scale = aeroval.statistic.scale.map((x) => x * Math.max(...statScale));
			scale = scale.map((x) => parseFloat(x.toFixed(2))) as number[];
		} else {
			scale = aeroval.statistic.scale;
		}
	}
	return scale;
}