import * as Highcharts from 'highcharts';
import 'highcharts/modules/annotations';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import { getExtremeDates } from '../../../modules/main.js';
import { clickOnLegendItem, getModelStyle, highchartsPalette, setManualExtremes } from '../../../utils/index.js';
import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global.js';
import { HMTSData } from '../../../types/data.js';
declare var aeroval: Aeroval;

export function plotTimeSeriesStatistics(
	data: HMTSData,
	parameter: string,
	observation: string,
	layer: string,
	statistic: string,
	div: string,
	models: string | string[] = 'all',
	show_number_points = false,
	show_annotations: boolean = true,
	chartOptions: any = {}
) {
	const subData = data[parameter][observation][layer];

	// get extreme dates
	getExtremeDates();

	// set style
	var lineWidthObs = 0;
	var type = 'scatter';
	if (aeroval.groupBy == 'None') {
		lineWidthObs = chartsStyle['overall-ts']['obs'].lineWidth * eval(aeroval.settings.lineWidthFactor);
		type = aeroval.settings.lineStyle;
	}

	var i = 0;
	const series = [] as any;
	const obsSeries = [];

	// loop through models
	if (models === 'all') {
		models = Object.keys(subData) as string[];
	}

	const unit = aeroval.statistics[statistic].unit;

	for (const model of models) {
		// set model style
		const highchartsColor = highchartsPalette(i);
		const modelStyle = getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;
		const modelWeight = modelStyle?.weight ? modelStyle.weight : 1;

		var markerSizeModel = modelWeight * chartsStyle['overall-ts']['mod'].markerSize * eval(aeroval.settings.lineWidthFactor);
		var lineWidthModel = modelWeight * chartsStyle['overall-ts']['mod'].lineWidth * eval(aeroval.settings.lineWidthFactor);

		// overwrite lineWidthMod
		if (aeroval.groupBy !== 'None') {
			lineWidthModel = 0;
		}

		// if chartOption, overwrite lineWidthModel
		if (chartOptions.lineWidth) {
			lineWidthModel = chartOptions.lineWidth;
		}
		if (chartOptions.markerRadius) {
			markerSizeModel = chartOptions.markerRadius;
		}

		//here we overwrite the visibility
		// obs
		var obsVisibility = true;
		if (typeof aeroval.seriesVisibility?.['Obs'] !== 'undefined') {
			obsVisibility = aeroval.seriesVisibility['Obs'];
		}
		// model
		var modVisibility = true;
		if (typeof aeroval.seriesVisibility?.[model] !== 'undefined') {
			modVisibility = aeroval.seriesVisibility[model];
		}

		// get modVar
		const modVar = Object.keys(subData[model])[0];

		if (typeof subData[model][modVar] != 'undefined') {
			var d = [];
			var o = [];
			var n = [];
			//loop over the seasons
			for (let date in subData[model][modVar][aeroval.region]) {
				let dat = subData[model][modVar][aeroval.region][date];
				if (statistic == 'nrms') {
					var val = dat['rms'] / dat['refdata_mean'];
				} else {
					val = dat[statistic];
				}
				if (val != null) {
					if (unit == '%') {
						val = val * 100;
					}
				}
				// if group by month, plot all years at the same year (2000)
				if (aeroval.groupBy == 'Month') {
					var x_value = new Date(parseInt(date)).getMonth();
				} else if (aeroval.groupBy === 'Weekday') {
					x_value = new Date(parseInt(date)).getDay() - 1;
					// set sunday as last day
					if (x_value == -1) {
						x_value = 6;
					}
				} else {
					x_value = parseInt(date);
				}

				// writes value
				d.push([x_value, val]);
				n.push([x_value, dat['num_coords_with_data']]);

				// add mean
				if (statistic == 'data_mean') {
					o.push([x_value, dat['refdata_mean']]);
				}
			}

			if (statistic == 'data_mean') {
				const obsColor = aeroval.settings.theme == 'dark' ? 'white' : 'black';

				obsSeries.push({
					name: `Obs (${model})`,
					id: `obs`,
					data: o,
					color: obsColor,
					marker: {
						enabledThreshold: 1,
						radius: 4,
					},
					enableMouseTracking: true,
					visible: obsVisibility,
					zIndex: 10,
					lineWidth: lineWidthObs,
					weight: 2
				});
			}
			series.push({
				type: aeroval.settings.lineType,
				name: model,
				id: `mod-${model}`,
				data: d,
				color: colorModel,
				marker: {
					enabledThreshold: 1,
					radius: markerSizeModel,
				},
				enableMouseTracking: true,
				visible: modVisibility,
				lineWidth: lineWidthModel,
				weight: modelStyle?.weight
			});
			if (show_number_points) {
				series.push({
					type: 'area',
					name: model,
					opacity: 0.1,
					data: n,
					yAxis: 2,
					color: colorModel,
					marker: {
						enabled: false,
					},
					enableMouseTracking: false,
					visible: modVisibility,
					linkedTo: ':previous',
				});
			}
		}
		i++;
	}

	if (statistic == 'data_mean') {
		// add longest observation series
		var obsLengths = [];
		for (let series of obsSeries) {
			obsLengths.push(series.data.length);
		}
		const max = Math.max(...obsLengths);
		const iMax = obsLengths.indexOf(max);
		series.unshift(obsSeries[iMax]);
	}

	/*unit*/
	const axis_unit = unit != '1' && unit != 'var' ? `(${unit})` : ''
	// set title
	const titleText = `${aeroval.menu[parameter].name} - ${aeroval.region} - ${aeroval.time}`;
	const subtitle = `${observation}`;

	// set extremes
	var xMin: number | undefined = undefined;
	var xMax: number | undefined = undefined;
	var xJitter = 0.1;

	if (aeroval.groupBy === 'None') {
		getExtremeDates();
		xMin = aeroval.dateMin?.valueOf();
		xMax = aeroval.dateMax?.valueOf();
		xJitter = 0;
	}

	// set legend
	const yLegend = 0;

	// set annotations
	const ts_annotations = aeroval.cfg.webdisp_opts.ts_annotations;
	if (show_annotations && ts_annotations && aeroval.groupBy == 'None') {
		var labels: any[] = [];
		var xPlotLines: any[] = [];
		for (let date in ts_annotations) {
			let annotation = ts_annotations[date];
			labels.push({
				point: {
					x: new Date(date).valueOf(),
					y: 0,
					xAxis: 0,
					yAxis: 1,
				},
				text: annotation,
				crop: false,
			});
			xPlotLines.push({
				value: new Date(date).valueOf(),
				color: 'rgba(153, 153, 153, .25)',
				width: 3,
			});
		}
		var annotations = [
			{
				draggable: '',
				labels: labels,
				id: 'annotations',
			},
		] as any[];
	} else {
		var annotations = [];
		var xPlotLines = [];
	}

	// set xAxis
	var xAxisType: string | undefined = undefined;
	xAxisType = 'datetime';
	var xAxisTitleText = 'Time';
	var xAxisCategories: string[] | undefined = undefined;
	if (aeroval.groupBy !== 'None') {
		xAxisType = undefined;
		xAxisTitleText = aeroval.groupBy;
		if (aeroval.groupBy == 'Month') {
			xAxisCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		} else if (aeroval.groupBy == 'Weekday') {
			xAxisCategories = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		}
	}

	// perfect score
	const perfectColor = aeroval.settings.theme == 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)';

	var yPlotLines = [
		{
			value: perfectScore(aeroval.statistics[statistic].name as perfectStats),
			color: perfectColor,
			width: 2,
			zIndex: 2,
		},
	];

	// verticalLineWidth
	const gridLineWidth = eval(aeroval.settings.verticalGridLines) ? 2 : 0;

	var chart = Highcharts.chart(
		div,
		{
			title: {
				text: titleText,
			},
			subtitle: {
				text: subtitle,
			},
			exporting: {
				enabled: true,
				sourceWidth: 1000,
				sourceHeight: 300,
				chartOptions: {
					xAxis: {
						labels: {
							style: {
								fontSize: '14px',
							},
						},
						title: {
							style: {
								fontSize: '14px',
							},
						}
					},
					yAxis: [
						{
							labels: {
								style: {
									fontSize: '14px',
								},
							},
							title: {
								style: {
									fontSize: '14px',
								},
							},
						},
						{
							title: {
								text: 'Nb. Obs',
							},
							opposite: true,
						},
					],
					legend: {
						itemStyle: {
							fontSize: '12px',
						},
					}
				},
			},
			chart: {
				renderTo: div,
				type,
				zooming: { type: 'x' },
				height: chartOptions && chartOptions.height ? chartOptions.height : 500,
				resetZoomButton: {
					position: {
						align: 'center', // by default
						verticalAlign: 'top', // by default
					},
				}
			},
			tooltip: {
				shared: true,
				valueDecimals: 3,
			},
			yAxis: [
				{
					title: {
						text: `${aeroval.statistics[statistic].name} ${axis_unit}`,
					},
					softMin: 0,
					plotLines: yPlotLines as any,
				},
				{
					min: 0,
					max: 1,
					visible: false,
				},
				{
					title: {
						text: 'Nb. Obs',
					},
					opposite: true,
					visible: show_number_points ? true : false,
				},
			],
			xAxis: {
				categories: xAxisCategories,
				type: xAxisType,
				gridLineWidth: gridLineWidth,
				title: {
					text: xAxisTitleText,
				},
				//plotBands: aeroval.highlightedSeasons,
				min: xMin,
				max: xMax,
				plotLines: xPlotLines,
				events: {
					setExtremes: function (e) {
						if (aeroval.groupBy == 'None') {
							setZoomState(e);
						}
					},
				},
			} as Highcharts.XAxisOptions,
			annotations,
			legend: {
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				floating: false,
				y: yLegend,
			},
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					},
					label: {
						enabled: true
					}
				},
				scatter: {
					jitter: {
						x: xJitter,
					},
				},
			},
			series,
		},
		function (chart) {
			// set initial zoom level
			if (aeroval?.zoom?.state === true) {
				chart.xAxis[0].setExtremes(aeroval.zoom.min, aeroval.zoom.max);
				chart.showResetZoom();
			}

			setManualExtremes(chart, ['y'])
		}
	);

	// add custom button
	function toggleAnnotations() {
		aeroval.annotations = !aeroval.annotations;
		if (aeroval.annotations) {
			// vertical lines
			chart.xAxis[0].update({
				plotLines: xPlotLines,
			});
			// annotations
			chart.addAnnotation({
				draggable: '',
				labels: labels,
				id: 'annotations',
			});
		} else {
			// vertical lines
			chart.xAxis[0].update({
				plotLines: [],
			});
			// annotations
			chart.removeAnnotation('annotations');
		}
	}

	if (ts_annotations && Object.keys(ts_annotations).length > 0 && aeroval.groupBy == 'None') {
		// initialize state to true
		aeroval.annotations = true;
		if (show_annotations) {
			chart.renderer.button('Annotations', 10, 10, toggleAnnotations).add();
		}
	}

	/*
	if (aeroval.sound) {
		data2partition()
	}
	*/
}

function setZoomState(event: Highcharts.AxisSetExtremesEventObject) {
	if (event.max && event.min) {
		aeroval.zoom = {
			state: true,
			min: event.min,
			max: event.max,
		};
	} else {
		aeroval.zoom = {
			state: false,
			min: undefined,
			max: undefined,
		};
	}
}

type perfectStats = 'NMB' | 'MNMB' | 'Mean Bias' | 'MAB' | 'R' | 'R Spearman' | 'FGE' | 'NRMSE' | 'RMSE';
function perfectScore(statName: perfectStats): number | null {
	const perfect_score = {
		NMB: 0,
		MNMB: 0,
		'Mean Bias': 0,
		MAB: 0,
		R: 1,
		'R Spearman': 1,
		FGE: 0,
		NRMSE: 0,
		RMSE: 0,
	};
	if (statName in perfect_score) {
		return perfect_score[statName];
	} else {
		return null;
	}
}
