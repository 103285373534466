import * as main from './main';
import { updateAxisType, updateQueryString, highlightSeason } from '../utils';
import { showStation } from '../components/charts/map';

import { plotTimeSeriesSingleModel, addTrendSingleModel, cleanTrendSingleModel } from '../components/charts/time-series-single-model';

/*import interfaces and extend window variable*/
import { Aeroval } from '../types/global';
declare var aeroval: Aeroval;

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/evaluation')) {
		init();
	}
});

function init(): void {
	console.log('initialize evaluation page');
	// set some defaults
	aeroval.goto = false;
	aeroval.tsType = 'regular'; //diurnal cycle
	aeroval.seriesVisibility = {};
	aeroval.menuButtons = ['parameter', 'observation', 'model', 'time', 'statistic'];
	aeroval.axisType = 'linear';

	// check if any queries
	main.getQueryStrings();

	// experiments
	main.getExperiments();

	// update header links with qs project
	main.updateURLs('project', aeroval.project);

	// scattering axis toggle
	const scatAxisToggle = document.getElementById('scatAxisToggle') as HTMLInputElement;
	if (scatAxisToggle) {
		scatAxisToggle.addEventListener('change', function () {
			// If switch off
			if (this.checked) {
				aeroval.axisType = 'logarithmic';
			} else {
				aeroval.axisType = 'linear';
			}
			updateAxisType();
		});
	}
}

export function callbackGlobStat(update_ts = true, update_scat = true) {
	document.querySelector('#map')?.classList.remove('loading');
	const iniLoader = document.querySelector('#iniLoader');
	if (iniLoader instanceof HTMLElement) {
		iniLoader.style.display = 'none';
	}
	if (aeroval.tsType == 'regular') {
		main.ts(update_ts, update_scat);
	} else if (aeroval.tsType == 'weekly') {
		main.tsWeekly(update_ts, update_scat);
	}
}

export function callbackMenu(reg_stat = true, update_ts = true, update_scat = true) {
	makeTsTabs();
	main.map(reg_stat, update_ts, update_scat);
}

function makeTsTabs() {
	//if ts div does not exist yet, create ts divs
	const containerTS = document.querySelector('containerTS');
	if (containerTS instanceof HTMLElement) {
		if (typeof aeroval.experiment.additional_ts_tabs != 'undefined') {
			var h = '';
			//nav
			h += `<nav>;
				<div class="nav nav-tabs" id="nav-ts-tab" role="tablist">
				<a class="nav-item nav-link active" id="nav-ts-tab" data-toggle="tab" href="#nav-ts" role="tab" aria-controls="nav-ts" aria-selected="true">Total</a>`
			for (let i in aeroval.experiment.additional_ts_tabs) {
				h +=
					`<a class="nav-item nav-link" id="nav-${aeroval.experiment.additional_ts_tabs[i]}-tab" data-toggle="tab" 
					href="#nav-${aeroval.experiment.additional_ts_tabs[i]}" role="tab" 
					aria-controls="nav-${aeroval.experiment.additional_ts_tabs[i]}" aria-selected="true">
					${aeroval.experiment.additional_ts_tabs[i]}</a>`
			}
			h += `</div>
				</nav>`;

			//content
			h += `<div class="tab-content" id="nav-tabContent">;
				<div id="nav-ts"></div>`;
			for (let i in aeroval.experiment.additional_ts_tabs) {
				h += `<div id="nav-${aeroval.experiment.additional_ts_tabs[i]}"></div>`;
			}
			h += '</div>';
			containerTS.innerHTML = h;
			const tsInfo = document.querySelector('tsInfo');
			if (tsInfo instanceof HTMLElement) {
				tsInfo.style.setProperty('margin-top', '40px');
			}
		} else if (containerTS.innerHTML.substring(0, 12) != '<div id="ts"') {
			containerTS.innerHTML = '<div id="ts"></div>';
		}
	}
	// switch tabs
}

export function update(which: string, what: any, wait = false): void {
	console.info('update ', which, 'with ', what);
	if (which == 'station' && what) {
		//for MODIS, station are tuples, and seen hre as array
		if (typeof what != 'string') {
			aeroval.station = `(${what[0].toFixed(1)}, ${what[1].toFixed(1)})`;
		} else {
			aeroval.station = what;
		}
		showStation(aeroval.station);
		if (aeroval.tsType == 'regular') {
			main.ts();
		} else {
			main.tsWeekly();
		}
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'parameter') {
		aeroval.parameter.dir = what;
		main.topMenu();
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'experiment') {
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		delete aeroval.zoom;
		main.getExperiments(true);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'model') {
		aeroval.model.dir = what;
		//get modVar
		aeroval.model.var = aeroval.modVars[what];
		main.map(true, true);
		main.showMenu(which, what, what);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'observation') {
		aeroval.observation = what;
		main.map(true, true);
		main.showMenu(which, what, what);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'statistic') {
		aeroval.statistic = aeroval.statistics[what];
		aeroval.statistic.dir = what;
		main.map(true, false, false);
		if (!aeroval.statistic.dir.includes('trend')) {
			cleanTrendSingleModel('evaluation-ts');
		} else if (aeroval.data.ts) {
			plotTimeSeriesSingleModel(aeroval.data.ts, 'evaluation-ts', true, aeroval.tsType);
		}
		main.showMenu(which, aeroval.statistic.name);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'layer') {
		aeroval.layer = what;
		if (!wait) {
			main.map(true, true);
			main.profile();
			main.showMenu('parameter', aeroval.par.name);
		}
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'frequency') {
		aeroval.frequency = what;
		if (what == 'monthly') {
			aeroval.tsType = 'regular';
		}
		main.checkSeasonMenu();
		main.topMenu();
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'time') {
		aeroval.time = what;
		delete aeroval.dateMin;
		delete aeroval.dateMax;
		main.map(true, false, false);
		main.showMenu(which, what);
		main.getExtremeDates(false, false);
		if (aeroval.data.ts) {
			plotTimeSeriesSingleModel(aeroval.data.ts, 'evaluation-ts', true, aeroval.tsType);
		}
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'season') {
		aeroval.season = what;
		main.getExtremeDates(false, false);
		if (aeroval.statistic.dir.includes('trend')) {
			main.map(true, false, true);
			addTrendSingleModel('evaluation-ts');
			if (aeroval.tsType != 'weekly') {
				highlightSeason('evaluation-ts');
			}
		} else if (aeroval.tsType == 'weekly') {
			main.map(true, true, true);
		} else {
			main.map(true, false, true);
			if (aeroval.tsType != 'weekly') {
				highlightSeason('evaluation-ts');
			}
		}
		main.showMenu('time', aeroval.time);
		updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'tsType') {
		aeroval.tsType = what;
		aeroval.granularity = 'season';
		main.topMenu();
		updateQueryString(which, what);
	}
	if (which == 'granularity') {
		aeroval.granularity = what;
		main.tsWeekly();
	}
}
