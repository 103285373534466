import * as Highcharts from 'highcharts';
import 'highcharts/highcharts-more';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import * as utils from '../../../utils';

import { Aeroval } from '../../../types/global';
import { MapData, FairmodeStats } from '../../../types/data';
declare var aeroval: Aeroval;

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
	faCircleCheck, faCircleQuestion, faCircleXmark
} from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faCircleCheck, faCircleQuestion, faCircleXmark);

const circleCheck = icon({ prefix: 'fas', iconName: 'circle-check' });
const circleQuestion = icon({ prefix: 'fas', iconName: 'circle-question' });
const circleXmark = icon({ prefix: 'fas', iconName: 'circle-xmark' });


export function plotTargetCircle(data: MapData, div: string = 'trgt'): void {
	if (!aeroval.config.target || !aeroval.frequency) {
		return;
	}

	const target_params = aeroval.config.target;

	//time selection
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);

	// model color
	const modelStyle = utils.getModelStyle(aeroval.model.dir);
	if (modelStyle) {
		var modelColor = modelStyle.color;
	} else {
		const highchartsColor = Highcharts.getOptions().colors as string[];
		if (highchartsColor) {
			modelColor = highchartsColor[0];
		}
	}

	var scatter_data = [];
	var counts = {
		in_05: 0,
		in_1: 0,
		out: 0,
		in_05_pct: 0,
		in_1_pct: 0,
		out_pct: 0,
		total: 0,
	};
	var MQI = [];

	if (aeroval.parameter.dir in target_params) {
		for (const station of data) {
			if (!(aeroval.frequency in station)) {
				break;
			}
			const stationStats = station[aeroval.frequency]?.[keyTime];
			if (!stationStats) continue;

			const fairmodeStats = stationStats.fairmode as FairmodeStats;
			if (fairmodeStats && fairmodeStats.alpha) {
				const x = (fairmodeStats.sign * fairmodeStats.crms) / (target_params[aeroval.parameter.dir].beta * fairmodeStats.RMSU);
				const y = fairmodeStats.bias / (target_params[aeroval.parameter.dir].beta * fairmodeStats.RMSU);
				var alpha = fairmodeStats.alpha.toFixed(2);
				var RV = fairmodeStats.RV.toFixed(2);
				var UrRV = fairmodeStats.UrRV.toFixed(2);
				MQI.push(fairmodeStats.beta_mqi / target_params[aeroval.parameter.dir].beta);

				if (aeroval.globalRegions.includes(aeroval.region) || aeroval.region == station.region) {
					scatter_data.push({
						x: parseFloat(x.toFixed(2)),
						y: parseFloat(y.toFixed(2)),
						name: station.station_name,
					});

					// counts
					if (Math.pow(x - 0, 2) + Math.pow(y - 0, 2) <= Math.pow(1, 2)) {
						counts['in_1'] += 1;
						if (Math.pow(x - 0, 2) + Math.pow(y - 0, 2) <= Math.pow(0.5, 2)) {
							counts['in_05'] += 1;
						}
					} else {
						counts['out'] += 1;
					}
					counts['total'] += 1;
				}
			} else {
				continue;
			}
		}
		const alertBox = document.getElementById('alert');
		if (alertBox instanceof HTMLElement) {
			alertBox.innerHTML = '';
		}
	} else {
		const alertBox = document.getElementById('alert');
		if (alertBox instanceof HTMLElement) {
			alertBox.innerHTML = '<div class="alert alert-warning" role="alert">No target plot is available for the selected species</div>';
		}
	}
	// calculate percents
	counts['in_05_pct'] = 100 * (counts['in_05'] / counts['total']);
	counts['in_1_pct'] = 100 * (counts['in_1'] / counts['total']);
	counts['out_pct'] = 100 * (counts['out'] / counts['total']);

	// calculate MQI90
	if (utils.quantile(MQI, 0.9)) {
		var MQI90 = utils.quantile(MQI, 0.9).toFixed(2) as string | undefined;
	} else {
		MQI90 = undefined
	}

	// set title
	const [titleText, titleFontSize] = getTitle();
	console.log(titleText, titleFontSize);

	// colors
	const diag_color = '#D0D0D04D';
	const circle1_color = '#D0D0D01F';
	const circle2_color = '#D0D0D014';

	// inner circles
	if (aeroval.isMobile) {
		var radiusIn = 28;
		var radiusOut = 56;
		var marginTop = 90;
		var marginBottom = 50;
		var marginLeft = 60;
		var marginRight = 40;
	} else {
		radiusIn = 35;
		radiusOut = 70;
		marginTop = 80;
		marginBottom = 50;
		marginLeft = 60;
		marginRight = 60;
	}

	Highcharts.chart(
		div,
		{
			chart: {
				renderTo: div,
				marginTop,
				marginBottom,
				marginLeft,
				marginRight,
			},
			title: {
				text: titleText,
				style: {
					fontSize: titleFontSize
				}
			},
			subtitle: {
				text: `${aeroval.observation} - ${aeroval.model.name} - ${aeroval.frequency} data`,
			},
			pane: {
				startAngle: 0,
				endAngle: 90,
			},
			xAxis: [
				{
					tickInterval: 0.5,
					min: -2,
					max: 2,
					lineWidth: 1,
					minorGridLineWidth: 1,
					visible: true,
					title: {
						text: 'CRMSE / β.RMSU',
					},
					gridLineWidth: 0,
					opposite: false,
					labels: {
						formatter: function () {
							const label = parseFloat(this.axis.defaultLabelFormatter.call(this));
							return String(Math.abs(label));
						},
					},
				},
				{
					linkedTo: 0,
					opposite: true,
				},
			],
			yAxis: [
				{
					min: -2,
					max: 2,
					tickInterval: 0.5,
					minorGridLineWidth: 1,
					title: {
						text: 'NMB / β.RMSU',
					},
					gridLineWidth: 0,
					lineWidth: 0,
				},
				{
					linkedTo: 0,
					opposite: true,
					gridLineWidth: 0,
					lineWidth: 0,
					title: {
						text: '',
					},
				},
			],
			tooltip: {
				valueDecimals: 2,
				headerFormat: '',
				pointFormat: '<b>{point.name}</b><br>x: {point.x}<br>y: {point.y}',
			},
			legend: {
				floating: true,
				verticalAlign: 'bottom',
				align: 'left',
				y: -50,
				x: 50,
			},
			series: [
				{
					type: 'scatter',
					data: scatter_data,
					name: 'All stations',
					color: modelColor,
					zIndex: 10,
					marker: {
						symbol: 'diamond',
						radius: 2,
					},
					cursor: 'pointer',
					point: {
						events: {
							click: function () {
								const thatPoint = this as any;
								location.href = `/pages/evaluation/?project=${aeroval.project}&experiment=${aeroval.experiment.name}&station=${thatPoint.name}&parameter=${aeroval.parameter.dir}&observation=${aeroval.observation}&model=${aeroval.model.dir}&layer=${aeroval.layer}`;
							},
						},
					},
					turboThreshold: 0,
				},
				{
					type: 'line',
					linkedTo: 'other',
					data: [
						[-2, -2],
						[2, 2],
					],
					marker: {
						enabled: false,
					},
					lineWidth: 1.25,
					name: 'diag1',
					zIndex: 6,
					showInLegend: false,
					color: diag_color,
					animation: false,
					enableMouseTracking: false,
				},
				{
					type: 'line',
					linkedTo: 'other',
					data: [
						[-2, 2],
						[2, -2],
					],
					marker: {
						enabled: false,
					},
					lineWidth: 1.25,
					name: 'diag2',
					zIndex: 6,
					showInLegend: false,
					color: diag_color,
					animation: false,
					enableMouseTracking: false,
				},
				{
					data: [[0, 0]],
					linkedTo: 'other', // link it to other series to avoid legend entry
					marker: {
						radius: radiusIn,
						fillColor: circle1_color,
						lineWidth: 1,
						lineColor: '#50ED934D',
						symbol: 'circle',
					},
					zIndex: 5,
					animation: false,
					enableMouseTracking: false,
				},
				{
					data: [[0, 0]],
					linkedTo: 'other', // link it to other series to avoid legend entry
					marker: {
						radius: radiusOut,
						fillColor: circle2_color,
						lineWidth: 2,
						lineColor: '#50ED934D',
						symbol: 'circle',
					},
					zIndex: 4,
					animation: false,
					enableMouseTracking: false,
				},
			] as any,
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							utils.clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					},
				},
			},
		},
		function (chart): void {
			if (aeroval.isMobile) {
				// add indicators
				chart.renderer.text('Mean bias > 0', 132, 110).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('Mean bias < 0', 132, 300).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('R', 70, 205).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('SD', 255, 205).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
			} else {
				// add some text
				chart.renderer.text('Parameters', 70, 100).attr({ zIndex: 15 }).css({ fontSize: '12px', fontWeight: 'bold' }).add();
				chart.renderer
					.text('α: ' + alpha, 70, 115)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();
				chart.renderer
					.text('β: ' + target_params[aeroval.parameter.dir].beta.toFixed(2), 70, 130)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();
				chart.renderer
					.text('RV: ' + RV, 70, 145)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();
				chart.renderer
					.text('UrRV: ' + UrRV, 70, 160)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();

				chart.renderer.text('Results', 250, 100).attr({ zIndex: 15 }).css({ fontSize: '12px', fontWeight: 'bold' }).add();
				chart.renderer
					.text('hits: ' + counts['in_1'] + ' (' + counts['in_1_pct'].toFixed(1) + '%)', 250, 115)
					.attr({ zIndex: 15, useHTML: true })
					.css({ fontSize: '12px' })
					.add();
				chart.renderer
					.text('missed: ' + counts['out'] + ' (' + counts['out_pct'].toFixed(1) + '%)', 250, 130)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();
				chart.renderer
					.text('MQI90: ' + MQI90, 250, 145)
					.attr({ zIndex: 15 })
					.css({ fontSize: '12px' })
					.add();
				//chart.renderer.text('S90: '+S90, 280, 160).attr({zIndex: 15}).css({fontSize: '12px'}).add();

				// add indicators
				chart.renderer.text('Mean bias > 0', 160, 100).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('Mean bias < 0', 160, 345).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('R', 75, 225).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
				chart.renderer.text('SD', 315, 225).css({ fontSize: '12px', fontStyle: 'italic', color: '#858585' }).add();
			}

			// color the dot
			var dot = document.getElementById('trgt-dot');
			if (dot instanceof HTMLElement) {
				var pct_in = counts['in_1_pct'];
				if (counts['total'] == 0) {
					dot.innerHTML = circleQuestion.html[0];
					dot.style.color = 'lightgray'
					dot.title = `No Data`
				} else if (pct_in >= 90) {
					var svg = circleCheck.html[0]
					dot.innerHTML = svg;
					dot.style.color = '#28a745'
					dot.title = `${pct_in}% hits`
				} else {
					dot.innerHTML = circleXmark.html[0];
					dot.style.color = '#dc3545'
					dot.title = `${pct_in}% hits`
				}
			}
		}
	);
}

export function plotTargetBars(data: MapData): void {
	// keyTime
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);

	// model style
	const modelStyle = utils.getModelStyle(aeroval.model.name);
	if (modelStyle) {
		var modelColor = modelStyle.color;
	} else {
		const highchartsColors = Highcharts.getOptions().colors as string[];
		if (highchartsColors) {
			modelColor = highchartsColors[0] as string;
		}
	}

	// loop through all categories and charts
	for (const category in aeroval.config.bars) {
		for (const div in aeroval.config.bars[category].charts) {
			var barChart = aeroval.config.bars[category].charts[div];
			var dat = [];
			var yJitter = 0;
			var series = [] as any[];
			// check if time or space chart: if time, use statistic as it is
			if (category == 'time') {
				yJitter = 0.25;
				// loop through all stations
				for (const station of data) {
					if (!(aeroval.frequency in station)) {
						break;
					}
					const stationStats = station[aeroval.frequency]?.[keyTime];
					if (stationStats && (aeroval.globalRegions.includes(aeroval.region) || station.region == aeroval.region)) {
						if (barChart.statistic == '1-R' && stationStats['R']) {
							var stat = 1 - (stationStats['R'] as number);
						} else if (barChart.statistic == 'data_std/data_mean' && stationStats['data_std'] && stationStats['data_mean']) {
							var stat = (stationStats['data_std'] as number) / (stationStats['data_mean'] as number);
						} else {
							var stat = stationStats[barChart.statistic] as number;
						}
						// decrease decimals
						if (stat != null) {
							stat = parseFloat(stat.toFixed(4));
						}
						dat.push({
							x: stat,
							y: 0,
							name: station.station_name,
						});
					}
				}
				series.push({
					type: 'scatter',
					data: dat,
					name: 'All stations',
					color: modelColor,
					zIndex: 10,
					marker: {
						symbol: 'diamond',
						radius: 2,
					},
					cursor: 'pointer',
					point: {
						events: {
							click: function () {
								location.href = `/pages/evaluation/?project=${aeroval.project}&experiment=${aeroval.experiment.name}&station=${(this as any).name
									}&parameter=${aeroval.parameter.dir}&observation=${aeroval.observation}&model=${aeroval.model.dir}&layer='${aeroval.layer}`;
							},
						},
					},
					turboThreshold: 0,
				});
			} else if (category == 'space') {
				// loop through all stations and median of statistic
				var dataToAvg = [];
				for (const station of data) {
					if (!(aeroval.frequency in station)) {
						break;
					}
					const stationStats = station[aeroval.frequency]?.[keyTime];
					if (stationStats && (aeroval.globalRegions.includes(aeroval.region) || station.region == aeroval.region)) {
						if (barChart.statistic == '1-R' && stationStats['R']) {
							var stat = 1 - (stationStats['R'] as number);
						} else if (barChart.statistic == 'data_std/data_mean' && stationStats['data_std'] && stationStats['data_mean']) {
							var stat = (stationStats['data_std'] as number) / (stationStats['data_mean'] as number);
						} else {
							var stat = stationStats[barChart.statistic] as number;
						}
						dataToAvg.push(stat);
					}
				}

				if (utils.quantile(dataToAvg, 0.5)) {
					var x = utils.quantile(dataToAvg, 0.5) as number | undefined;
				} else {
					x = undefined
				}

				if (dataToAvg.length > 0) {
					dat.push({
						x: x,
						y: 0,
						name: 'Spacial Median',
					});
				}
				series.push({
					type: 'scatter',
					data: dat,
					name: 'All stations',
					color: modelColor,
					zIndex: 10,
					marker: {
						symbol: 'diamond',
						radius: 4,
					},
				});
			}

			// add plotBands
			var plotBands = [];
			for (const band of barChart.bands) {
				plotBands.push({
					from: band.range[0],
					to: band.range[1],
					color: band.color,
				});
			}

			Highcharts.chart(
				div,
				{
					chart: {
						renderTo: div,
						height: 60,
					},
					exporting: {
						enabled: false,
					},
					title: {
						text: '',
					},
					subtitle: {
						text: '',
					},
					tooltip: {
						valueDecimals: 2,
						headerFormat: '',
						pointFormat: '<b>{point.name}</b><br>x: {point.x}',
					},
					xAxis: {
						min: barChart.range[0],
						max: barChart.range[1],
						plotBands: plotBands,
					},
					yAxis: {
						min: 0,
						max: 0,
						tickInterval: 0,
						title: {
							text: barChart.title,
							useHTML: true,
						},
						gridLineWidth: 0,
					},
					plotOptions: {
						scatter: {
							jitter: {
								y: yJitter,
							},
						},
					},
					series,
					legend: {
						enabled: false,
					},
				},
				function (chart): void {
					// color the dot

					// 1. get color interval
					var bands = (chart.xAxis[0] as any).plotLinesAndBands;
					var from = [];
					var to = [];
					for (const band of bands) {
						from.push(band.options.from);
						to.push(band.options.to);
					}
					var min = Math.min.apply(Math, from);
					var max = Math.max.apply(Math, to);

					// 2. check if the dot is between min and max
					var seriesData = chart.series[0].data;
					var n_in = 0;
					var n_out = 0;
					for (const data of seriesData) {
						if (data.x >= min && data.x <= max) {
							n_in++;
						} else {
							n_out++;
						}
					}

					// 3. replace dot
					var dot = document.getElementById(div.replace('bar', 'dot'));
					if (dot instanceof HTMLElement) {
						var pct_in = Math.round((100 * n_in) / (n_in + n_out));
						if (n_in + n_out == 0) {
							dot.innerHTML = circleQuestion.html[0];
							dot.style.color = 'lightgray';
							dot.title = 'No Data';
						} else if (pct_in >= 90) {
							dot.innerHTML = circleCheck.html[0];
							dot.style.color = '#28a745'
							dot.title = `${pct_in}% hits`
						} else {
							dot.innerHTML = circleXmark.html[0];
							dot.style.color = '#dc3545';
							dot.title = `${pct_in}% hits`
						}
					}
				}
			);
		}
	}
}

function getTitle(): [string, string] {
	var title = `${aeroval.parameter.name} - ${aeroval.region} - ${aeroval.time}`;
	if (aeroval.season !== 'All') {
		title += ` (${aeroval.season})`;
	}
	var fontSize = '16px'
	if (title.length >= 40) {
		fontSize = '14px'
	}
	return [title, fontSize];
}
