import * as Highcharts from 'highcharts';
import 'highcharts/modules/map';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import { discreteColorscale } from '../../colorscale';
import * as utils from '../../../utils';
import { updateURLs } from '../../../modules/main';
import { update as evalUpdate } from '../../../modules/evaluation';
import { update as interUpdate } from '../../../modules/intercomp';

import './index.css';

import { Aeroval } from '../../../types/global';
import { MapData, TrendsStats } from '../../../types/data';
declare var aeroval: Aeroval;

export async function plotMap(data: MapData, id = 'map'): Promise<void> {
	const legend = getLegend();
	const stations: any[] = [];
	const stationsName: string[] = [];

	//trends update: if the statistics has a /, then split this and show two statistics (obs and model trends)
	if (aeroval.statistic.dir.includes('/')) {
		const firstUnderscore = aeroval.statistic.dir.indexOf('_');
		const stat1 = `${aeroval.statistic.dir.split('/')[0]}_${aeroval.statistic.dir.slice(firstUnderscore + 1)}`;
		const stat2 = aeroval.statistic.dir.split('/')[1];
		var stats = [stat2, stat1];
	} else {
		stats = [aeroval.statistic.dir];
	}

	for (const stat of stats) {
		if (stat.includes('mod')) {
			var symbol = 'square';
			var radiusFactor = 1.2;
			var zIndex = 0;
			var lineWidth = 0;
			var lineColor = '#C0C0C0';
		} else {
			symbol = getMarkerSymbol() as string;
			radiusFactor = 1;
			zIndex = 1;
			lineWidth = 0;
			if (stats.length > 1) {
				lineWidth = 1;
			}
			lineColor = '#C0C0C0';
		}
		for (const stationData of data) {
			const keyStat = aeroval.frequency;
			const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);
			if (!(keyStat in stationData)) break;
			const statisticsData = stationData[keyStat]?.[keyTime];
			if (!statisticsData) continue;

			// get value from statistics data: nrms is not defined in the files and need to be computed
			var value: number | undefined = undefined;
			if (stat === 'nrms' && statisticsData['rms'] && statisticsData['refdata_mean']) {
				value = (statisticsData['rms'] as number) / (statisticsData['refdata_mean'] as number);
			} else if (statisticsData[stat] != null) {
				// other statistics need to be defined
				if (stat.includes('trend') && (statisticsData[stat] as TrendsStats)['map_var']) {
					var mapVar = (statisticsData[stat] as TrendsStats)['map_var'];
					value = (statisticsData[stat] as TrendsStats)[mapVar] as number;
				} else {
					value = statisticsData[stat] as number;
				}
			}

			// the trends are already provided in percent per year
			if (aeroval.statistic.unit === '%' && value) {
				value = value * 100;
			}

			// set up color
			const scale = getScale();
			const color = value != null ? utils.colStat(value, scale) : '#adadad';

			// set up radius
			var radius = 1.5;
			const stationsRadius = document.querySelector('#stationsRadius');
			if (stationsRadius instanceof HTMLInputElement) {
				radius = parseFloat(stationsRadius.value) - 0.75;
			}

			// limit digits
			const statistic = value != null ? value.toFixed(3) : null;

			// MODIS fix
			const name = typeof stationData['station_name'] !== 'string' ? `(${(stationData['station_name'][0] as number).toFixed(1)}, ${(stationData['station_name'][1] as number).toFixed(1)})` : stationData['station_name'];
			const lat = parseFloat(stationData['latitude']?.toFixed(2));
			const lon = parseFloat(stationData['longitude']?.toFixed(2));
			const alt = parseFloat(stationData['altitude']?.toFixed(2));

			const station = {
				name,
				lat,
				lon,
				alt,
				marker: {
					radius: radius * radiusFactor,
					fillColor: color,
					symbol,
					zIndex,
					lineColor,
					lineWidth,
				},
				// more entries for the table
				region: stationData['region'],
				statistic,
				[keyStat]: stationData[keyStat],
				zIndex,
			};
			if (statistic != null) {
				stations.push(station);
			}
			stationsName.push(name);

		}
	}

	// map zoom and position
	const [center, zoom] = getMapInitialPosition();
	// set title
	const titleText = getTitle();
	//set subtitle
	const subTitleText = getSubTitle();

	// load world map
	if (!aeroval.topology) {
		aeroval.topology = await fetch('../../geography/world/mercator_high.geo.json').then((response) => response.json());
	}

	const mapElement = document.getElementById(id);
	if (!mapElement) return;

	// Initiate the chart
	Highcharts.mapChart(
		mapElement,
		{
			chart: {
				renderTo: id,
				map: aeroval.topology,
				animation: false
			},
			exporting: {
				allowHTML: true,
				chartOptions: {
					chart: {
						marginBottom: 50,
					},
					legend: {
						y: 0,
						x: 150,
						width: 540,
					},
				},
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: subTitleText,
			},
			mapNavigation: {
				enabled: true,
				enableButtons: false,
				enableMouseWheelZoom: true,
				enableDoubleClickZoomTo: true,
			},
			mapView: {
				center,
				zoom,
			},
			tooltip: {
				headerFormat: '',
				pointFormat: `<b>{point.name}</b><br>Lat: {point.lat}, Lon: {point.lon}<br>Alt: {point.alt} m <br>${aeroval.statistic.name}: {point.statistic}`,
				valueDecimals: 2,
			},
			plotOptions: {
				series: {
					turboThreshold: 0,
				},
			},
			legend: {
				useHTML: true,
				floating: true,
				align: 'center',
				symbolRadius: 0,
				symbolWidth: 0,
				labelFormatter: function () {
					return legend as string;
				},
			},
			series: [
				{
					// Use the gb-all map with no data as a basemap
					name: 'Basemap',
					borderColor: '#FFFFFF',
					nullColor: `rgba(175, 175, 175, ${1 - Number((document.querySelector('#opacityRange') as HTMLInputElement).value)})`,
					showInLegend: false,
				},
				{
					// Specify points using lat/lon
					type: 'mappoint',
					name: 'Stations',
					animation: false,
					data: stations,
					marker: {
						symbol: 'circle',
					},
					stickyTracking: false,
					cursor: 'pointer',
					dataLabels: {
						enabled: false,
					},
					showInLegend: true,
					point: {
						events: {
							legendItemClick: function () {
								return false; // <== returning false will cancel the default action
							},
							mouseOver: function () {
								if (aeroval.settings.presentationMode !== 'true') {
									// check if not in 3d tab
									document.querySelector('#hoverLoaderMap')?.classList.remove('hide');
									document.querySelector('#hoverLoaderMap')?.classList.add('show');
									aeroval.wait = false;
									const that = this as any;
									aeroval.timeOver = setTimeout(function () {
										if (aeroval.wait === false) {
											aeroval.station = that.name;
											if (window.location.pathname.includes('/evaluation')) {
												evalUpdate('station', that.name);
											} else if (window.location.pathname.includes('/intercomp')) {
												interUpdate('station', that.name);
											} else {
												utils.todo();
											}
											document.querySelector('#hoverLoaderMap')?.classList.remove('show');
											document.querySelector('#hoverLoaderMap')?.classList.add('hide');
										}
									}, 2000);
								}
							},
							mouseOut: function () {
								if (!aeroval.isMobile) {
									aeroval.wait = true;
									clearTimeout(aeroval.timeOver);
									document.querySelector('#hoverLoaderMap')?.classList.remove('show');
									document.querySelector('#hoverLoaderMap')?.classList.add('hide');
								}
							},
							click: function () {
								aeroval.wait = false;
								clearTimeout(aeroval.timeOver);
								const that = this as any;
								aeroval.station = that.name;
								if (window.location.pathname.includes('/evaluation')) {
									evalUpdate('station', that.name);
								} else if (window.location.pathname.includes('/intercomp')) {
									interUpdate('station', that.name);
								} else {
									utils.todo();
								}
								document.querySelector('#hoverLoaderMap')?.classList.remove('show');
								document.querySelector('#hoverLoaderMap')?.classList.add('hide');
							},
						},
					},
				},
			] as any,
		},
		function (chart: any) {
			const mapElement = document.querySelector('#map');
			if (mapElement) {
				mapElement.addEventListener('mouseout', () => {
					if (chart && chart.mapView) {
						aeroval.mapCenter = chart.mapView.center;
						aeroval.mapZoom = chart.mapView.zoom;
					}
				});
			}

			//send the list of stations to the statForm
			autocomplete(document.getElementById('statForm') as HTMLInputElement, stationsName);

			// add regions
			plotRegions(chart);

			// print map Table
			mapTable(data);
		}
	);

	// if click on a country, hide the station
	// Get all elements with class 'highcharts-background'
	const highchartsBackgrounds = document.querySelectorAll('.highcharts-background');
	highchartsBackgrounds.forEach((background) => {
		background.addEventListener('click', () => {
			hideSelectedStation();
		});
	});

	// if click on export button, hide station by default
	// Get the parent element
	const parentElement = document.querySelector('#map > * > svg > g.highcharts-exporting-group > g');
	if (parentElement) {
		// Add event listener for 'click' event
		parentElement.addEventListener('click', () => {
			hideSelectedStation();
		});
	}

	if (aeroval.data.map) {
		checkStation(aeroval.data.map);
	}
}

function getMapInitialPosition(): [Aeroval['mapCenter'], Aeroval['mapZoom']] {
	var center: undefined | [number, number] = undefined;
	var zoom: undefined | number = undefined;
	if (aeroval.config.map && aeroval.cfg.webdisp_opts.map_zoom in aeroval.config.map) {
		center = aeroval.config.map[aeroval.cfg.webdisp_opts.map_zoom].center;
		zoom = aeroval.config.map[aeroval.cfg.webdisp_opts.map_zoom].zoom;
	}
	if (aeroval.mapCenter && aeroval.mapZoom) {
		center = aeroval.mapCenter;
		zoom = aeroval.mapZoom;
	}
	return [center, zoom];
}

function getLegend(): string | undefined {
	var legend: string | undefined = undefined;

	const legSize = 12;
	const unit = utils.getUnits();
	const legendUnit = unit === '(1)' ? '' : ` ${unit}`;
	const scale = getScale();

	legend = `<table id='tabLegend' class='center' style='font-weight: initial; text-align: center; font-size: 10px;'>
		<tr class='center'><td colspan='17'>${aeroval.statistic.name}${legendUnit} </td></tr>
		<tr style='height: " + legSize + "px'>`

	for (let i: number = 0; i < scale.length; i++) {
		let name = scale[i].toString();
		if (i == 0) {
			name = `${name}<`;
		}
		if (i == scale.length - 1) {
			name = `>${name}`;
		}
		legend += `<td style='width:${legSize}px'>${name}</td>`;
		if (i < scale.length - 1) {
			legend += `<td>
				<span class='legend-item' style='font-size: 12pt; color:${discreteColorscale(scale[i + 1], scale)}'>●</span>
				</td>`
		}
	}
	legend += `</tr></table>`;

	return legend;
}

function getScale(): number[] {
	if (!aeroval.statistic.scale) {
		var scale = aeroval.ranges[aeroval.parameter.dir].scale;
	} else {
		if (aeroval.statistics[aeroval.statistic.dir].unit === 'var') {
			const statScale = aeroval.ranges[aeroval.parameter.dir].scale;
			scale = aeroval.statistic.scale.map((x) => x * Math.max(...statScale));
			scale = scale.map((x) => parseFloat(x.toFixed(2))) as number[];
		} else {
			scale = aeroval.statistic.scale;
		}
	}
	return scale;
}

function getMarkerSymbol(): string | undefined {
	// get marker symbol from man menu
	let markerSymbol = 'circle';
	const markerInputs = document.querySelectorAll('#markerSymbol>input');
	if (!markerInputs) return;

	for (let input of markerInputs) {
		var element = input as HTMLInputElement;
		if (element.checked) {
			markerSymbol = element.value;
		}
	}
	return markerSymbol;
}

function mapTable(stations: MapData): void {
	/*mapTable*/
	const keyStat = aeroval.frequency;
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);
	const statistics = Object(aeroval.statistics).keys;

	var h = `<table class='table table-hover table-condensed slideTable'>
		<thead>
		<tr>
		<th rowspan=2>Station</th>
		<th rowspan=2>Lat</th>
		<th rowspan=2>Lon</th>
		<th rowspan=2>Region</th>
		<th colspan=${statistics}>Statistics</th>
		</tr>
		<tr>
		<th>${aeroval.statistic.name}</th>
		</tr>
		</thead>
		<tbody>`
	for (let station of stations) {
		h += `<tr>
			<td>${station.station_name}</td>
			<td>${station.latitude.toFixed(2)}</td>
			<td>${station.longitude.toFixed(2)}</td>
			<td>${station.region}</td>`
		if (!station[keyStat]) continue;
		if (aeroval.statistic.dir == 'obs/mod_trend') {
			const statObs = station[keyStat]?.[keyTime]['obs_trend'] as TrendsStats;
			const statMod = station[keyStat]?.[keyTime]['mod_trend'] as TrendsStats;
			const mapVarObs = statObs.map_var;
			const mapVarMod = statMod.map_var;
			const slopeY0Obs = statObs[mapVarObs];
			const slopeY0Mod = statMod[mapVarMod];
			h += `< td > ${slopeY0Obs?.toFixed(3)} / ${slopeY0Mod?.toFixed(3)}</td > `;
		} else {
			const statistic = station[keyStat]?.[keyTime][aeroval.statistic.dir];
			if (!statistic) continue;
			//if the statistic is a trend, this is an object and not a number
			if (typeof statistic === 'number') {
				h += statistic != null ? `<td>${statistic.toFixed(3)}</td>` : `<td>${statistic}</td>`;
			} else if (typeof statistic == 'object') {
				//print slp as respect to first year
				const mapVar = (statistic as TrendsStats).map_var;
				const slopeY0 = (statistic as TrendsStats)[mapVar];
				h += `<td>${slopeY0?.toFixed(3)}</td>`;
			}
		}
		h += '</tr>';
	}
	h += `</tbody>
		</table>`;

	const mapTable = document.querySelector('#mapTable');
	if (mapTable instanceof HTMLElement) {
		mapTable.innerHTML = h;
	}
}

function plotRegions(chart: Highcharts.Chart) {
	const regColor = aeroval.settings.theme == 'dark' ? 'rgba(200,200,200,0.5)' : 'rgba(100,100,100,0.5)';

	//check if region is defined as a rectangle
	for (let region in aeroval.regions) {
		//if rectangle
		if (aeroval.regions[region].minLon) {
			//get coordinates - specific case of World, since the map does not go to -90 in lat
			const minLon = aeroval.regions[region].minLon;
			const maxLon = aeroval.regions[region].maxLon;
			const minLat = aeroval.regions[region].minLat;
			const maxLat = aeroval.regions[region].maxLat;

			// points defining regions boundaries
			if (minLon <= maxLon) {
				const longitudes = [minLon, maxLon, maxLon, minLon, minLon];
				const latitudes = [minLat, minLat, maxLat, maxLat, minLat];
				const coordinates = [];
				for (const j in longitudes) {
					coordinates.push([longitudes[j], latitudes[j]]);
				}

				// plot lines in between each point
				chart.addSeries(
					{
						id: region,
						type: 'mapline',
						data: [
							{
								geometry: {
									type: 'LineString',
									coordinates: coordinates,
								},
							},
						],
						lineWidth: 1,
						zIndex: 10,
						color: regColor,
						nullColor: regColor,
						showInLegend: false,
						visible: false,
					},
					false,
					false
				);

				// else, the region shall be split in two parts
			} else {
				// draw left part
				const leftLongitudes = [-180, maxLon, maxLon, -180];
				const leftLatitudes = [minLat, minLat, maxLat, maxLat];
				const leftCoordinates = [];
				for (const j in leftLongitudes) {
					leftCoordinates.push([leftLongitudes[j], leftLatitudes[j]]);
				}

				// plot lines in between each point
				chart.addSeries(
					{
						id: region,
						type: 'mapline',
						data: [
							{
								geometry: {
									type: 'LineString',
									coordinates: leftCoordinates,
								},
							},
						],
						lineWidth: 1,
						zIndex: 10,
						color: regColor,
						nullColor: regColor,
						showInLegend: false,
						visible: false,
					} as any,
					false,
					false
				);

				// draw right part
				const rightLongitudes = [180, minLon, minLon, 180];
				const rightLatitudes = [minLat, minLat, maxLat, maxLat];
				const rightCoordinates = [];
				for (let j in rightLongitudes) {
					rightCoordinates.push([rightLongitudes[j], rightLatitudes[j]]);
				}

				// plot lines in between each point
				chart.addSeries(
					{
						id: `${region}-2`,
						//linkedTo: region,
						type: 'mapline',
						data: [
							{
								geometry: {
									type: 'LineString',
									coordinates: rightCoordinates,
								},
							},
						],
						lineWidth: 1,
						zIndex: 10,
						color: regColor,
						nullColor: regColor,
						showInLegend: false,
						visible: false,
					},
					false,
					false
				);
			}

			// if lons lats arrays
		} else if (aeroval.regions[region].lats) {
			//get coordinates - specific case of World, since the map does not go to -90 in lat
			const longitudes = aeroval.regions[region].lons as any[];
			const latitudes = aeroval.regions[region].lats as any[];
			const coordinates = [];
			for (const j in longitudes) {
				coordinates.push([longitudes[j], latitudes[j]]);
			}

			// plot lines in between each point
			chart.addSeries(
				{
					id: region,
					type: 'mapline',
					data: [
						{
							geometry: {
								type: 'LineString',
								coordinates: coordinates,
							},
						},
					],
					lineWidth: 1,
					zIndex: 10,
					color: regColor,
					nullColor: regColor,
					showInLegend: false,
					visible: false,
				},
				false,
				false
			);
		} else {
			//country code
			var codes = aeroval.regions[region];
			if (Array.isArray(codes)) {
				for (const j in codes) {
					codes[j] = codes[j].toUpperCase();
				}
			} else {
				codes = codes.toUpperCase();
			}

			const data = [[region, 1]];

			if (region != '') {
				chart.addSeries(
					{
						name: region,
						id: region,
						borderWidth: 1,
						borderColor: 'rgba(255, 165, 0, 1)',
						zIndex: 100,
						color: 'rgba(255, 165, 0, 0.5)',
						keys: ['admin', 'value'],
						joinBy: 'admin',
						allAreas: false,
						data: data,
						dataLabels: {
							enabled: true,
							color: '#FFFFFF',
							formatter: function () {
								return (this as any).point.name;
							},
						},
						showInLegend: false,
						visible: false,
					} as any,
					false
				);
			}
		}
	}
	// redraw chart
	chart.redraw();
}

export function checkStation(data: MapData) {
	// extracts coordinates to find closest station if station not in list
	const stations: string[] = [];
	const lats: number[] = [];
	const lons: number[] = [];
	for (let item of data) {
		stations.push(item.station_name);
		lats.push(item.latitude);
		lons.push(item.longitude);
	}
	// check if station is in the list
	if (stations.includes(aeroval.station) || Object.keys(aeroval.regions).includes(aeroval.station)) {
		showStation(aeroval.station);
	} else {
		const iClosest = utils.calcDist(aeroval.lastLatitude, aeroval.lastLongitude, lats, lons);
		aeroval.station = stations[iClosest];
		if (window.location.pathname.includes('/evaluation')) {
			evalUpdate('station', aeroval.station);
		} else if (window.location.pathname.includes('/intercomp')) {
			interUpdate('station', aeroval.station);
		} else {
			utils.todo();
		}
	}
}

export function showStation(station_name: string) {
	console.log('showStation', station_name);
	const chart = utils.getChartById('map');
	if (!chart || !aeroval.data.map) return;

	//remove previously selected stations
	if (typeof chart.get('SelectedStation') != 'undefined') {
		(chart as any).get('SelectedStation').remove();
	}

	// set up color
	const color = aeroval.settings.theme == 'dark' ? '#ffffff' : '#000000';

	// grep info of selected station
	var selectedStation = undefined;
	for (let stationData of aeroval.data.map) {
		if (stationData.station_name == station_name) {
			var lat = stationData.latitude;
			var lon = stationData.longitude;
			selectedStation = {
				name: stationData.station_name,
				lat,
				lon,
				color: '#404040',
			};
			aeroval.lastLatitude = selectedStation.lat;
			aeroval.lastLongitude = selectedStation.lon;
			break;
		}
	}

	if (selectedStation) {
		const selectedSeries = {
			type: 'mappoint',
			name: 'SelectedStation',
			id: 'SelectedStation',
			data: [selectedStation],
			zIndex: 1000,
			marker: {
				symbol: 'circle',
				radius: Number((document.querySelector('#stationsRadius') as HTMLInputElement).value) / 2,
				color,
			},
			stickyTracking: false,
			dataLabels: {
				enabled: true,
				color: '#404040',
			},
			showInLegend: false,
			enableMouseTracking: false,
		} as any;

		//send the serie to the map
		chart.addSeries(selectedSeries, true, false);

		//if mouse not in map
		if (aeroval.goto) {
			aeroval.mapCenter = [selectedStation.lon, selectedStation.lat];
			aeroval.mapZoom = 5;
			(chart as any).mapView.setView(aeroval.mapCenter, aeroval.mapZoom);
			aeroval.goto = false;
		}
	}

	// update URL
	if (aeroval.post_first_station || !['ALL', 'WORLD'].includes(aeroval.station)) {
		updateURLs('station', aeroval.station);
	}
	aeroval.post_first_station = true;
}

export function hideSelectedStation() {
	const chart = utils.getChartById('map');
	//remove previously selected stations
	if (chart) {
		(chart as any).get('SelectedStation')?.remove();
	}
}

function autocomplete(inp: HTMLInputElement, arr: string[]) {
	let currentFocus: number;

	inp.addEventListener('input', () => {
		let a: HTMLDivElement,
			b: HTMLDivElement,
			val: string = inp.value;
		closeAllLists();
		if (!val) {
			return false;
		}
		currentFocus = -1;
		a = document.createElement('DIV') as HTMLDivElement;
		a.setAttribute('id', inp.id + 'autocomplete-list');
		a.setAttribute('class', 'autocomplete-items');
		inp.parentNode?.appendChild(a);
		for (let ar of arr) {
			if (ar.substring(0, val.length).toUpperCase() == val.toUpperCase()) {
				b = document.createElement('DIV') as HTMLDivElement;
				b.innerHTML = `<strong>${ar.substring(0, val.length)}</strong>${ar.substring(val.length)}<input type='hidden' value='${ar}'>`
				b.addEventListener('click', function () {
					inp.value = this.getElementsByTagName('input')[0].value;
					aeroval.goto = true;
					if (window.location.pathname.includes('/evaluation')) {
						evalUpdate('station', inp.value);
					} else if (window.location.pathname.includes('/intercomp')) {
						interUpdate('station', inp.value);
					} else {
						utils.todo();
					}
					closeAllLists();
				});
				a.appendChild(b);
			}
		}
	});

	inp.addEventListener('keydown', (e) => {
		let x: HTMLDivElement[] | undefined = undefined;
		let tempX = document.getElementById(inp.id + 'autocomplete-list');
		if (tempX) {
			x = Array.from(tempX.getElementsByTagName('div')) as HTMLDivElement[];
		}
		if (e.key == 'ArrowDown') {
			currentFocus++;
			addActive(x);
		} else if (e.key == 'ArrowUp') {
			currentFocus--;
			addActive(x);
		} else if (e.key == 'Enter') {
			e.preventDefault();
			if (currentFocus > -1) {
				if (x) x[currentFocus].click();
			}
		}
	});

	function addActive(x: HTMLDivElement[] | undefined) {
		if (!x) return false;
		removeActive(x);
		if (currentFocus >= x.length) currentFocus = 0;
		if (currentFocus < 0) currentFocus = x.length - 1;
		x[currentFocus].classList.add('autocomplete-active');
	}

	function removeActive(x: HTMLDivElement[]) {
		for (let item of Array.from(x)) {
			item.classList.remove('autocomplete-active');
		}
	}

	function closeAllLists(element?: HTMLElement) {
		let items = document.getElementsByClassName('autocomplete-items');
		for (let item of Array.from(items)) {
			if (element != item && element != inp) {
				item.parentNode?.removeChild(item);
			}
		}
	}

	document.addEventListener('click', function (e) {
		closeAllLists(e.target as HTMLElement);
	});
}

function getTitle(): string {
	var title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	title += ` - ${aeroval.time}`;
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}

function getSubTitle(): string {
	if (aeroval.statistic.name.includes('Mod')) {
		var subtitle = `${aeroval.model.name} - ${aeroval.frequency} data`;
	} else if (aeroval.statistic.name.includes('Obs')) {
		var subtitle = `${aeroval.observation} - ${aeroval.frequency} data`;
	} else {
		var subtitle = `${aeroval.observation} - ${aeroval.model.name} - ${aeroval.frequency} data`;
	}
	return subtitle;
}
