import 'bootstrap5-toggle';

import { topMenu, showMenu, getQueryStrings, getExperiments, updateURLs, getExtremeDates } from './main';
import * as utils from '../utils';

import { plotHeatmap } from '../components/charts/heatmap';
import { plotGauges } from '../components/charts/gauges';
import { plotScatterPlotOverall } from '../components/charts/scatter-plot-overall';
import { plotTimeSeriesStatistics } from '../components/charts/time-series-statistics';
import { plotMiniMap } from '../components/charts/mini-map';
import { plotTaylor } from '../components/charts/taylor';
import { plotTargetCircle } from '../components/charts/targets';
import { plotTargetBars } from '../components/charts/targets';
import { plotForecast } from '../components/charts/time-series-forecast';
import { getCookie, setCookie } from '../components/cookies';

/*import interfaces*/
import { Aeroval } from '../types/global';
import { MapData } from '../types/data';

// Extend the Globals interface
interface ExtendedGlobals extends Aeroval {
	sound: boolean;
	play: boolean;
	hide: string[];
	tabsMenuButtons: {
		[key: string]: Aeroval['menuButtons'];
	};
	soundBox: any;
	genres: string[];
	genre: string;
	annotations: boolean;
}
declare var aeroval: ExtendedGlobals;

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
	faCircle
} from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faCircle);

const circle = icon({ prefix: 'fas', iconName: 'circle' });


document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/overall')) {
		init();
	}
});

function init(): void {
	console.log('initialize overall page');
	utils.initAPI();

	// set some defaults
	aeroval.axisType = 'linear'; //"logarithmic" //"linear"
	aeroval.frequency = 'monthly';
	aeroval.hide = [];
	aeroval.tab = 'heatmap';
	aeroval.sound = false;
	aeroval.play = false;
	aeroval.seriesVisibility = {};
	aeroval.tabsMenuButtons = {
		heatmap: ['obsType', 'region', 'time', 'statistic'],
		taylor: ['parameter', 'observation', 'region', 'time'],
		timeseries: ['parameter', 'observation', 'region', 'yearAndGroupBy', 'meanAndStatistic'],
		target: ['parameter', 'observation', 'model', 'region', 'time'],
		median: ['parameter', 'observation', 'region', 'time', 'statistic'],
	};

	//check if any queries
	getQueryStrings();

	fetch('../../config/gauges.json')
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.config.gauges = data;
			makeDivs();
			aeroval.menuButtons = aeroval.tabsMenuButtons[aeroval.tab];
			// experiments
			getExperiments(true, false, true, true, true);
			//update header links with qs project
			updateURLs('project', aeroval.project);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});

	/*
	if (aeroval.sound){
		$("#soundBox").css("display","initial")

		var fn = "../includes/config/soundBox.json"
		$.getJSON(fn).done(function(data) {
			aeroval.soundBox = data
			aeroval.genres = Object.keys(aeroval.soundBox)
			//by default: select first genre
			aeroval.genre = aeroval.genres[0]

			// Fill menu
			var h =""
			h+="<div id='list-genres' class='btn-group btn-group-toggle' data-toggle='buttons'>"
				for (var i=0; i<aeroval.genres.length; i++){
					if (aeroval.genre==aeroval.genres[i]){
						h+="<label class='btn btn-light btn-sm active'>"
							h+="<input type='radio' name='genre-"+aeroval.genres[i]+"' autocomplete='off' checked>"+aeroval.genres[i]
						h+="</label>"
					} else {
						h+="<label class='btn btn-light btn-sm'>"
							h+="<input type='radio' name='genre-"+aeroval.genres[i]+"' autocomplete='off'>"+aeroval.genres[i]
						h+="</label>"
					}
				}
			h+="</div>"
			document.getElementById("musicGenreButtons").innerHTML = h
			//event on bootstrap buttons
			$("#list-genres input").on("click", function() {
				aeroval.genre = this.name.split("-")[1]
				soundBox.data2partition()
				var settings = aeroval.soundBox[aeroval.genre].instruments["Main Keyboard"]
				aeroval.hm_synth = soundBox.createSynth(settings)
			});
		})
	}

	$("#playButton").on('click', function() {
		//toggle aeroval.play state
		aeroval.play = !aeroval.play;
		if (aeroval.play) {
			soundBox.playData()
			document.getElementById("faPlay").innerHTML = "<i id='faPlay' class='fas fa-stop'></i>"
		} else {
			document.getElementById("faPlay").innerHTML = "<i id='faPlay' class='fas fa-play'></i>"
			//stop synths
			var synth_names = Object.keys(aeroval.synths)
			for (var i=0; i<synth_names.length; i++){
				soundBox.stopSynth(synth_names[i])
			}
		}
	})
	*/

	// Set up actions on tab change
	document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((tabButton) => {
		tabButton.addEventListener('shown.bs.tab', (e: Event) => {
			const target = e.target as HTMLElement;
			const tabId = target.id.split('-')[1];
			aeroval.tab = tabId;
			// delete aeroval zoom
			delete aeroval.zoom;
			// update header urls
			updateURLs('tab', aeroval.tab);
			// make divs
			makeDivs();
			aeroval.menuButtons = aeroval.tabsMenuButtons[aeroval.tab];
			getExperiments(true, false, true, true, true);
		});
	});

	// Show active tab
	const tabs = Object.keys(aeroval.tabsMenuButtons);
	const isTabInTabs = tabs.includes(aeroval.tab)
	const selectedTab = isTabInTabs ? aeroval.tab : tabs[0]

	tabs.forEach((tab) => {
		const tabButton = document.querySelector(`#nav-${tab}-tab`);
		const tabContent = document.querySelector(`#nav-${tab}`);
		if (tabButton && tabContent) {
			if (tab === selectedTab) {
				tabButton.classList.add('active');
				tabContent.classList.add('show', 'active');
				aeroval.tab = selectedTab;
				utils.updateQueryString('tab', selectedTab)
			} else {
				tabButton.classList.remove('active');
				tabContent.classList.remove('show', 'active');
			}
		}
	});

}

function loadTarget(target_data: MapData, plotTarget = true) {
	fetch('../../config/target.json')
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.config.target = data;
			if (plotTarget) {
				plotTargetCircle(target_data);
				plotTargetBars(target_data);
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function loadForecast(plot_forecast = true): void {
	const url = `${window.API_ROOT}/forecast/${aeroval.project}/${aeroval.experiment.name}/${aeroval.region}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			if (plot_forecast) {
				plotForecast(data);
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function loadHeatmap(plot = 'heatmap'): void {
	//prepare the heatmap with right number of pixels
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);
	const url = `${window.API_ROOT}/heatmap/${aeroval.project}/${aeroval.experiment.name}/${aeroval.frequency}/${aeroval.region}/${keyTime}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			if (aeroval.cfg.webdisp_opts.var_order_menu?.length > 0) {
				var orderedData = reorderDataByCfgOrder(data)
				//var orderedData = data
			} else {
				orderedData = data
			}
			aeroval.data.hm = orderedData
			if (plot == 'heatmap') {
				plotHeatmap(orderedData);
				plotGauges(aeroval.config.gauges);
			} else if (plot === 'taylor') {
				plotTaylor(orderedData);
				loadMapData(true);
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function reorderDataByCfgOrder(data: any): any {
	// Get the cfg order array
	const cfgOrder = aeroval.cfg.webdisp_opts.var_order_menu;

	// Initialize orderedData
	const orderedData: any = {};

	// Reorder data using cfgOrder
	for (const cfgParameter of cfgOrder) {
		if (data.hasOwnProperty(cfgParameter)) {
			orderedData[cfgParameter] = data[cfgParameter];
		}
	}

	return orderedData;
}

export function loadScat(selectedPixel: any, preview: boolean = false,): void {
	const url = `${window.API_ROOT}/scat/${aeroval.project}/${aeroval.experiment.name}/${selectedPixel.obs}/${selectedPixel.var}/${selectedPixel.lay}/${selectedPixel.mod}/${selectedPixel.modVar}/${aeroval.cfg.time_cfg.periods[0].replaceAll('/', '')}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			document.body.style.cursor = 'progress';
			plotScatterPlotOverall(data, preview, selectedPixel);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function updateAxisType(div: string): void {
	document.body.style.cursor = 'progress';
	const chart = utils.getChartById(div);
	if (!chart) return;

	if (aeroval.axisType == 'linear') {
		chart.xAxis[0].update(
			{
				softMin: 0,
				type: aeroval.axisType,
			},
			false
		);
		chart.yAxis[0].update(
			{
				softMin: 0,
				type: aeroval.axisType,
			},
			false
		);
	} else if (aeroval.axisType == 'logarithmic') {
		chart.xAxis[0].update(
			{
				type: aeroval.axisType,
			},
			false
		);
		chart.yAxis[0].update(
			{
				type: aeroval.axisType,
			},
			false
		);
	}
	chart.redraw();

	document.body.style.cursor = 'default';
}

export function callbackMenu() {
	if (aeroval.tab === 'heatmap') {
		document.getElementById('gauges')!.style.display = 'block';
		document.getElementById('minimap')!.style.display = 'none';
		loadHeatmap('heatmap');
	} else if (aeroval.tab === 'taylor' || aeroval.tab === 'timeseries' || aeroval.tab === 'target' || aeroval.tab === 'median') {
		document.getElementById('gauges')!.style.display = 'none';
		document.getElementById('minimap')!.style.display = 'block';
		if (aeroval.tab === 'taylor') {
			loadHeatmap('taylor');
		} else if (aeroval.tab === 'timeseries') {
			loadTimeSeries(true);
		} else {
			loadMapData(true, aeroval.tab);
		}
	}
}

export function update(which: string, what: any): void {
	console.log('update ', which, 'with ', what);
	if (which == 'meanAndStatistic' || which == 'statistic' || which == 'forecast_statistic') {
		aeroval.statistic = aeroval.statistics[what];
		aeroval.statistic.dir = what;
		if (what == 'data_mean') {
			aeroval.statistic.name = 'Mean';
			aeroval.statistic.longname = 'Mean (Obs and Model)';
		}
		topMenu();
		utils.updateQueryString('statistic', what);
		updateURLs('statistic', what);
	}
	if (which == 'region') {
		aeroval[which] = what;
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'experiment') {
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		getExperiments(true);
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'obsType') {
		aeroval[which] = what;
		topMenu();
	}
	if (which == 'frequency') {
		aeroval[which] = what;
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'axisType') {
		aeroval[which] = what;
		updateAxisType('scatt');
	}
	if (which == 'time') {
		aeroval.time = what;
		topMenu();
		getExtremeDates();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which.includes('year')) {
		aeroval.time = what;
		showMenu(which, what);
		getExtremeDates();
		updateTimeSeries();
		utils.updateQueryString('time', what);
		updateURLs('time', what);
	}
	if (which == 'groupBy') {
		aeroval[which] = what;
		if (aeroval.data.hm_ts) {
			plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
		}
	}
	if (which == 'season') {
		aeroval[which] = what;
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'parameter') {
		aeroval.parameter.dir = what;
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'observation') {
		aeroval.observation = what;
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
	if (which == 'model') {
		aeroval.model = {
			name: what,
			dir: what,
			var: aeroval.modVars[what]
		}
		topMenu();
		utils.updateQueryString(which, what);
		updateURLs(which, what);
	}
}

function hideScat() {
	document.querySelector('#heatmap')?.classList.remove('col-md-7');
	document.querySelector('#heatmap')?.classList.add('col-md-12');
	const scatterPlot = document.querySelector('#scatterplot');
	if (scatterPlot instanceof HTMLElement) {
		scatterPlot.style.display = 'none';
	}
	if (aeroval.data.hm) {
		plotHeatmap(aeroval.data.hm);
	}
}

function makeDivs() {
	if (aeroval.tab == 'heatmap') {
		// first, empty the other tabs
		for (const tab of ['nav-timeseries', 'nav-taylor', 'nav-target', 'nav-median']) {
			const tabElement = document.getElementById(tab);
			if (tabElement) {
				tabElement.innerHTML = '';
			}
		}

		// then, creates the divs
		var h = "<div id='menu'></div>";
		h += "<div id='main-content'>";
		h += "<div class='row h-100' style='width: 100%; margin: 10px 0px; padding: 0px;'>";
		h += "<div id='heatmap' style='padding: 0;' class='col-md-12 col-sm-12 col-12'></div>";
		h +=
			"<div id='heatmap-span' style='height: 20px; color: #6a6666; margin-top: -30px; z-index: 1; font-style: italic; display: none'>Click on the heatmap to show the associated scatterplot</div>";
		h += "<div id='scatterplot' style='display: none;' class='col-md-0 col-sm-12 col-12 position-relative'>";
		h += `<input id="scatAxisToggle" type="checkbox" data-toggle="toggle" data-onlabel="log" data-offlabel="lin">`;
		h += `<button id='closeScat' type='button' class='btn btn-xs ${aeroval.settings.theme} btn-close position-absolute top-0 end-0' style='z-index: 10;' title='Close'><span aria-hidden='true' style='font-weight: normal; font-size: 16px;'></span></button>`;
		h += "<div id='scatt'></div>";
		h += '</div>';
		h += "<div id='hideElements' class='container'></div>";
		h += '</div>';
		h += '</div>';
		const navHeatmap = document.getElementById('nav-heatmap');
		if (navHeatmap) {
			navHeatmap.innerHTML = h;
		}

		// Initialize toggle
		const toggle = document.getElementById('scatAxisToggle') as HTMLInputElement;
		// initialize bootstrap toggles
		document.querySelectorAll("[data-toggle='toggle']").forEach((element) => {
			(element as any).bootstrapToggle({
				onstyle: aeroval.settings.theme,
				offstyle: aeroval.settings.theme,
				size: 'mini',
			} as BootstrapToggleOptions);
		});

		// Set up function
		toggle.addEventListener('change', function () {
			// If switch off
			if (this.checked) {
				update('axisType', 'logarithmic');
			} else {
				update('axisType', 'linear');
			}
		});

		// Set up close button action
		const closeButton = document.getElementById('closeScat');
		if (closeButton) {
			closeButton.addEventListener('click', function () {
				hideScat();
			});
		}
	} else if (aeroval.tab == 'taylor') {
		// first, empty the other tabs
		for (const tab of ['nav-heatmap', 'nav-timeseries', 'nav-target', 'nav-median']) {
			const tabElement = document.getElementById(tab);
			if (tabElement) {
				tabElement.innerHTML = '';
			}
		}

		// then, creates the divs
		var h = "<div id='menu'></div>";
		h += "<div id='main-content'>";
		h += "<div class='row h-100' style='width: 100%; margin: 10px 0px; padding: 0px;'>";
		h += "<div id='tlrdgrm' class='w-100 h-100'></div>";
		h += '</div>';
		h += '</div>';
		const navTaylor = document.getElementById('nav-taylor');
		if (navTaylor) {
			navTaylor.innerHTML = h;
		}
	} else if (aeroval.tab == 'timeseries') {
		// first, empty the other tabs
		for (const tab of ['nav-heatmap', 'nav-taylor', 'nav-target', 'nav-median']) {
			const tabElement = document.getElementById(tab);
			if (tabElement) {
				tabElement.innerHTML = '';
			}
		}

		//then, creates the divs
		var h = "<div id='menu'></div>";
		h += "<div id='main-content'>";
		h += "<div class='row h-100' style='width: 100%; margin: 10px 0px; padding: 0px;'>";
		h += "<div id='overall-ts' class='w-100 h-100' style='min-height: 500px !important;'></div>";
		h += '</div>';
		h += '</div>';

		const navTimeSeries = document.getElementById('nav-timeseries');
		if (navTimeSeries) {
			navTimeSeries.innerHTML = h;
		}
	} else if (aeroval.tab == 'target') {
		// first, empty the other tabs
		for (const tab of ['nav-heatmap', 'nav-taylor', 'nav-timseries', 'nav-median']) {
			const tabElement = document.getElementById(tab);
			if (tabElement) {
				tabElement.innerHTML = '';
			}
		}

		var h = "<div id='menu'></div>";
		h += "<div id='main-content'>";

		h += "<div class='row' style='margin: 10px 0px; padding: 0px;'>";
		if (aeroval.isMobile) {
			h += "<div id='trgt' style='padding: 0; height: 360px; width: 320px; margin: auto;'></div>";
		} else {
			h += "<div id='trgt' style='padding: 0; height: 400px; width: 400px; margin: auto;'></div>";
		}
		h += "<div class='center target-dot' style='width: 30px;'>";
		h += `<div id='trgt-dot'>${circle.html}</div>`;
		h += '</div>';
		h += '</div>';
		h += "<div id='target-bars'></div>";

		h += '</div>';

		const navTarget = document.getElementById('nav-target');
		if (navTarget) {
			navTarget.innerHTML = h;
		}

		makeBarsDivs();
	} else if (aeroval.tab == 'median') {
		// first, empty the other tabs
		for (const tab of ['nav-heatmap', 'nav-taylor', 'nav-timeseries', 'nav-target']) {
			const tabElement = document.getElementById(tab);
			if (tabElement) {
				tabElement.innerHTML = '';
			}
		}

		// then, creates the divs
		var h = "<div id='menu'></div>";
		h += "<div id='main-content'>";
		h += "<div class='row h-100' style='width: 100%; margin: 10px 0px; padding: 0px;'>";
		h += "<div id='frcst-ts' class='w-100 h-100' style='min-height: 500px !important;'></div>";
		h += '</div>';
		h += '</div>';

		const navMedian = document.getElementById('nav-median');
		if (navMedian) {
			navMedian.innerHTML = h;
		}
	}

	var h = '';
	const nCol = 2;
	const w = 12 / nCol;
	h += "<div class='row'>";
	for (let gauge in aeroval.config.gauges) {
		h += `<div class='gauge col-md-${w} col-3' id='gauge-${gauge}' style='width: 50%; height: 100px' style='border: 1px solid red'></div>`;
	}
	h += '</div>';

	const gauges = document.getElementById('gauges');
	if (gauges) {
		gauges.innerHTML = h;
	}

	// show description if cookie not set to true
	chartDescription(aeroval.tab);
}

function chartDescription(which: string): void {
	const desc = {
		taylor:
			'The <a href="https://doi.org/10.1029/2000JD900719">Taylor diagram</a> is a 2D representation of the model performances using polar coordinates. The radius represents the normalized standard deviation while the angle indicates the correlation. The closer a model is to the Reference point, the higher its performance is.',
		median:
			'This chart allows to investigate how well a model performs as function of forecast/analysis hour. For example, for the <strong>forecasts</strong>, Hour=48 shows the performance of the model to forecast the selected variable 2 days in the future. For the <strong>analyses</strong>, the performance is shown as a function of the analysis hour, i.e. the 24 hours of the day before.',
		target:
			'The <strong>target plots</strong> have originally been designed within the <a href="https://fairmode.jrc.ec.europa.eu/" class="alert-link">FAIRMODE</a> project for quickly identifying if a model satisfies predefined statistics criteria based on comparisons with observations. This includes a circular target (defined using observations uncertainties) as well as multiple bar targets investigating time and space statistics. More information can be found in the FAIRMODE <a href="https://fairmode.jrc.ec.europa.eu/document/fairmode/WG1/MQO_GuidanceV3.2_online.pdf" class="alert-link">Guidance Document.</a> Please note that these plots are based on the <strong>assessment</strong> metrics of FAIRMODE.',
	} as any;

	if (which in desc && getCookie(`close-${which}-alert`) !== 'true') {
		var h = '<div class="alert alert-dismissible fade show my-2" role="alert">';
		h += '<i class="fas fa-info-circle" style="margin: 0px 8px 0px 0px; font-size: large;"></i>';
		h += desc[which];
		h += `<button id="close-${which}-alert" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>`;
		h += '</div>';
		const mainContent = document.getElementById('main-content');
		if (mainContent) {
			mainContent.insertAdjacentHTML('afterbegin', h);
		}

		// set up button action
		const closeButton = document.getElementById(`close-${which}-alert`);
		if (closeButton) {
			closeButton.addEventListener('click', function () {
				setCookie(this.id, 'true', 14);
			});
		}
	}
}

function makeBarsDivs(): void {
	fetch('../../config/bars.json')
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.config.bars = data;
			var h = '';
			for (let category in data) {
				h += "<div id='" + category + "-bars' class='row' style='margin: 10px 0px; padding: 0px;'>";
				h += "<div class='center' style='width: 30px; transform: rotate(-90deg);'>" + data[category].title + '</div>';
				h += "<div style='width: calc(100% - 30px); padding: 0px;'>";

				for (let chart in data[category].charts) {
					h += "<div class='row' style='padding: 0px'>";
					h += "<div id='" + chart + "' style='width: calc(100% - 30px); min-height: 60px !important; padding: 0px'></div>";
					h += "<div class='center target-dot' style='width: 30px;'>";
					h += `<div id='${chart.replace('bar', 'dot')}'>${circle.html}</div>`;
					h += '</div>';
					h += '</div>';
				}
				h += '</div>';
				h += '</div>';
				h += '</div>';
			}
			const targetBars = document.getElementById('target-bars');
			if (targetBars) {
				targetBars.innerHTML = h;
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function loadTimeSeries(force_update = false) {
	if (typeof aeroval.data.hm_ts == 'undefined' || force_update) {
		const url = `${window.API_ROOT}/hm_ts/${aeroval.project}/${aeroval.experiment.name}/${aeroval.region}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
		fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				const orderedData: any = {};
				for (const menuItem in data) {
					orderedData[menuItem] = data[menuItem];
				}
				aeroval.data.hm_ts = orderedData;
				getExtremeDates(true);
				loadMapData(true);
			})
			.catch((error) => {
				console.error('There was a problem with the fetch operation:', error);
			});
	} else {
		plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
	}


	/*
	const pyaerocomVersion = utils.getPyaerocomVersion();
	const comparedVersion = utils.versionCompare(pyaerocomVersion, '0.12.1');
	if (comparedVersion != null && comparedVersion >= 0) {
		if (typeof aeroval.data.hm_ts == 'undefined' || force_update) {
			const urlNew = `${window.API_ROOT}/hm_ts/${aeroval.project}/${aeroval.experiment.name}/hm/ts/${aeroval.region}-${aeroval.observation}-${aeroval.parameter.dir}-${aeroval.layer}.json${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
			const urlOld = `${window.API_ROOT}/hm_ts/${aeroval.project}/${aeroval.experiment.name}/hm/ts/${aeroval.observation}-${aeroval.parameter.dir}-${aeroval.layer}.json${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;

			fetch(urlNew)
				.then((response) => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data) => {
					const orderedData: any = {};
					for (const menuItem in data) {
						orderedData[menuItem] = data[menuItem];
					}
					aeroval.data.hm_ts = orderedData;
					getExtremeDates(true);
					loadMapData(true);
				})
				.catch((error) => {
					console.error('There was a problem with the fetch operation:', error);
					fetch(urlOld)
						.then((response) => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.json();
						})
						.then((data) => {
							const orderedData: any = {};
							for (const menuItem in data) {
								orderedData[menuItem] = data[menuItem];
							}
							aeroval.data.hm_ts = orderedData;
							plotTimeSeriesStatistics(orderedData, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
							loadMapData(true);
						})
						.catch((error) => {
							console.error('There was a problem with the fetch operation:', error);
						});
				});
		} else {
			plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
		}
	} else {
		// old format
		if (typeof aeroval.data.hm_ts == 'undefined' || force_update) {
			const url = `${window.API_ROOT}/hm_ts/${aeroval.project}/${aeroval.experiment.name}/hm/ts/stats_ts.json${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
			fetch(url, {
				method: 'GET',
				headers: {
					'Accept': 'application/json'
				}
			})
				.then((response) => {
					if (!response.ok) {
						throw new Error('Network response was not ok');
					}
					return response.json();
				})
				.then((data) => {
					const orderedData: any = {};
					for (const menuItem in data) {
						orderedData[menuItem] = data[menuItem];
					}
					aeroval.data.hm_ts = orderedData;
					plotTimeSeriesStatistics(orderedData, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
					loadMapData(true);
				})
				.catch((error) => {
					console.error('There was a problem with the fetch operation:', error);
				});
		} else {
			plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
		}
	}
	*/
}

function updateTimeSeries(): void {
	const chart = utils.getChartById('overall-ts');
	if (!chart) return;

	if (aeroval.groupBy == 'None') {
		chart.xAxis[0].update({
			min: aeroval.dateMin?.valueOf(),
			max: aeroval.dateMax?.valueOf(),
		});
		chart.setTitle({ text: `${aeroval.parameter.name} - ${aeroval.region} - ${aeroval.time}` });
	} else if (aeroval.data.hm_ts) {
		plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
	}
}

function selectFirstModel(): void {
	const models = aeroval.menu[aeroval.parameter.dir].obs[aeroval.observation][aeroval.layer];
	const model = Object.keys(models)[0];
	aeroval.model = {
		dir: model,
		var: models[model].model_var,
		name: model,
	};
}

function loadMapData(updateMiniMap = true, plot: 'target' | 'median' | undefined = undefined) {
	// we need to select a model for the mini-map visualization
	if (!aeroval.model?.dir || aeroval.model.dir === 'dummy') {
		selectFirstModel();
	}
	const keyTime = `${aeroval.time}-${aeroval.season}`.replace('-All', '-all')
	const url = `${window.API_ROOT}/map/${aeroval.project}/${aeroval.experiment.name}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}/${aeroval.model.dir}/${aeroval.model.var}/${aeroval.time.replaceAll('/', '')}/${aeroval.frequency}/${keyTime}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url)
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			if (updateMiniMap) {
				plotMiniMap(data as Aeroval['data']['map']);
			}
			if (plot === 'target') {
				if (!aeroval.config.target) {
					loadTarget(data, true);
				} else {
					plotTargetCircle(data);
					plotTargetBars(data);
				}
			}
			if (plot === 'median') {
				loadForecast(true);
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}
