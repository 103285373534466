import * as Highcharts from "highcharts";

import { Aeroval } from "../../types/global";
declare var aeroval: Aeroval;

Highcharts.setOptions({
    chart: {
        backgroundColor: 'transparent',
        events: {
            fullscreenOpen: function () {
                // fullscreen background color
                const fullScreenBackgroundColor = aeroval.settings.theme == 'dark' ? '#282b34' : 'white';
                (this as any).update({
                    chart: {
                        backgroundColor: fullScreenBackgroundColor,
                    },
                });
            },
            fullscreenClose: function () {
                (this as any).update({
                    chart: {
                        backgroundColor: 'transparent',
                    },
                });
            }
        }
    },
    credits: {
        enabled: false,
    },
    accessibility: {
        enabled: false,
    },
    exporting: {
        chartOptions: {
            chart: {
                events: {
                    load: function (this: any) {
                        if (this.resetZoomButton) {
                            this.resetZoomButton.hide();
                        }
                    }
                }
            }
        }
    }
});