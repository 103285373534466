import * as Highcharts from 'highcharts';
import 'highcharts/modules/map';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import * as utils from '../../../utils';

import { Aeroval } from '../../../types/global';
declare var aeroval: Aeroval;

export async function plotMiniMap(data: Aeroval['data']['map']): Promise<void> {
	if (!data) return;

	// map options
	const mapOptions = {
		country: 0.8,
		NED: true,
		radiusFactor: 1,
		zIndex: 1,
		lineWidth: 0,
		lineColor: '#C0C0C0',
	};

	const stationsInRegion = [];
	const otherStations = [];
	for (const stationData of data) {
		// color station if in region
		if (stationData.region == aeroval.region || stationData.region.includes(aeroval.region) || aeroval.globalRegions.includes(aeroval.region)) {
			var radius = 1.75;
			stationsInRegion.push({
				name: stationData.station_name,
				lat: parseFloat(stationData['latitude'].toFixed(2)),
				lon: parseFloat(stationData['longitude'].toFixed(2)),
				alt: stationData['altitude'] ? parseFloat(stationData['altitude'].toFixed(2)) : null,
				marker: {
					radius: radius * mapOptions.radiusFactor,
					zIndex: mapOptions.zIndex,
					lineColor: mapOptions.lineColor,
					lineWidth: mapOptions.lineWidth,
				},
			});
		} else {
			var radius = 1.25;
			otherStations.push({
				name: stationData.station_name,
				lat: parseFloat(stationData['latitude'].toFixed(2)),
				lon: parseFloat(stationData['longitude'].toFixed(2)),
				alt: stationData['altitude'] ? parseFloat(stationData['altitude'].toFixed(2)) : null,
				marker: {
					radius: radius * mapOptions.radiusFactor,
					zIndex: mapOptions.zIndex,
					lineColor: mapOptions.lineColor,
					lineWidth: mapOptions.lineWidth,
				},
			});
		}
	}

	// configure view
	if (aeroval.cfg.webdisp_opts.map_zoom == 'Europe') {
		//zoom over Europe
		var zoom: number | undefined = 3;
	}

	// set rotation
	const centerRegion = getCenterRegion(aeroval.region);
	const rotation = [-centerRegion[0], -centerRegion[1], 0];

	// title and subtitle
	const title = `${aeroval.parameter.name} - ${aeroval.region}`;
	const subtitle = aeroval.observation;

	// load world map
	if (!aeroval.topology) {
		aeroval.topology = await fetch('../../geography/world/mercator_low.geo.json').then((response) => response.json());
	}

	// Initiate the chart
	Highcharts.mapChart('minimap', {
		chart: {
			renderTo: 'minimap',
			map: aeroval.topology,
			animation: false,
			height: 250,
			events: {
				load: function () {
					if (aeroval.cfg.webdisp_opts.map_zoom != 'Europe' && aeroval.globalRegions.includes(aeroval.region)) {
						autoRotate(this);
					}
				}
			},
		},
		exporting: {
			enabled: false,
		},
		title: {
			text: title,
		},
		subtitle: {
			text: subtitle,
		},
		legend: {
			enabled: false,
		},
		mapNavigation: {
			enabled: false,
			enableButtons: false,
		},
		mapView: {
			maxZoom: 30,
			zoom,
			projection: {
				name: 'Orthographic',
				rotation,
				center: centerRegion,
			},
		},
		tooltip: {
			headerFormat: '',
			pointFormat: '<b>{point.name}</b><br>Lat: {point.lat}, Lon: {point.lon}<br>Alt: {point.alt} m',
			valueDecimals: 2,
		},
		plotOptions: {
			series: {
				cursor: 'default',
				clip: false,
				animation: false,
			},
		},
		series: [
			{
				name: 'Graticule',
				id: 'graticule',
				type: 'mapline',
				data: getGraticule(),
				nullColor: 'rgba(120, 120, 120, 0.05)',
				accessibility: {
					enabled: false,
				},
			} as any,
			{
				data: [],
				joinBy: 'name',
				name: 'Countries',
				borderColor: '#FFFFFF',
				nullColor: `rgba(175, 175, 175, ${1 - mapOptions.country})`,
				dataLabels: {
					enabled: false,
					format: '{point.name}',
				},
				accessibility: {
					exposeAsGroupOnly: true,
				},
			},
			{
				// Specify points using lat/lon
				type: 'mappoint',
				name: 'Stations In Region',
				data: stationsInRegion,
				animation: false,
				marker: {
					symbol: 'circle',
				},
				stickyTracking: false,
				dataLabels: {
					enabled: false,
				},
				turboThreshold: 0,
			},
			{
				// Specify points using lat/lon
				type: 'mappoint',
				name: 'Other Stations',
				data: otherStations,
				animation: false,
				marker: {
					symbol: 'circle',
				},
				stickyTracking: false,
				dataLabels: {
					enabled: false,
				},
				turboThreshold: 0,
				opacity: 0.25,
			},
		],
	});

	if (aeroval.cfg.webdisp_opts.map_zoom == 'Europe') {
		// get id corresponding to country
		var countries = utils.getChartById('minimap')?.series[1].data;
		if (!countries) return;
		for (var country of countries) {
			if (country.name == aeroval.region) {
				const miniMap = utils.getChartById('minimap');
				const countryFocus = miniMap?.get((country as any).id) as Highcharts.Point;
				if (!miniMap || !countryFocus) return;
				countryFocus.zoomTo();
				miniMap.mapZoom(2.5);
			}
		}
	}
}

function getCenterRegion(region_name: string): number[] {
	if (aeroval.cfg.webdisp_opts.map_zoom == 'Europe') {
		return [10, 50];
	} else {
		const region = aeroval.regions[region_name];
		if (region.minLon && region.maxLon && region.minLat && region.maxLat) {
			var minLat = region.minLat as number;
			var maxLat = region.maxLat as number;
			var maxLon = region.maxLon as number;
			if (region.minLon < region.maxLon) {
				var minLon = region.minLon as number;
			} else {
				var minLon = region.minLon - 360;
			}
			return [(minLon + maxLon) / 2, (minLat + maxLat) / 2];
		} else if ('lats' in region && 'lons' in region) {
			var minLon = Math.min.apply(Math, region.lons) as number;
			var maxLon = Math.max.apply(Math, region.lons) as number;
			var minLat = Math.min.apply(Math, region.lats) as number;
			var maxLat = Math.max.apply(Math, region.lats) as number;
			return [(minLon + maxLon) / 2, (minLat + maxLat) / 2];
		} else {
			return [0, 0];
		}
	}
}

const getGraticule = () => {
	const data = [];

	// Meridians
	for (let x = -180; x <= 180; x += 15) {
		data.push({
			geometry: {
				type: 'LineString',
				coordinates:
					x % 90 === 0
						? [
							[x, -90],
							[x, 0],
							[x, 90],
						]
						: [
							[x, -80],
							[x, 80],
						],
			},
		});
	}

	// Latitudes
	for (let y = -90; y <= 90; y += 10) {
		const coordinates = [];
		for (let x = -180; x <= 180; x += 5) {
			coordinates.push([x, y]);
		}
		data.push({
			geometry: {
				type: 'LineString',
				coordinates,
			},
			lineWidth: y === 0 ? 2 : undefined,
		});
	}

	return data;
};

/*
const autoRotate = (chart: Highcharts.Chart) => {
	setInterval(() => {
		if ('mapView' in chart && 'projection' in (chart as any).mapView) {
			const rotation = (chart.mapView as any).projection.options.rotation;
			rotation[0] += 1;
			(chart.mapView as any).update({
				projection: {
					rotation,
				},
			});
		}
	}, 30);
};
*/

const autoRotate = (chart: Highcharts.Chart) => {
	let lastFrameTime = 0;

	const animate = (currentTime: number) => {
		const elapsedTime = currentTime - lastFrameTime;

		if (elapsedTime > 30) {
			// Limit update frequency
			lastFrameTime = currentTime;

			if ('mapView' in chart && 'projection' in (chart as any).mapView) {
				const rotation = (chart?.mapView as any)?.projection.options.rotation;
				rotation[0] += 1;

				// Update rotation
				(chart as any).mapView.update(
					{
						projection: {
							rotation,
						},
					},
					void 0,
					void 0,
					false
				);
			}
		}
		// Request next frame
		requestAnimationFrame(animate);
	};
	// Start animation loop
	requestAnimationFrame(animate);
};
