import * as Highcharts from 'highcharts';
import 'highcharts/modules/pattern-fill';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import 'highcharts/modules/offline-exporting';
import '../../highcharts-options';

import { getChartById, getKeyTime, getModelStyle, highchartsPalette, getUnits, getCoarsestFrequency } from '../../../utils';
import { update as interUpdate, callbackGlobStat as interCallbackGlobStat } from '../../../modules/intercomp';

import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global';
import { RegStatData, TrendsStats } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotRegionalStatisticsMultiModel(data: RegStatData, updateTS: boolean, updateScat: boolean, id = 'regStat'): void {
	//decompose statistics: support obs/mod_trends
	if (aeroval.statistic.dir.includes('/')) {
		var firstUnderscore = aeroval.statistic.dir.indexOf('_');
		var stat1 = `${aeroval.statistic.dir.split('/')[0]}_${aeroval.statistic.dir.slice(firstUnderscore + 1)}`;
		var stat2 = aeroval.statistic.dir.split('/')[1];
		var stats = [stat1, stat2];
	} else {
		var stats = [aeroval.statistic.dir];
	}

	//time selection
	const keyTime = getKeyTime(aeroval.time, aeroval.season);

	var series: any[] = [];
	var regionalStatistics = {} as any;

	const mods = Object.keys(data);
	for (let stat of stats) {
		for (let j: number = 0; j < mods.length; j++) {
			const model = mods[j];

			if (stat == 'obs_trend' && j > 0) continue;

			//here we overwrite the visibility
			var vis = true;
			if (typeof aeroval.seriesVisibility[mods[j]] != 'undefined') {
				vis = aeroval.seriesVisibility[mods[j]];
			}
			if (!aeroval.excludeModels.includes(mods[j])) {
				var modName = mods[j];
				var modVar = Object.keys(data[mods[j]]);
				var modData = data[mods[j]][modVar[0]];

				var seriesData = [];
				const highchartsColor = highchartsPalette(j);
				const modelStyle = getModelStyle(model);
				const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;

				if (stat == 'obs_trend') {
					var colorSeries = aeroval.settings.theme === "dark" ? chartsStyle['intercomp-ts'][`obs-primary`].colorDarkMode : chartsStyle['intercomp-ts'][`obs-primary`].color
					var coarsestFrequency = getCoarsestFrequency(aeroval.cfg.freqs)
					var seriesName = `Observation (${coarsestFrequency})`
				} else {
					colorSeries = colorModel
					seriesName = model
				}

				var i = 0;
				for (var region in aeroval.regions) {
					if (typeof modData?.[region]?.[keyTime] != 'undefined') {
						var label: string | null = null;
						if ((aeroval.cfg.webdisp_opts.country_flag || aeroval.cfg.webdisp_opts.country_code) && !aeroval.globalRegions.includes(region)) {
							var country = region;
							if (country === 'Macedonia') {
								country = 'North Macedonia';
							}
							if (country === 'Holy See (Vatican City State)') {
								country = 'Vatican';
							}

							if (aeroval?.world_flags?.[country]) {
								if (aeroval.cfg.webdisp_opts.country_flag) {
									label = aeroval.world_flags[country].Emoji;
								} else if (aeroval.cfg.webdisp_opts.country_code) {
									label = aeroval.world_flags[country].ISO;
								}
							}
						}

						var value: number | null = null;
						if (stat.includes('trend') && modData[region][keyTime][stat]) {
							if ((modData[region][keyTime][stat] as TrendsStats)['map_var']) {
								const mapVar = (modData[region][keyTime][stat] as TrendsStats)['map_var'];
								value = (modData[region][keyTime][stat] as TrendsStats)[mapVar];
							}
						} else if (stat === 'nrms') {
							value = (modData[region][keyTime]['rms'] as number) / (modData[region][keyTime]['refdata_mean'] as number);
						} else {
							value = modData[region][keyTime][stat] as number;
						}

						// some statistics are expressed in percentage
						if (value && aeroval.statistic.unit === '%') {
							value *= 100;
						}

						regionalStatistics[region] = {
							statistic: value,
							nTotal: modData[region]['num_coords_with_data'],
						};

						if (typeof regionalStatistics[region]['statistic'] != 'undefined') {
							seriesData.push({
								category: i,
								y: regionalStatistics[region]['statistic'],
								model: modName,
								label,
							});
						} else {
							seriesData.push(null);
						}
					} else {
						regionalStatistics[region] = {
							statistic: null,
							nTotal: null,
						};
					}
					i++;
				}

				series.push({
					name: seriesName,
					data: seriesData,
					color: colorSeries,
					showInLegend: false,
					visible: vis,
					dataLabels: {
						enabled: true,
						allwOverlap: true,
						style: { fontSize: 8, fontWeight: 'bolder' },
						formatter: function (): string | undefined {
							if (aeroval.cfg.webdisp_opts.country_flag || aeroval.cfg.webdisp_opts.country_code) {
								var models = Object.keys(aeroval.menu[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer]);
								var series = getChartById('regStat')?.series;

								if (!series) return;

								// visible models
								var visible_models = [];
								var max_abs = 0;
								var showModel = '';

								for (let i in models) {
									var model = models[i];
									var serie = series[i];
									if (model in aeroval.seriesVisibility && !aeroval.seriesVisibility[model]) {
									} else {
										visible_models.push(model);
										// loop though data and get value for right category
										if (typeof serie.points != 'undefined') {
											for (var point of serie.points) {
												if (point.y && point.category === this.x) {
													if (Math.abs(point.y) > max_abs) {
														var showModel = model;
														max_abs = Math.abs(point.y);
													}
												}
											}
										}
									}
								}
								if ((this as any).point.model === showModel) {
									return (this as any).point.label;
								}
							}
							return;
						},
						y: 0,
						x: 0,
					},
				});
				//of obs_trends, just plot values from the first model
				if (stat === 'obs_trends') {
					break;
				}
			}
		}
	}

	const unit = getUnits();
	const titleText = getTitle();

	Highcharts.chart(
		id,
		{
			chart: {
				renderTo: id,
				type: 'column',
				style: {
					cursor: 'pointer',
				}
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.frequency} data - all stations`,
			},
			xAxis: {
				categories: Object.keys(regionalStatistics),
				crosshair: true,
				labels: {
					overflow: 'justify',
					rotation: -45,
				},
				cursor: 'pointer',
			} as Highcharts.XAxisOptions,
			yAxis: [
				{
					title: {
						text: `${aeroval.statistic.name} ${unit}`,
					},
				},
				{
					gridLineWidth: 0,
					title: {
						text: 'Nb. of Stations (◆)',
					},
					opposite: true,
					visible: false,
				},
			],
			plotOptions: {
				column: {
					turboThreshold: 0,
					borderWidth: 0,
					shadow: false,
					cursor: 'pointer',
					point: {
						events: {
							mouseOver: function () {
								if (aeroval.settings.presentationMode !== 'true') {
									const thisPoint = (this as any).point;
									const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
									const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
									if (hoverLoaderRegionalChart instanceof HTMLElement) {
										hoverLoaderRegionalChart.classList.remove('hide');
										hoverLoaderRegionalChart.classList.add('show');
									}
									aeroval.wait = false;
									aeroval.timeOver = setTimeout(function () {
										if (aeroval.wait === false && mapRegion) {
											mapRegion.show();
											interUpdate('station', thisPoint.category.name);
											const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
											if (hoverLoaderRegionalChart instanceof HTMLElement) {
												hoverLoaderRegionalChart.classList.remove('show');
												hoverLoaderRegionalChart.classList.add('hide');
											}
										}
									}, 1000);
								}
							},
							mouseOut: function () {
								if (!aeroval.isMobile) {
									const thisPoint = (this as any).point;
									aeroval.wait = true;
									clearTimeout(aeroval.timeOver);
									const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
									if (hoverLoaderRegionalChart instanceof HTMLElement) {
										hoverLoaderRegionalChart.classList.remove('show');
										hoverLoaderRegionalChart.classList.add('hide');
									}
									const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
									if (mapRegion) {
										mapRegion.hide();
									}
								}
							},
							click: function () {
								const thisPoint = (this as any).point;
								aeroval.wait = false;
								clearTimeout(aeroval.timeOver);
								const mapRegion = getChartById('map')?.get(thisPoint.category.name) as Highcharts.Series;
								if (mapRegion) {
									mapRegion.show();
								}
								interUpdate('station', thisPoint.category.name);
								const hoverLoaderRegionalChart = document.querySelector('#hoverLoaderReg');
								if (hoverLoaderRegionalChart instanceof HTMLElement) {
									hoverLoaderRegionalChart.classList.remove('show');
									hoverLoaderRegionalChart.classList.add('hide');
								}
							},
						},
					},
				} as any,
			},
			tooltip: {
				shared: true,
				formatter: function () {
					const thisPoint = (this as any).point;
					if (!thisPoint) return;
					const mods = Object.keys(data);
					var h = `<b>${thisPoint.category}</b><br>`;
					for (let i: number = 0; i < mods.length; i++) {
						// set model style
						const modelStyle = getModelStyle(mods[i]);
						if (modelStyle?.color) {
							var color = modelStyle.color;
						} else {
							var color = highchartsPalette(i);
						}
						// check series visibility
						if (aeroval.seriesVisibility[mods[i]] != false) {
							h += "<span style='color: " + color + "'>● </span>";
							h += `<span>${mods[i]}: <b> ${(this as any).points[i].point.y?.toFixed(aeroval.statistic.decimals)} </b></span>`;
						}
						if (i < mods.length - 1) {
							h += '<br>';
						}
					}
					return h;
				},
			},
			series,
		},
		function (chart) {
			// when the chart is rendered
			var h = `<table class='table table-hover table-condensed slideTable'>`;
			h += '<thead>';
			h += '<tr>';
			h += "<th rowspan='1'>Region</th>";
			h += "<th rowspan='1'>Nb. of Stations</th>";
			h += `<th seriesDatapan='1'>${aeroval.statistic.name}</th>`;
			h += '</tr>';
			h += '</thead>';
			h += '<tbody>';
			for (let region in regionalStatistics) {
				h += '<tr>';
				h += `<td>${region}</td>`;
				h += `<td>${regionalStatistics[region]['nTotal']}</td>`;
				if (regionalStatistics[region]['statistic'] != null) {
					h += `<td>${regionalStatistics[region]['statistic'].toFixed(2)}</td>`;
				} else {
					h += `<td>${regionalStatistics[region]['statistic']}</td>`;
				}
				h += '</tr>';
			}
			h += '</tbody>';
			h += '</table>';

			const regStatTable = document.querySelector('#regStatTable');
			if (regStatTable instanceof HTMLElement) {
				regStatTable.innerHTML = h;
			}

			interCallbackGlobStat(updateTS, updateScat);
			chart.redraw();
		}
	);
}

export function toggleRegionalStatisticsColor(value: string, id = 'regStat'): void {
	if (value === 'theme') {
		const chart = getChartById(id);
		if (chart) {
			console.log(chart);
		}
	}
	if (value === 'value') {
		const chart = getChartById(id);
		if (chart) {
			console.log(chart);
		}
	}
	aeroval.settings.regionalStatisticsColor = value;
}

function getTitle(): string {
	var title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	title += ` - ${aeroval.time}`;
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}
