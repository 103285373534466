// Import Highcharts modules
import * as Highcharts from 'highcharts';
import '../../highcharts-options';

import { getKeyTime, clickOnLegendItem, getModelStyle, highchartsPalette } from '../../../utils';

import chartsStyle from '../../../../public/config/charts-style.json';

import { Aeroval } from '../../../types/global';
import { ForecastData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotForecast(data: ForecastData): void {
	const series = [] as any;
	const subData = data[aeroval.parameter.dir][aeroval.observation][aeroval.layer];

	// set keyTime
	const keyTime = getKeyTime(aeroval.time, aeroval.season);

	var i = 0;
	for (const model in subData) {
		var mod_var = Object.keys(subData[model])[0];

		// set model style
		const highchartsColor = highchartsPalette(i);
		const modelStyle = getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;
		const modelWeight = modelStyle?.weight ? modelStyle.weight : 1;

		var markerSizeModel = modelWeight * chartsStyle['frcst-ts']['mod'].markerSize * eval(aeroval.settings.lineWidthFactor);
		var lineWidthModel = modelWeight * chartsStyle['frcst-ts']['mod'].lineWidth * eval(aeroval.settings.lineWidthFactor);

		//here we overwrite the visibility
		var vis = true;
		if (typeof aeroval.seriesVisibility[model] != 'undefined') {
			vis = aeroval.seriesVisibility[model];
		}

		series.push({
			data: subData[model][mod_var][keyTime][aeroval.statistic.dir],
			color: colorModel,
			name: model,
			id: `mod-${model}`,
			visible: vis,
			lineWidth: lineWidthModel,
			marker: {
				radius: markerSizeModel,
			},
			weight: modelWeight
		});
		i++;
	}

	/*unit*/
	if (aeroval.statistic.unit != '1') {
		var axis_unit = `(${aeroval.statistic.unit})`;
	} else {
		axis_unit = '';
	}

	// set title
	if (aeroval.season == 'All') {
		var title = `${aeroval.parameter.name} - ${aeroval.region} - ${aeroval.time}`;
	} else {
		title = `${aeroval.parameter.name} - ${aeroval.region} -${aeroval.time} (${aeroval.season})`;
	}

	var subtitle = aeroval.observation;
	// if the frequency of the stats in the time series is different from the main freq, display the used frequency
	if ('stats_tseries_base_freq' in aeroval.cfg.statistics_opts && aeroval.cfg.statistics_opts.stats_tseries_base_freq != null) {
		if (aeroval.cfg.statistics_opts.stats_tseries_base_freq != aeroval.cfg.time_cfg.main_freq) {
			subtitle += ' (statistics computed from ' + aeroval.cfg.statistics_opts.stats_tseries_base_freq + ' values)';
		}
	}

	Highcharts.chart({
		chart: {
			renderTo: 'frcst-ts',
			type: aeroval.settings.lineStyle,
			zooming: { type: 'x' },
			height: 500,
			resetZoomButton: {
				position: {
					align: 'center',
				},
			},
		},
		title: {
			text: title,
		},
		subtitle: {
			text: subtitle,
		},
		exporting: {
			enabled: true,
		},
		tooltip: {
			shared: true,
			valueDecimals: 3,
			headerFormat: 'Hour ({point.point.x}) <br>',
		},
		yAxis: {
			title: {
				text: `Median of ${aeroval.statistic.name} ${axis_unit}`,
			},
			//softMin: 0,
		},
		xAxis: {
			//type: 'datetime',
			title: {
				text: 'Hour',
			},
			tickInterval: 6,
			plotLines: [
				{
					width: 3,
					value: 24,
					color: 'rgba(153, 153, 153, .25)',
				},
				{
					width: 3,
					value: 48,
					color: 'rgba(153, 153, 153, .25)',
				},
				{
					width: 3,
					value: 72,
					color: 'rgba(153, 153, 153, .25)',
				},
			],
			events: {
				setExtremes: function (e) {
					setZoomState(e);
				},
			},
		},
		legend: {
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			floating: false,
		},
		plotOptions: {
			series: {
				events: {
					legendItemClick: function () {
						clickOnLegendItem(this);
					},
				},
				states: {
					inactive: {
						enabled: false,
					},
				},
			},
		},
		series,
	});
}

function setZoomState(e: Highcharts.AxisSetExtremesEventObject) {
	if (e.max && e.min) {
		aeroval.zoom = {
			state: true,
			min: e.min,
			max: e.max,
		};
	} else {
		aeroval.zoom = {
			state: false,
			min: null as any,
			max: null as any,
		};
	}
}
