import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faVial, faUsers, faPaperPlane, faGear, faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faHubspot, faXTwitter } from '@fortawesome/free-brands-svg-icons';

// Add the imported icons to the library
library.add(faHubspot, faVial, faUsers, faPaperPlane, faInfoCircle, faGear, faXTwitter, faCircleQuestion);

const hubspot = icon({ prefix: 'fab', iconName: 'hubspot' });
const infoCircle = icon({ prefix: 'fas', iconName: 'info-circle' });
const vial = icon({ prefix: 'fas', iconName: 'vial' });
const xTwitter = icon({ prefix: 'fab', iconName: 'x-twitter' });
const users = icon({ prefix: 'fas', iconName: 'users' });
const paperPlane = icon({ prefix: 'fas', iconName: 'paper-plane' });
const gear = icon({ prefix: 'fas', iconName: 'gear' });
const circleQuestion = icon({ prefix: 'fas', iconName: 'circle-question' });

import { getCookie } from '../cookies';
import { offCanvas } from '../offcanvas';
import { toggleTheme } from '../theme';
import { animateIcons } from '../icons-animation';

import * as utils from '../../utils';
import canvasConfig from '../../../public/config/canvas.json';

import './header.css';

import aerovalFullLogo from '../../assets/images/logo/full_logo.svg';

export function renderHeader(): HTMLElement {
	const header = document.createElement('header');
	header.innerHTML = `
      <div id="external_header" style="position: absolute; top: 0; width: 100%; display: contents;"></div>
      <nav id="header-content" class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="./">
            <img id="header-logo" class="full-logo" src='${aerovalFullLogo}' style="height: 30px; width: 117.23px; padding-top: 0;" alt="logo_aeroval">
          </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" style="padding-top: 5px;">
          <ul class="navbar-nav me-auto">
            <li class="nav-item active">
              <a id="main-link" class="nav-link" href="#index">${hubspot.html}</i>Portal</a>
            </li>
            <li class="nav-item">
              <a id="overall-link" class="nav-link" href="#about">${infoCircle.html}</i>About</a>
            </li>
            <li class="nav-item" style="display: none;">
                <a id="lab-link" class="nav-link" href="#lab" title="Make your own experiment">${vial.html}Laboratory</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#news" title="News">${xTwitter.html}</i>News</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#contribute" title="Contribute to AeroVal">${users.html}</i>Contribute</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#contact" title="Contact Us">${paperPlane.html}</i>Contact</a>
            </li>
          </ul>
          <button id="toggle-settings" class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-settings" aria-controls="offcanvas-settings">
            ${gear.html}
          </button>
        </div>
      </div>
      </nav>
      
      <div id="offcanvas-content"></div>
    `;
	return header;
}

/*on document ready*/
document.addEventListener('DOMContentLoaded', function () {
	// apply theme as soon as possible
	const themeCookie = getCookie('theme');
	if (themeCookie && themeCookie != '') {
		toggleTheme(themeCookie);
	}
	var offcanvasOptions = [];
	for (var canvasOption in canvasConfig) {
		if (!(canvasConfig as any)[canvasOption].skipIndex) {
			offcanvasOptions.push(canvasOption)
		}
	}

	offCanvas(offcanvasOptions as [string | number]);
	showCurrentPage();
	enableScrollStatus();
	applyCAMSTheme();
	animateIcons();

	// menu events
	menuEvents();

	addFAQ();
});

function showCurrentPage() {
	// Hide all content elements
	const allContent = document.querySelectorAll('.main-content');
	allContent.forEach((content) => {
		(content as HTMLElement).style.display = 'none';
	});

	const hash: string = new URL(window.location.href).hash;

	if (hash) {
		// add active class to corresponding nav link
		const targetElement = document.querySelector(hash);
		const navLink = document.querySelector(`[href="${hash}"]`);

		if (navLink instanceof HTMLAnchorElement) {
			navLink.classList.add('active');
		}

		// show content
		if (targetElement instanceof HTMLElement) {
			targetElement.style.display = 'block';
		}
	} else {
		// add active class to corresponding nav link
		const targetElement = document.querySelector('#index');
		const navLink = document.querySelector(`[href="#index"]`);

		if (navLink instanceof HTMLAnchorElement) {
			navLink.classList.add('active');
		}

		// show content
		if (targetElement instanceof HTMLElement) {
			targetElement.style.display = 'block';
		}
	}
}

function menuEvents() {
	const navLinks = document.querySelectorAll('.nav-link');

	// Function to handle click on nav links
	function handleNavLinkClick(event: Event) {
		event.preventDefault();

		utils.removeActiveClass(); // Remove 'active' class from all nav links

		const targetId = (event.target as HTMLElement).getAttribute('href') as string;
		const targetElement = document.querySelector(targetId);

		// Hide all content elements
		const allContent = document.querySelectorAll('.main-content');
		allContent.forEach((content) => {
			(content as HTMLElement).style.display = 'none';
		});

		// Show only the target content
		if (targetElement instanceof HTMLElement) {
			targetElement.style.display = 'block';
			targetElement.classList.add('active');
		}

		// Add 'active' class to the selected link
		(event.target as HTMLAnchorElement).classList.add('active');

		// Update the URL without page reload
		const url = new URL(window.location.href);
		url.hash = targetId;
		history.pushState(null, '', url.toString());
	}

	// Add click event listener to nav links
	navLinks.forEach((navLink) => {
		navLink.addEventListener('click', handleNavLinkClick);
	});
}

function enableScrollStatus() {
	window.addEventListener('scroll', function () {
		const navbar = document.querySelector('nav.navbar');
		if (navbar instanceof HTMLElement) {
			window.scrollY > 30 ? navbar.classList.add('scrolled') : navbar.classList.remove('scrolled');
		}
	});
}

function applyCAMSTheme() {
	if (window.location.host.includes('cams') || window.location.host.includes('atmosphere.copernicus')) {
		const navbar = document.querySelector('nav.navbar');
		if (navbar instanceof HTMLElement) {
			navbar.style.position = 'sticky';
		}

		const externalHeader = document.getElementById('external_header');
		if (externalHeader instanceof HTMLElement) {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', '../includes/cams/header.html');
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						externalHeader.innerHTML = xhr.responseText;
					} else {
						console.error('Failed to load external header:', xhr.status);
					}
				}
			};
			xhr.send();
		}
	}
}

function addFAQ() {
	if (window.location.host.startsWith("aeroval-test.met.no")) {
		const url = 'https://docs.google.com/document/d/1B_YAE8bmmVyToAOSG6iJ0sXm3RSQl4antf85D7c0ZBM/pub';
		const menuListElement = document.querySelector('#navbarCollapse > ul')
		if (menuListElement) {
			var li = document.createElement("li");
			li.innerHTML = `
				<li class="nav-item">
					<a class="nav-link" href="${url}" title="FAQ">${circleQuestion.html}</i>FAQ</a>
				</li>`
			menuListElement.appendChild(li);
		}
	}
}