import * as Highcharts from 'highcharts';
import 'highcharts/modules/exporting';
import 'highcharts/modules/export-data';
import 'highcharts/modules/offline-exporting';
import '../../highcharts-options';

import * as utils from '../../../utils';
import { addGuidelines } from '../guidelines';

import chartsStyle from '../../../../public/config/charts-style.json'

import { Aeroval } from '../../../types/global';
import { TSData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotScatterPlotMultiModel(id: string): void {
	plotScatterPlot(id);
}

function getDataFromChart(): TSData {
	// for avoiding global data, could extract data from ts chart
	/*var targetChart = utils.getChartById(id);
	if (targetChart){
		console.log(targetChart);
	}*/
	return aeroval.data.ts as TSData;
}

function plotScatterPlot(id: string) {
	const data = getDataFromChart();
	const mainModelData = data[aeroval.model.dir];
	const tsChart = utils.getChartById('intercomp-ts');
	if (!tsChart || !mainModelData) return;

	// set time boundaries: from zoom state or from time series data
	const [xMin, xMax] = getXBoundariesFromTimeSeries();
	if (!xMin || !xMax) return;

	//filter models: should be also in the menu
	const models = utils.getModels();

	var series = [] as any[];
	var regressionLines = [] as any[];
	var allscat = [] as any[];

	// loop over models
	for (let j: number = 0; j < models.length; j++) {
		const model = models[j];
		var scatData = {} as any;
		var scatX = {} as any;
		var scatY = {} as any;

		//overwrite visibility
		const visible = typeof aeroval.seriesVisibility[model] != 'undefined' ? aeroval.seriesVisibility[model] : true;
		// subselect data
		const modelData = data[model];
		if (!modelData) continue;

		// by default, work with monthly data
		const highchartsColor = utils.highchartsPalette(j);
		const modelStyle = utils.getModelStyle(model);
		const colorModel = modelStyle?.color ? modelStyle.color : highchartsColor;

		var frequencies = utils.getOrderedFrequencies();
		// remove yearly if single year
		if (frequencies.includes('yearly') && (mainModelData['yearly_date']?.length ?? 0) <= 1) {
			frequencies = frequencies.filter((item) => item !== 'yearly');
		}
		frequencies = utils.filterFrequencies(frequencies, aeroval.frequency)
		const whichFrequencies: string[] = ['primary', 'secondary'];
		const minN = Math.min(frequencies.length, whichFrequencies.length);

		// Precompute months in season outside the main loop
		const monthsInSeason = utils.monthsInSeason(aeroval.season);

		// Main loop optimization
		for (let i = 0; i < minN; i++) {
			const freq = frequencies[i];
			const whichFreq = whichFrequencies[i] as 'primary' | 'secondary';

			// Set the variables only once based on `whichFreq`
			const opacity = (whichFreq === 'primary') ? 1 : 0.2;
			const showInLegend = (whichFreq === 'primary');
			const linkedTo = (whichFreq === 'primary') ? undefined : `${models[j]}-primary`;
			const zIndex = (whichFreq === 'primary') ? 2 : 1;

			// Data arrays
			const xObs = [];
			const xMod = [];
			const yObs = [];
			const yMod = [];

			const keyDate = `${freq}_date` as keyof TSData[keyof TSData];
			const keyObs = `${freq}_obs` as keyof TSData[keyof TSData];
			const keyMod = `${freq}_mod` as keyof TSData[keyof TSData];

			const dateArray = modelData[keyDate] as number[];
			const obsArray = modelData[keyObs] as number[];
			const modArray = modelData[keyMod] as number[];

			if (!dateArray || !obsArray || !modArray) continue;

			// Loop over dateArray only once
			for (let k = 0; k < dateArray.length; k++) {
				const dateValue = dateArray[k];

				// Check date range
				if (dateValue > xMin && dateValue <= xMax) {
					const obsValue = obsArray[k];
					const modValue = modArray[k];
					const currentDate = new Date(dateValue); // Create Date object once
					const iMonth = currentDate.getMonth() + 1;
					let iYear = currentDate.getFullYear();

					if (aeroval.season === 'DJF' && iMonth === 12) {
						iYear++;
					}

					if (aeroval.season === 'All' || monthsInSeason.includes(iMonth)) {
						// If a single year is selected, match the year
						if (!aeroval.time.includes('-') && iYear !== parseInt(aeroval.time)) {
							continue;
						}

						// Push data into arrays
						xObs.push(dateValue);
						xMod.push(dateValue);
						yObs.push(obsValue);
						yMod.push(modValue);
					}
				}
			}

			// Prepare scatter data
			scatData[whichFreq] = [] as [number, number][];
			scatX[whichFreq] = [] as number[];
			scatY[whichFreq] = [] as number[];

			// Optimize by assuming `xObs` and `xMod` are aligned
			for (let j = 0; j < xObs.length; j++) {
				if (yObs[j] != null && yMod[j] != null) {
					scatData[whichFreq].push([yObs[j], yMod[j]]);
					scatX[whichFreq].push(yObs[j]);
					scatY[whichFreq].push(yMod[j]);
					allscat.push([yObs[j], yMod[j]]);
				}
			}

			// Push to series in one operation
			series.push({
				type: 'scatter',
				name: models[j],
				id: `${models[j]}-${whichFreq}`,
				data: scatData[whichFreq],
				color: colorModel,
				opacity,
				zIndex,
				lineWidth: 0,
				marker: {
					enabled: true,
					radius: chartsStyle['intercomp-ts'][`mod-${whichFreq}`].markerSize,
				},
				showInLegend,
				linkedTo,
				visible,
				label: {
					enabled: false,
				},
			});
		}


		// calculates regression - from primary data
		var dataRegression: null[][] | number[][] = [];
		var dashStyle: string | undefined = undefined;

		if (scatX['primary'].length > 0) {
			const lr = utils.linearRegression(scatY['primary'], scatX['primary']);
			if (!lr || !lr.intercept || !lr.slope || !lr.r2) break;

			// set maximum value
			var FlatScat = [].concat.apply([], scatData['primary']);
			var max = Math.max.apply(Math, FlatScat);
			max = Math.round(max * 1.25 * 100) / 100;

			// regression line
			dataRegression = [
				[0, lr.intercept],
				[max, max * lr.slope + lr.intercept],
			];

			//dashStyle depending on R2
			dashStyle = lr.r2 < 0.2 ? 'Dash' : 'Solid';
		}

		regressionLines.push({
			name: 'lin. regression',
			id: null,
			type: 'line',
			data: dataRegression,
			dashStyle,
			marker: {
				enabled: false,
			},
			color: colorModel,
			tooltip: {
				valueDecimals: 3,
			},
			linkedTo: 'dummy-reg',
			showInLegend: false,
			opacity: 0.5,
			legend: {
				itemStyle: {
					color: 'red', //,style.obs.secondary.color
				},
			},
			label: {
				enabled: false,
			},
		});
	}

	// add dummy regression line for having a neutral color
	const vis = 'lin. regression' in aeroval.seriesVisibility ? aeroval.seriesVisibility['lin. regression'] : false;
	// color regression
	const colorRegression = aeroval.settings.theme === 'dark' ? chartsStyle['intercomp-ts']['obs-secondary'].colorDarkMode.replace('0.4', '0.5') : chartsStyle['intercomp-ts']['obs-secondary'].color.replace('0.4', '0.5');

	regressionLines.unshift({
		name: 'lin. regression',
		id: 'dummy-reg',
		type: 'line',
		data: [null, null],
		color: colorRegression,
		marker: {
			enabled: false,
		},
		showInLegend: true,
		visible: vis,
		label: {
			enabled: false,
		},
	});

	// add regression lines in the end
	series = series.concat(regressionLines);

	//set maximum value
	const [valMin, valMax] = getYBoundariesFromTimeSeries();
	if (typeof valMin === 'undefined' || typeof valMax === 'undefined') return;

	//get colors from the time series
	var tsColor = {} as any;
	for (let j in tsChart.series) {
		tsColor[tsChart.series[j].name] = tsChart.series[j].color;
	}

	/*unit*/
	const obsUnit = aeroval.observationUnit != '1' ? ` (${aeroval.observationUnit})` : '';
	const modUnit = aeroval.modelUnit != '1' ? ` (${aeroval.modelUnit})` : ``;

	/*axis*/
	if (aeroval.axisType == 'linear') {
		var yAxis = {
			title: {
				text: `Models ${modUnit}`,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: `${aeroval.observation}${obsUnit}`,
			},
			min: 0,
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	} else if (aeroval.axisType == 'logarithmic') {
		var yAxis = {
			title: {
				text: `Models${modUnit}`,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			gridLineWidth: 0,
			type: aeroval.axisType,
		} as any;
		var xAxis = {
			title: {
				text: aeroval.observation + obsUnit,
			},
			min: Math.max(0, utils.getLowerPowerOfTen(valMin) as number),
			max: valMax,
			endOnTick: true,
			type: aeroval.axisType,
		} as any;
	}

	// set title
	const titleText = getTitle();

	Highcharts.chart(
		{
			chart: {
				renderTo: id,
				zooming: {
					type: 'xy',
				},
			},
			boost: {
				enabled: false
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: `${aeroval.observation} - intercomparison`,
			},
			tooltip: {
				shared: true,
				pointFormat: '({point.x:.2f}, {point.y:.2f})',
			},
			yAxis,
			xAxis,
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							utils.clickOnLegendItem(this);
						},
					},
					states: {
						inactive: {
							enabled: false,
						},
					},
					turboThreshold: 0,
					label: {
						enabled: false
					}
				},
			},
			legend: {
				enabled: true,
				layout: 'horizontal',
				align: 'left',
				verticalAlign: 'top',
				floating: true,
				y: 50,
				x: 50,
			},
			series,
		},
		function (chart) {
			addGuidelines(chart);
		}
	);
}

function getTitle(): string {
	let title = aeroval.parameter.name;
	if (aeroval.layer != 'Surface' && aeroval.layer != 'Column') {
		title += ` (${aeroval.layer})`;
	}
	// add station and time
	title += ` - ${aeroval.station} - ${aeroval.time}`;
	// add season
	if (aeroval.season != 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}

function getXBoundariesFromTimeSeries(): [number | undefined, number | undefined] {
	const tsChart = utils.getChartById('intercomp-ts');
	var xMin: number | undefined = undefined;
	var xMax: number | undefined = undefined;

	if (tsChart) {
		xMin = (tsChart.axes[0] as any).min;
		if (aeroval.zoom?.min) {
			xMin = aeroval.zoom.min;
		}
		xMax = (tsChart.axes[0] as any).max;
		if (aeroval.zoom?.max) {
			xMax = aeroval.zoom.max;
		}
		if (aeroval.zoom && aeroval.zoom.state === false) {
			xMin = (tsChart.axes[0] as any).dataMin;
			xMax = (tsChart.axes[0] as any).dataMax;
		}
	}

	return [xMin, xMax];
}

function getYBoundariesFromTimeSeries(): [number | undefined, number | undefined] {
	const tsChart = utils.getChartById('intercomp-ts');
	var yMin: number | undefined = undefined;
	var yMax: number | undefined = undefined;

	if (tsChart) {
		if (tsChart.axes[1].userOptions.type === 'logarithmic') {
			yMin = Math.pow(10, (tsChart.axes[1] as any).min);
			yMax = Math.pow(10, (tsChart.axes[1] as any).max);
		} else {
			yMin = (tsChart.axes[1] as any).min;
			yMax = (tsChart.axes[1] as any).max;
		}
	}
	return [yMin, yMax];
}
