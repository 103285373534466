import * as Highcharts from 'highcharts';
import 'highcharts/modules/series-label';
import '../components/highcharts-options'

import * as L from 'leaflet';
import '../../node_modules/leaflet-ajax/dist/leaflet.ajax.js';

//import * as Cesium from 'cesium'; // to be restored when 3d view is available
declare var Cesium: any;

import '../../node_modules/leaflet/dist/leaflet.css';

import * as main from './main';
import * as utils from '../utils';
import { discreteColorscale } from '../components/colorscale';
import { getCookie, setCookie } from '../components/cookies';
import chartsStyle from '../../public/config/charts-style.json';

/*import interfaces and extend window variable*/
import { Aeroval } from '../types/global';
import { ScatData, TSData, ContourData } from '../types/data';

// Extend the Globals interface
interface ExtendedGlobals extends Aeroval {
	mapTypes: string[];
	mapType: any;
	mapView: string;
	bordersColor: 'light' | 'dark';
	bordersColors: ExtendedGlobals['bordersColor'][];
	layersOpacity: number;
	stationsRadius: number;
	freshStart: boolean;
	spinSpeeds: number[];
	spinSpeed: number;
	prevSpeed: number;
	obs: string;
	recObs: string;
	date: number;
	previousDate: number;
	update: string;
	/*some data*/
	map1: any;
	region1: any;
	timeArray: number[];
	stations1: any;
	map2: any;
	region2: any;
	stations2: any;
	/**/
	viewer: any;
	scene: any;
	/**/
	geojsonLayer: any;
	legend: any;
	coos: any;
	loading: boolean;
	pixels3D: any;
	/*dynamic keys*/
	[key: string]: any;
}
declare var aeroval: ExtendedGlobals;


utils.initAeroval();
utils.initAPI();

/*set default valuers*/
aeroval.region = 'WORLD';
aeroval.mapView = '2D';
aeroval.mapTypes = ['contour', 'overlay'];
aeroval.mapType = aeroval.mapTypes[0];
aeroval.colorMaps = ['coolwarm', 'Blues', 'PiYG_r', 'gray_r']
aeroval.colorMapTypes = ['linear', 'logarithmic']
aeroval.colorMapType = aeroval.colorMapTypes[0]
aeroval.bordersColor = 'dark';
aeroval.bordersColors = ['light', 'dark'];
aeroval.layersOpacity = 0.8;
aeroval.menuButtons = ['parameter', 'region', 'model1', 'model2'];
aeroval.stationsRadius = 4;
aeroval.freshStart = true;
aeroval.spinSpeeds = [0, 1, 2];
aeroval.spinSpeed = aeroval.spinSpeeds[0];
aeroval.autoplays = ['true', 'false']
aeroval.autoplay = aeroval.autoplays[1]
aeroval.isTSRegional = true

for (var cookie of ['autoplay']) {
	if (getCookie(cookie)) {
		aeroval[cookie] = getCookie(cookie);
	}
	if (utils.getQueryString(cookie)) {
		const cookieValue = utils.getQueryString(cookie) as string;
		if (cookieValue === 'true' || cookieValue === 'false') {
			aeroval[cookie] = eval(cookieValue)
		}
	}
}

if (utils.getQueryString('autoplay-interval')) {
	var autoPlayInterval = utils.getQueryString('autoplay-interval')
	aeroval.autoPlayInterval = autoPlayInterval ? eval(autoPlayInterval) : 1000;
	aeroval.autoplay = true;
} else {
	aeroval.autoPlayInterval = 1000;
}

if (utils.getQueryString('mapType')) {
	const mapType = utils.getQueryString('mapType')
	aeroval.mapType = mapType && aeroval.mapTypes.includes(mapType) ? mapType : aeroval.mapTypes[0]
}

if (utils.getQueryString('colorMap')) {
	const colorMap = utils.getQueryString('colorMap')
	aeroval.colorMap = colorMap && aeroval.colorMaps.includes(colorMap) ? colorMap : aeroval.colorMaps[0]
}

if (utils.getQueryString('colorMapType')) {
	const colorMapType = utils.getQueryString('colorMapType')
	aeroval.colorMapType = colorMapType && aeroval.colorMapTypes.includes(colorMapType) ? colorMapType : aeroval.colorMapTypes[0]
}

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/maps')) {
		init();
	}
});

function init() {
	console.log('initialize maps page');

	const mapMenuDropdown = document.querySelector('#mapMenu.dropdown-menu');
	if (mapMenuDropdown) {
		mapMenuDropdown.addEventListener('click', (e) => {
			e.stopPropagation();
		});
	}

	//check if any queries
	main.getQueryStrings();

	// update header links with qs project
	main.updateURLs('project', aeroval.project);

	// get experiments
	main.getExperiments(true, false);

	const mainElement = document.getElementById('main');
	if (mainElement) {
		mainElement.addEventListener('click', (event) => {
			event.preventDefault();
		});
	}

	// Time slider
	const mSlider = document.getElementById('m_slider') as HTMLInputElement;
	if (mSlider) {
		mSlider.addEventListener('input', (event) => {
			const target = event.target as HTMLInputElement;
			aeroval.previousDate = aeroval.date;
			aeroval.date = aeroval.timeArray[parseInt(target.value)];
			update('date', aeroval.date);
		});
	}

	// SpinGlobe button
	const globeRotationButton = document.getElementById('globeRotationButton');
	if (globeRotationButton) {
		globeRotationButton.addEventListener('click', () => {
			// Toggle aeroval.play state
			aeroval.prevSpeed = aeroval.spinSpeed;
			const iSpeed = aeroval.spinSpeeds.indexOf(aeroval.spinSpeed);
			aeroval.spinSpeed = iSpeed < aeroval.spinSpeeds.length - 1 ? aeroval.spinSpeeds[iSpeed + 1] : aeroval.spinSpeeds[0];

			// Change icon
			const faPlayIcon = document.createElement('i');
			faPlayIcon.id = 'faPlay';
			if (iSpeed === 0) {
				faPlayIcon.className = 'fas fa-play';
			} else if (iSpeed === 1) {
				faPlayIcon.className = 'fas fa-forward';
			} else if (iSpeed === 2) {
				faPlayIcon.className = 'fas fa-pause';
			}
			globeRotationButton.innerHTML = '';
			globeRotationButton.appendChild(faPlayIcon);
			spinGlobe();
		});
	}
	updateAutoplay()
}

function removeMap(): void {
	//only the first model is shown in 3d mode
	const myMap = aeroval.map1;
	if (aeroval.mapView == '3D') {
		myMap.remove();
	}
}

function colorCountry(color: 'light' | 'dark' = aeroval.bordersColor !== undefined ? aeroval.bordersColor : 'dark') {
	const colors = {
		dark: '#404040',
		light: '#ffffff',
	};
	return colors[color];
}

function createMap(div: 'mod1' | 'mod2') {
	var elements2D = document.querySelectorAll('._2D');
	var elements3D = document.querySelectorAll('._3D');

	elements2D.forEach((element) => {
		(element as HTMLElement).style.display = 'none';
	});

	elements3D.forEach((element) => {
		(element as HTMLElement).style.display = 'initial';
	});

	// delete previous map if exist already
	const myOldMap = div === 'mod1' ? aeroval.map1 : aeroval.map2;
	if (myOldMap) {
		removeMap();
	}

	const mapDiv = `${div}-${aeroval.mapView}` as 'mod1-2D' | 'mod2-2D';
	const divElement = document.getElementById(mapDiv);
	if (divElement) {
		divElement.innerHTML = '';
	}

	// set up initial view
	const center: L.LatLngTuple = aeroval.cfg.webdisp_opts.map_zoom == 'Europe' ? [55, 10] : [30, 0];
	var zoom = aeroval.cfg.webdisp_opts.map_zoom == 'Europe' ? 4 : 2;
	if (aeroval.isMobile) {
		zoom -= 1;
	}

	elements2D.forEach((element) => {
		(element as HTMLElement).style.display = 'initial';
	});

	elements3D.forEach((element) => {
		(element as HTMLElement).style.display = 'none';
	});

	const myMap = L.map(mapDiv, {
		center,
		zoom,
		minZoom: 1,
		markerZoomAnimation: false,
		worldCopyJump: true,
		crs: L.CRS.EPSG3857,
		maxBounds: [
			[-90, -180.0],
			[90, 180.0],
		],
		zoomControl: false,
		attributionControl: false,
		preferCanvas: true
	});

	//set different panes to manage the zIndex
	myMap.createPane('background');
	myMap.createPane('data');
	myMap.createPane('grid');
	myMap.createPane('countries');
	myMap.createPane('region');

	(myMap.getPane('background') as HTMLElement).style.zIndex = '1';
	(myMap.getPane('data') as HTMLElement).style.zIndex = '2';
	(myMap.getPane('grid') as HTMLElement).style.zIndex = '3';
	(myMap.getPane('countries') as HTMLElement).style.zIndex = '4';
	(myMap.getPane('region') as HTMLElement).style.zIndex = '5';

	L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		noWrap: true,
		pane: 'background',
	}).addTo(myMap);

	// add countries border
	const filename = '../../geography/admin_0_countries/ne_110m_admin_0_countries.geojson';
	const geojsonLayer = new (L.GeoJSON as any).AJAX(filename, {
		interactive: false,
		weight: 1,
		fillOpacity: 0,
		color: colorCountry(aeroval.bordersColor),
		opacity: 0.5,
		pane: 'countries',
	});
	aeroval[`${mapDiv}-countries`] = geojsonLayer.addTo(myMap);
	aeroval.geojsonLayer = geojsonLayer;

	//add click event
	myMap.on('click', onMapClick);

	/*myMap.on('browser-print-start', function (event: any) {
		(L as any).legendControl({ position: 'bottomright' }).addTo(event.printMap);
	});*/

	if (mapDiv == 'mod1-2D') {
		aeroval.map1 = myMap;
	} else if (mapDiv == 'mod2-2D') {
		aeroval.map2 = myMap;
		// move map
		aeroval.map1.on('zoomlevelschange move', function () {
			const center = aeroval.map1.getCenter();
			const zoom = aeroval.map1.getZoom();
			myMap.setView(center, zoom);
		});
	}

	//responsive
	myMap.invalidateSize();


	//click on map
	function onMapClick(event: any) {
		// remove station data from time series
		const chartTS = utils.getChartById('map-ts');
		if (chartTS && !aeroval.isTSRegional) {
			const data = aeroval.data.ts
			if (!data) return;
			plotRegionalTS(data);
		}

		const lat = parseFloat(event.latlng.toString().split(',')[0].split('(')[1]);
		const lon = parseFloat(event.latlng.toString().split(',')[1].split(')')[0]);
		const h = `coords: (${lat.toFixed(2)}, ${lon.toFixed(2)})`;
		if (mapDiv === 'mod1-2D') {
			const pixInfo1 = document.getElementById('pixInfo1');
			if (pixInfo1 instanceof HTMLElement) {
				pixInfo1.innerHTML = h;
			}
		} else {
			const pixInfo2 = document.getElementById('pixInfo1');
			if (pixInfo2 instanceof HTMLElement) {
				pixInfo2.innerHTML = h;
			}
		}
	}
}

function spinGlobe() {
	const spinRate = -0.05;
	var previousTime = Date.now();
	var speed = aeroval.spinSpeed;
	if (speed == 0) {
		if (typeof aeroval.prevSpeed != 'undefined') {
			// Getting sum of previous speeds
			const sum = aeroval.spinSpeeds.reduce(function (a, b) {
				return a + b;
			}, 0);
			speed = -sum;
		} else {
			speed = 0;
		}
	}
	aeroval.scene.postRender.addEventListener(function () {
		const currentTime = Date.now();
		const delta = (currentTime - previousTime) / 1000;
		previousTime = currentTime;
		aeroval.scene.camera.rotate(Cesium.Cartesian3.UNIT_Z, -spinRate * delta * speed);
	});
}

function cleanMap(div: 'mod1' | 'mod2', keepObs: boolean = false) {
	const myMap = div === 'mod1' ? aeroval.map1 : aeroval.map2;
	if (!myMap) return;

	//delete aeroval.map1._panes.overlayPane
	myMap.eachLayer(function (layer: any) {
		if (layer.options.pane.includes(div) || (layer.options.pane.startsWith('obs-') && keepObs !== true)) {
			myMap.removeLayer(layer);
			delete myMap._panes[layer.options.pane];
			delete myMap._paneRenderers[layer.options.pane];
		}
	});
}

function getMapFrequency(): Aeroval["frequency"] | undefined {
	var mapFrequency = aeroval.cfg.modelmaps_opts.maps_freq;
	if ((mapFrequency == 'coarsest' || mapFrequency == undefined) && aeroval.cfg.time_cfg.freqs) {
		mapFrequency = utils.getCoarsestFrequency(aeroval.cfg.time_cfg.freqs)
	}
	return mapFrequency
}

function timeSlider(data: Aeroval["TSData"]) {
	// if contour, take time from keys else, take time from ts data considering maps frequency
	const mapFrequency = getMapFrequency();
	if (`${mapFrequency}_date` in data[aeroval.model.dir]) {
		aeroval.timeArray = data[aeroval.model.dir][`${mapFrequency as Aeroval["frequency"]}_date`] as number[]
	} else {
		console.log('missing information to continue')
	}


	aeroval.date = aeroval.date ? aeroval.date : aeroval.timeArray?.[0]
	// check that timeArray includes date
	if (!aeroval.timeArray?.includes(aeroval.date)) {
		aeroval.date = aeroval.timeArray?.[0]
	}

	const iMin = 0;
	const iMax = aeroval.timeArray.length - 1;
	const slider = document.querySelector('#m_slider') as HTMLInputElement;
	if (slider) {
		slider.min = String(iMin);
		slider.max = String(iMax);
		slider.step = String(1);
	}

}

async function plotMap(div: 'mod1' | 'mod2') {
	showWaiter()
	smartPlotLayers(div);
	if (aeroval.mapType === 'overlay') {
		addObsToMenu()
	}
}

function addObsToMenu() {
	var modelList = document.getElementById("list-model2")
	var obsList = '<li><a class="dropdown-item" id="model2:None" href="#" title="None">None</a></li>'
	if (modelList) {
		if (aeroval.cfg.modelmaps_opts.right_menu) {
			for (let obs of aeroval.cfg.modelmaps_opts?.right_menu) {
				obsList += `<li><a class="dropdown-item" id="model2:${obs}" href="#" title="${obs}">${obs}</a></li>`
			}
		}

		modelList.innerHTML = obsList

		//event on bootstrap menus
		const elements = document.querySelectorAll(`#list-model2 a`);
		elements.forEach((element) => {
			element.addEventListener('click', function () {
				main.selectMenu(element.id);
			});
		});
	}
}

function makeLegendFromRanges() {
	// make legend in right format
	const colorScale = aeroval.ranges[aeroval.parameter.dir]
	const levels = colorScale.scale;
	const colors = [];
	const scale = levels
	for (let i = 0; i < levels.length; i++) {
		colors.push(discreteColorscale(levels[i], scale, aeroval.colorMapType))
	}

	return {
		colors: colors,
		levels: levels,
		units: colorScale.unit
	}
}

function plotStations(data: ScatData) {

	const time = aeroval.timeArray
	if (!time) return;

	// make sure we have a legend
	if (!aeroval.legend || aeroval.mapType === 'overlay') {
		aeroval.legend = makeLegendFromRanges()
	}

	for (var model of ['model1', 'model2']) {

		if (aeroval[model]?.dir === 'None') continue;

		const div = model == 'model1' ? 'mod1' : 'mod2';
		const myMap = div === 'mod1' ? aeroval.map1 : aeroval.map2;
		const color = aeroval.bordersColor === 'light' ? colorCountry('dark') : colorCountry('light');
		const keyTime = aeroval.cfg.time_cfg.periods[0] + '-all';
		const opacity = 1;
		const fillOpacity = 1;

		const group = [] as any;

		for (const t of time) {
			//create pane and set zIndex
			const pane = `obs-${div}-${t}`;
			if (myMap.getPane(pane)) {
				delete myMap._paneRenderers[pane];
			} else {
				myMap.createPane(pane);
				myMap.getPane(pane).style.zIndex = 100;
			}

			const markers = [];
			for (let key in data) {
				//get index of scat corresponding to time
				const iTime = data[key][keyTime]['date'].indexOf(t);
				//if match time, opacity else 0
				if (iTime != -1) {
					const value = data[key][keyTime]['obs'][iTime];
					const fillColor = pixelColor(value);
					const lat = data[key].latitude;
					const lon = data[key].longitude;

					const options = {
						pane: pane,
						name: key,
						radius: aeroval.stationsRadius,
						color,
						fillColor,
						stroke: true,
						weight: 1,
						fillOpacity,
						opacity,
					};
					if (value != null) {
						markers.push(
							L.circleMarker([lat, lon], options).on('click', function (event: any) {
								getStationTS(event);
							})
						);
					}
				}
			}

			if (!myMap.layers) {
				myMap.layers = {}
			}
			myMap.layers[pane] = L.layerGroup(markers).addTo(myMap);
			group.push(myMap.layers[pane]);

			// hide other time
			if (t !== aeroval.date) {
				myMap.getPane(pane).classList.add('hide');
			}
		}

		if (div == 'mod1') {
			aeroval.stations1 = group;
		} else if (div == 'mod2') {
			aeroval.stations2 = group;
		}
	}

	document.body.style.cursor = 'default';
}

function getStationTS(marker: any) {
	aeroval.station = marker.sourceTarget.options.name;

	//if satellite, transform the name
	const station_name = typeof aeroval.station === 'object' ? `( ${aeroval.station[0]}, ${aeroval.station[1]})` : aeroval.station;
	const url = `${window.API_ROOT}/ts/${aeroval.project}/${aeroval.experiment.name}/${station_name}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			plotStationTS(data);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function plotStationTS(data: TSData) {
	// remove first station series
	const chartTS = utils.getChartById('map-ts');
	if (chartTS) {
		chartTS.get('station')?.remove();
	}

	// take time series of first model: same obs in both models
	const seriesObsData: [number, number][] = [];
	const seriesMod1Data: [number, number | undefined][] = [];
	const seriesMod2Data: [number, number | undefined][] = [];

	const mapFrequency = getMapFrequency() as Aeroval['frequency'];

	const dataTime1 = data[aeroval.model1.dir][`${mapFrequency}_date`];
	const dataObs = data[aeroval.model1.dir][`${mapFrequency}_obs`];
	const dataMod1 = data[aeroval.model1.dir][`${mapFrequency}_mod`];

	if (!dataTime1 || !dataObs || !dataMod1) return;
	for (var i = 0; i < dataTime1.length; i++) {
		const time = dataTime1[i] as number;
		const obsValue = dataObs[i] as number;
		const mod1Value = dataMod1[i] as number;

		seriesObsData.push([time, obsValue]);
		seriesMod1Data.push([time, mod1Value]);
	}

	if (aeroval.model2.dir !== 'None') {
		const dataTime2 = data[aeroval.model2.dir][`${mapFrequency}_date`];
		const dataMod2 = data[aeroval.model2.dir][`${mapFrequency}_mod`];
		if (dataMod2 && dataTime2) {
			for (var i = 0; i < dataTime2.length; i++) {
				const time = dataTime2[i] as number;
				const mod2Value = dataMod2 ? dataMod2[i] as number : undefined;

				seriesMod2Data.push([time, mod2Value]);
			}
		}
	}


	const tsChart = utils.getChartById('map-ts');
	if (!tsChart) return;

	aeroval.isTSRegional = false

	// update mod series
	tsChart.series[0].update({
		name: `${aeroval.model1.name}[${aeroval.observation}]@${data[aeroval.model1.dir]?.station_name}`,
		data: seriesMod1Data,
	} as any, false)


	tsChart.series[1].update({
		name: `${aeroval.model2.name}[${aeroval.observation}]@${data[aeroval.model2.dir]?.station_name}`,
		data: seriesMod2Data,
	} as any, false)

	// add obs series
	tsChart.series[2].update({
		name: `${aeroval.observation}@${data[aeroval.model1.dir]?.station_name}`,
		data: seriesObsData,
		marker: {
			symbol: 'square',
		},
	} as any, true);
}

function setPaneVisibility(pane: string, visible: boolean) {
	const myMap = pane.startsWith('mod1') ? aeroval.map1 : aeroval.map2;
	const paneElement = myMap.getPane(pane);
	if (paneElement) {
		paneElement.style.display = visible ? 'inherit' : 'none';
		paneElement.style.opacity = visible ? 1 : 0;
	}
};

function updateModPane(div: 'mod1' | 'mod2') {
	const myMap = div == 'mod1' ? aeroval.map1 : aeroval.map2;
	aeroval.timeArray.forEach((t) => {
		const pane = `${div}-${t}`;
		const visible = t == aeroval.date;
		const paneElement = myMap.getPane(pane);
		// change visibility if visibility has changed
		if (paneElement) {
			paneElement.style.opacity = aeroval.layersOpacity;
			const paneElementVisibility = paneElement.style.display == 'inherit' ? true : false;
			if (paneElementVisibility != visible) {
				setPaneVisibility(pane, visible);
			}
		}
	});
}

function getSmartTimeArray(nCache: number): number[] {
	const iSmart = aeroval.timeArray.indexOf(aeroval.date)
	const iMin = Math.max(iSmart - nCache, 0)
	const iMax = Math.min(iSmart + nCache, aeroval.timeArray.length - 1)
	const smartIndexArray = utils.range(iMin, iMax + 1, 1)
	const smartTimeArray = []
	for (let smartIndex of smartIndexArray) {
		smartTimeArray.push(aeroval.timeArray[smartIndex]);
	}
	return smartTimeArray;
}

function plotOverlay(url: string, map: L.Map, pane: string) {
	const altText = url;

	// Ensure model boundaries are valid
	if (!aeroval.cfg.modelmaps_opts.boundaries) {
		console.error("Missing model boundaries configuration.");
		return;
	}

	const { north, south, east, west } = aeroval.cfg.modelmaps_opts.boundaries;
	const southWest = L.latLng(south, west);
	const northEast = L.latLng(north, east);
	const latLngBounds = L.latLngBounds(southWest, northEast);

	var img = new Image();
	img.crossOrigin = 'Anonymous'; // Set cross-origin before setting the src
	img.src = url; // Set the image source

	img.onload = function () {
		// Initialize canvas
		var canvas = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext('2d');
		if (!ctx) {
			console.error("Failed to get canvas context.");
			return;
		}

		// Draw the image onto the canvas
		ctx.drawImage(img, 0, 0);

		// Get the image data
		const imageData = ctx.getImageData(0, 0, img.width, img.height);
		const data = imageData.data;

		// Apply the custom colormap
		for (var i = 0; i < data.length; i += 4) {
			var intensity = data[i]; // Assuming grayscale image (R channel)
			var color = colorFromSRGB(intensity); // Get color from colormap function
			if (!color) break
			data[i] = color.r;     // R
			data[i + 1] = color.g; // G
			data[i + 2] = color.b; // B
			// data[i + 3] remains unchanged (alpha channel)
		}

		// Put the modified image data back on the canvas
		ctx.putImageData(imageData, 0, 0);

		// Convert canvas to data URL and create ImageOverlay
		var recoloredUrl = canvas.toDataURL();

		L.imageOverlay(recoloredUrl, latLngBounds, {
			opacity: aeroval.layersOpacity ?? 1.0, // Default to full opacity if not set
			alt: altText,
			interactive: false,
			pane: pane
		}).addTo(map);
	};

	img.onerror = function () {
		console.error("Failed to load image:", url);
	};
}

function colorFromSRGB(rgb: number) {
	const value = Math.min(...aeroval.legend.levels) + (rgb / 255) * (Math.max(...aeroval.legend.levels) - Math.min(...aeroval.legend.levels))
	const hexColor = discreteColorscale(value, aeroval.legend.levels, aeroval.colorMapType);
	return utils.hexToRgb(hexColor)
}

function plotFeatures(data: ContourData, map: any, pane: string) {
	const layerGroup = L.featureGroup().addTo(map);
	const commonStyle = {
		interactive: false,
		weight: 1,
		opacity: aeroval.layersOpacity,
		fillOpacity: aeroval.layersOpacity,
		renderer: L.canvas({ pane: pane })
	};
	data?.features.forEach((feature: any) => {
		const valueRange = feature.properties.title
		const value = valueRangeToValue(valueRange)
		const fillColor = pixelColor(value);
		const featureStyle = { ...commonStyle, color: fillColor };
		const geoJsonLayer = L.geoJSON(feature as any, { style: () => featureStyle });
		layerGroup.addLayer(geoJsonLayer);
	});
}


function valueRangeToValue(valueRange: string): number {
	if (valueRange.startsWith('>')) {
		return eval(valueRange.split('>')[1])
	} else {
		return eval(valueRange.split('-')[0])
	}
}

async function getContour(div: 'mod1' | 'mod2', time: number): Promise<ContourData | undefined> {
	const model = div === 'mod1' ? aeroval.model1.dir : aeroval.model2.dir;
	//const modVar = div === 'mod1' ? aeroval.model1.var : aeroval.model2.var;
	const obsVar = aeroval.parameter.dir
	const url = `${window.API_ROOT}/contour/${aeroval.project}/${aeroval.experiment.name}/${obsVar}/${model}?time=${time}${window.DATA_PATH && `&data_path=${window.DATA_PATH}`}`;
	try {
		const response = await fetch(url, {
			cache: 'no-cache'
		});
		return response.ok ? response.json() : undefined;
	} catch (error) {
		console.warn(`contour file: ${error}`);
		return undefined;
	}
}

async function getOverlay(div: 'mod1' | 'mod2', time: number): Promise<string | undefined> {
	const model = div === 'mod1' ? aeroval.model1.dir : aeroval.model2.dir;
	const modVar = div === 'mod1' ? aeroval.model1.var : aeroval.model2.var;

	const url = `${window.API_ROOT}/maps_overlays/${aeroval.project}/${aeroval.experiment.name}/${model}/${modVar}/${time}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	try {
		const response = await fetch(url, {
			cache: 'no-cache'
		});
		return response.ok ? url : undefined;
	} catch (error) {
		console.warn(`overlay file: ${error}`);
		return undefined;
	}
}

async function smartPlotLayers(div: 'mod1' | 'mod2', nCache: number = 2) {

	const myMap = div === 'mod1' ? aeroval.map1 : aeroval.map2;

	//initialize time to first date available
	if (!aeroval.date || !aeroval.timeArray.includes(aeroval.date)) {
		aeroval.date = aeroval.timeArray?.[0]
		document.querySelector('#m_slider')?.setAttribute('value', String(0));
	}

	if (!aeroval.isLegendPlotted) {
		// make legend in right format
		aeroval.legend = makeLegendFromRanges()
		plotLegend(aeroval.legend as any);
	}

	// always hide previous date
	const previousPane = `${div}-${aeroval.previousDate}`;
	setPaneVisibility(previousPane, false);
	hideWaiter()

	var isSomethingPlotted = false
	const smartTimeArray = getSmartTimeArray(nCache)

	for (const time of smartTimeArray) {
		const pane = `${div}-${time}`;
		var deletePane = true

		if (!myMap.getPane(pane)) {
			// create pane and set zIndex
			myMap.createPane(pane);
			myMap.getPane(pane).style.zIndex = 2;

			if (aeroval.mapType === 'contour') {
				// try getting contour data for relevant time
				const dataContour = await getContour(div, time)
				if (dataContour) {
					isSomethingPlotted = true
					deletePane = false
					plotFeatures(dataContour, myMap, pane)
					setPaneVisibility(pane, time === aeroval.date);
				}
			} else if (aeroval.mapType === 'overlay') {
				const url = await getOverlay(div, time)
				if (url) {

					isSomethingPlotted = true
					deletePane = false
					plotOverlay(url, myMap, pane)
					setPaneVisibility(pane, time === aeroval.date);
				}
			}
		} else {
			isSomethingPlotted = true
			deletePane = false
			setPaneVisibility(pane, time === aeroval.date);
		}
		if (deletePane) {
			delete myMap._panes[pane];
			delete myMap._paneRenderers[pane];
		}
	}

	isSomethingPlotted ? hideMapMessage(div) : showMapMessage(div, `No ${aeroval.mapType.toLowerCase()} data available`);
}

function pixelColor(value: number): string | undefined {
	//returns color of pixel according to colorscale of polygons
	if (aeroval.legend) {
		const nearest = indexOfClosest(aeroval.legend.levels, value);
		return aeroval.legend.colors[nearest];
	} else {
		return undefined;
	}
}

function indexOfClosest(nums: number[], target: number): number {
	let closest = Number.MAX_SAFE_INTEGER;
	let index = -1;

	for (let i = 0; i < nums.length; i++) {
		const dist = Math.abs(target - nums[i]);
		if (dist < closest) {
			closest = dist;
			index = i;
			// Early exit if the exact match is found
			if (dist === 0) break;
		}
	}
	return index;
}

function updateStations() {

	// set colors
	const color = aeroval.bordersColor == 'light' ? colorCountry('dark') : colorCountry('light');

	for (var model of ['model1', 'model2']) {
		if (aeroval[model]?.dir === 'None') continue;

		const myMap = model === 'model1' ? aeroval.map1 : aeroval.map2;
		const div = model === 'model1' ? 'mod1' : 'mod2';

		// hide previous date
		const previousPane = `obs-${div}-${aeroval.previousDate}`;
		if (myMap.getPane(previousPane)) {
			myMap.getPane(previousPane).classList.add('hide');
			myMap.getPane(previousPane).classList.remove('show');
		}

		// show current date
		const currentPane = `obs-${div}-${aeroval.date}`;
		if (myMap.getPane(currentPane)) {
			myMap.getPane(currentPane).classList.add('show');
			myMap.getPane(currentPane).classList.remove('hide');

			// update stationRadius
			if (myMap.layers[currentPane]) {
				myMap.layers[currentPane].eachLayer(function (layer: any) {
					layer.setStyle({
						radius: aeroval.stationsRadius,
						opacity: aeroval.layersOpacity,
						fillOpacity: aeroval.layersOpacity,
						color,
					});
				});
			}
		}
	}
}

function formatLegendColors(legend: any): any {
	const maxColor = legend.colors[legend.colors.length - 1];
	legend.colors.push(maxColor);
	legend.colors = legend.colors;
	return legend
}

function plotLegend(data: ContourData["legend"]): void {
	const legend = data;
	aeroval.legend = legend;

	var h = `<p style="font-size: 12px; font-weight: bold">${aeroval.parameter.name}</p>`;
	if (aeroval.legend.units != '1') {
		h += `<p style="font-size: 10px; font-style: italic">${aeroval.legend.units}</p>`
	}
	h += '<table>';

	// append colors with white
	aeroval.legend = formatLegendColors(legend)

	for (let i = legend.levels.length - 1; i >= 0; i--) {
		h += '<tr>';
		h += `<td style="height: 10px; font-size:9px; text-align: center; padding-right: 5px">${legend.levels[i]}</td>`;
		if (i === legend.levels.length - 1) {
			h += `<td bgcolor="${legend.colors[i]}" class="arrow-up" style="height: 10px; width:10px; font-size:8px; text-align: center; transform: translateY(-5px)"></td>`;
		} else {
			h += `<td bgcolor="${legend.colors[i]}" style="height: 10px; width:10px; font-size:8px; text-align: center; transform: translateY(-5px)"></td>`;
		}
		h += '</tr>';
	}
	h += '</table>';
	const tabLegend = document.getElementById('tabLegend');
	if (tabLegend instanceof HTMLElement) {
		tabLegend.innerHTML = h;
	}
	aeroval.isLegendPlotted = true;
}

function updateTS() {
	const chartTS = utils.getChartById('map-ts');
	if (!chartTS) return;

	// just update the plotline
	chartTS.xAxis[0].update({
		plotLines: [
			{
				color: 'rgba(150,150,150,0.1)',
				width: 20,
				value: aeroval.date.valueOf(),
				zIndex: 0,
			},
		],
	});

	const time = (chartTS.series[0] as any)?.dataTable.columns.x as number[];
	const timeResolution = utils.getTimeResolution(time)
	const subTitleText = formatSubtitle(new Date(aeroval.date), timeResolution)

	chartTS.setTitle({ text: undefined }, { text: subTitleText });
}

function getRegionalTS(div: 'mod1' | 'mod2', updateTS: boolean, initSlider: boolean = false, addModObs: boolean = false) {
	// make sure we request an available observation network
	const observation = aeroval.observation === 'None' ? Object.keys(aeroval.menu[aeroval.parameter.dir].obs)[0] : aeroval.observation;
	const url = `${window.API_ROOT}/ts/${aeroval.project}/${aeroval.experiment.name}/${aeroval.region}/${observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.data.ts = data;

			if (initSlider) {
				timeSlider(data)
			}
			if (updateTS) {
				plotRegionalTS(data);
				document.querySelector('#map-ts')?.classList.remove('loading');
			}
			if (addModObs) {
				plotMap(div)
				if (aeroval.observation !== 'None') {
					getObs();
				}
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			document.querySelector('#map-ts')?.classList.add('noStat');
		});
}

function plotRegionalTS(data: TSData) {
	if (!data) return;

	const series = [{} as any, {} as any, {} as any]
	const models = [aeroval.model1.dir, aeroval.model2.dir]

	var nMod = 0
	for (let model of models) {
		if (model === 'None' || model === undefined) continue;
		const dat = data[model]
		if (!dat) continue

		// check unit consistency with contour plots
		if ((aeroval.Contours1) && (aeroval.Contours1.legend.units != dat.obs_unit)) {
			console.warn(`Warning: Unit mismatch between contour plots (${aeroval.Contours1.legend.units})and time series ${dat.obs_unit}.`);
		}

		const modName = dat.model_name;
		const obsName = dat.obs_name;
		const seriesName = `${modName}[${obsName}]@${aeroval.region}`
		const modColor = nMod == 0 ? utils.highchartsPalette(0) : utils.highchartsPalette(1);
		const mapFrequency = getMapFrequency();

		const obsData = dat[`${mapFrequency as Aeroval["frequency"]}_obs`] as any
		const modelData = dat[`${mapFrequency as Aeroval["frequency"]}_mod`] as any
		const obsColor = utils.highchartsPalette(2);
		const seriesModData: [number, number][] = [];
		const seriesObsData: [number, number][] = [];

		const time = dat[`${mapFrequency as Aeroval["frequency"]}_date`] as any
		if (!time || !modelData) {
			return;
		}

		for (let j in time) {
			//push average and stdDevev
			const timeValue = new Date(time[j]).valueOf();
			seriesModData.push([timeValue, modelData[j]]);
			seriesObsData.push([timeValue, obsData[j]]);
		}

		series[nMod] = {
			name: seriesName,
			id: `mod-${nMod}'`,
			data: seriesModData,
			zIndex: 1,
			lineWidth: chartsStyle['map-ts']['mod-*'].lineWidth * eval(aeroval.settings.lineWidthFactor),
			marker: {
				fillColor: 'white',
				radius: chartsStyle['map-ts']['mod-*'].markerSize * eval(aeroval.settings.lineWidthFactor),
				lineWidth: 2,
				lineColor: modColor,
			},
			color: modColor,
		} as any;

		// add observation using first model available
		if ((nMod == 0) && (aeroval.observation !== 'None')) {
			series[2] = {
				name: `${dat.obs_name}@${aeroval.region}`,
				id: `obs`,
				data: seriesObsData,
				zIndex: 1,
				lineWidth: chartsStyle['map-ts']['mod-*'].lineWidth * eval(aeroval.settings.lineWidthFactor),
				marker: {
					fillColor: 'white',
					radius: chartsStyle['map-ts']['mod-*'].markerSize * eval(aeroval.settings.lineWidthFactor),
					lineWidth: 2,
					lineColor: obsColor,
					symbol: 'square',
				},
				color: obsColor,
			} as any;
		}

		nMod += 1
	}

	// time series margins
	const marginLeft = aeroval.isMobile ? 60 : 80;
	const marginRight = aeroval.isMobile ? 10 : 20;

	// subtitle
	const timeResolution = utils.getTimeResolution(aeroval.timeArray)
	const subtitleText = formatSubtitle(new Date(aeroval.date), timeResolution)
	if (timeResolution === 'hourly') {
		var xDateFormat = undefined
	} else if (timeResolution === 'daily') {
		xDateFormat = '%Y-%m-%d'
	} else if (timeResolution === 'monthly') {
		xDateFormat = '%Y-%m'
	} else if (timeResolution === 'yearly') {
		xDateFormat = '%Y'
	}

	aeroval.isTSRegional = true
	const gridLineWidth = eval(aeroval.settings.verticalGridLines) ? 2 : 0;

	Highcharts.chart({
		chart: {
			renderTo: 'map-ts',
			marginLeft,
			marginRight,
			animation: false,
			backgroundColor: 'transparent',
			events: {
				redraw: function () {
					scaleSlider();
				},
				render: function () {
					scaleSlider();
				}
			},
		},
		exporting: {
			enabled: false
		},
		title: {
			text: '',
		},
		subtitle: {
			text: subtitleText,
		},
		xAxis: {
			type: 'datetime',
			gridLineWidth: gridLineWidth,
			plotLines: [
				{
					color: 'rgba(150,150,150,0.1)',
					width: 20,
					value: aeroval.date.valueOf(),
					zIndex: 0,
				},
			],
			labels: {
				x: 40,
			},
		},
		yAxis: {
			title: {
				text: aeroval.parameter.name,
			},
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			xDateFormat: xDateFormat,
			valueDecimals: 2,
			shared: true,
		},
		series: series,
		plotOptions: {
			series: {
				label: {
					connectorAllowed: false
				}
			}
		},
	});

}

function formatSubtitle(date: Date, timeResolution: undefined | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly'): string {
	// Get year, month, and day, and format them to yyyy/mm/dd
	const yyyy = date.getFullYear();
	const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
	const dd = String(date.getDate()).padStart(2, '0');
	const hh = String(date.getHours()).padStart(2, '0');
	const min = String(date.getMinutes()).padStart(2, '0');


	if (timeResolution === 'hourly') {
		return `${yyyy}-${mm}-${dd} ${hh}:${min}:00Z`
	} else if (timeResolution === 'daily') {
		return `${yyyy}-${mm}-${dd}`
	} else if (timeResolution === 'weekly') {
		return `${yyyy}-${mm}-${dd}`
	} else if (timeResolution === 'monthly') {
		return `${yyyy}-${mm}`
	} else if (timeResolution === 'yearly') {
		return `${yyyy}`
	} else {
		return `${yyyy}-${mm}-${dd}`
	}
}

function scaleSlider(): void {
	const mSlider = document.getElementById('m_slider');
	if (!(mSlider instanceof HTMLInputElement)) return;

	var wts: string | null = null;
	const highchartsPlotBackground = document.querySelector('#map-ts > * > svg > rect.highcharts-plot-background');
	if (highchartsPlotBackground) {
		wts = highchartsPlotBackground.getAttribute('width');
	}

	const dx = 18; // margin between the thumb and the slider edge
	if (wts) {
		if (aeroval.isMobile) {
			mSlider.style.width = `${parseFloat(wts) + 2 * dx}px`;
			//mSlider.style.right = `-${dx / 2}px`;
			mSlider.style.visibility = 'initial';
		} else {
			mSlider.style.width = `${parseFloat(wts) + 2 * dx}px`;
			mSlider.style.left = `-${dx / 2}px`;
			mSlider.style.visibility = 'initial';
		}
	} else if (aeroval.mapType === 'overlay') {
		mSlider.style.width = '100%';
		mSlider.style.visibility = 'initial';
	}
}

function showWaiter() {
	if (aeroval.loading) {
		const waiter = document.querySelector('#waiter');
		if (waiter instanceof HTMLElement) {
			waiter.style.display = 'flex';
		}
		const menu = document.getElementById('menu')
		if (menu instanceof HTMLElement) {
			menu.style.pointerEvents = 'none';
		}
	}
}

function hideWaiter() {
	document.body.style.cursor = 'default';
	aeroval.loading = false;
	const waiter = document.querySelector('#waiter');
	if (waiter instanceof HTMLElement) {
		waiter.style.display = 'none';
	}
	const menu = document.getElementById('menu')
	if (menu instanceof HTMLElement) {
		menu.style.pointerEvents = 'auto';
	}
}

function map(div: 'mod1' | 'mod2', updateTS: boolean = true) {
	const modDir = div === 'mod1' ? aeroval.model1.dir : aeroval.model2.dir;
	const map = div === 'mod1' ? aeroval.map1 : aeroval.map2;

	if (typeof map == 'undefined' || map instanceof Node) {
		createMap(div);
	} else {
		cleanMap(div, false);
	}

	const divElement = document.querySelector(`#${div}`);
	if (divElement instanceof HTMLElement) {
		divElement.classList.add('loading');
	}

	document.body.style.cursor = 'progress';
	aeroval.loading = true;
	setTimeout(showWaiter, 100);

	// we plot the gridded map if not using wms
	if (modDir != 'None') {
		getRegionalTS(div, updateTS, true, true);
	}
}

export function callbackMenu() {
	mapMenu();
	cleanStations('mod1');
	map('mod1', true);
	if (aeroval.map2) {
		cleanStations('mod2');
		map('mod2', true);
	}
}

function showMapMessage(div: 'mod1' | 'mod2', message: string, fontSize: number = 16) {
	hideWaiter()
	const mapMessageElement = document.getElementById(`mapMessage-${div}`)
	if (mapMessageElement) {
		mapMessageElement.innerHTML = message;
		mapMessageElement.style.display = 'initial'
		mapMessageElement.style.fontSize = `${fontSize}px`;
		mapMessageElement.style.color = `black`;
	}
}

function hideMapMessage(div: 'mod1' | 'mod2') {
	const mapMessageElement = document.getElementById(`mapMessage-${div}`)
	if (mapMessageElement) {
		mapMessageElement.style.display = 'none'
	}
}

function updateRegion() {

	const region = aeroval.regions[aeroval.region];
	if (region.minLon) {
		aeroval.coos = {
			min: {
				lon: region.minLon,
				lat: region.minLat,
			},
			max: {
				lon: region.maxLon,
				lat: region.maxLat,
			},
		};
	} else if (typeof aeroval.regions[aeroval.region] === 'string') {
		//get polygon from Borders file
		const layers = aeroval.geojsonLayer._layers;
		for (const key in layers) {
			if (aeroval.geojsonLayer._layers[key].feature.properties.ISO2 == aeroval.regions[aeroval.region]) {
				aeroval.coos = {
					min: {
						lon: aeroval.geojsonLayer._layers[key]._bounds._southWest.lng,
						lat: aeroval.geojsonLayer._layers[key]._bounds._southWest.lat,
					},
					max: {
						lon: aeroval.geojsonLayer._layers[key]._bounds._northEast.lng,
						lat: aeroval.geojsonLayer._layers[key]._bounds._northEast.lat,
					},
				};
				break;
			}
		}
	} else if (typeof aeroval.regions[aeroval.region] == 'object') {
		// if array
		console.info('TODO: support HTAP regions');
	}
	showRegion();
}

function showRegion() {
	if (aeroval.region1) {
		aeroval.region1.eachLayer(function (layer: any) {
			aeroval.region1.removeLayer(layer);
		});
	}

	const mapRegion = L.featureGroup();
	const minLat = aeroval.coos.min.lat;
	const maxLat = aeroval.coos.max.lat;
	const minLon = aeroval.coos.min.lon;
	const maxLon = aeroval.coos.max.lon;

	// if the polygon does not cross the map
	if (minLon < maxLon) {
		var polygonPoints = [
			[minLat, minLon],
			[maxLat, minLon],
			[maxLat, maxLon],
			[minLat, maxLon],
		];
		mapRegion.addLayer(
			L.polygon(polygonPoints, {
				fillOpacity: 0,
				color: 'gray',
				pane: 'region',
			})
		);
	} else {
		var polygonPointsRight = [
			[minLat, minLon],
			[maxLat, minLon],
			[maxLat, 180],
			[minLat, 180],
		];
		var polygonPointsLeft = [
			[minLat, -180],
			[maxLat, -180],
			[maxLat, maxLon],
			[minLat, maxLon],
		];
		mapRegion
			.addLayer(
				L.polygon(polygonPointsRight, {
					fillOpacity: 0,
					color: 'gray',
					pane: 'region',
				})
			)
			.addLayer(
				L.polygon(polygonPointsLeft, {
					fillOpacity: 0,
					color: 'gray',
					pane: 'region',
				})
			);
	}

	aeroval.map1.addLayer(mapRegion);
	aeroval.region1 = mapRegion;

	//go to this region
	//aeroval.map1.panTo(new L.LatLng(.5*(minLat+maxLat), .5*(minLon+maxLon)));
}

function showMap2(): void {
	document.getElementById('map2')!.style.setProperty('display', 'initial', 'important');
	const toggleWithHisto2Scat = document.querySelector('.toggle:has(#histo2scat)');
	if (toggleWithHisto2Scat instanceof HTMLElement) {
		toggleWithHisto2Scat.style.display = 'initial';
	}
	const map1 = document.getElementById('map1');
	if (map1) {
		map1.style.width = 'calc(50% - 20px)';
		map1.style.width = 'calc(100% - 20px)';
		map1.style.marginTop = '0px';
	}
	const map2 = document.getElementById('map2');
	if (map2) {
		map2.style.width = 'calc(50% - 20px)';
		map2.style.width = 'calc(100% - 20px)';
	}
}

function hideMap2(): void {
	const map2 = document.getElementById('map2');
	if (map2) {
		map2.style.setProperty('display', 'none', 'important');
	}
	const map1 = document.getElementById('map1');
	if (map1) {
		map1.style.width = '100%';
	}
	const pixInfo2 = document.getElementById('pixInfo2');
	if (pixInfo2) {
		pixInfo2.innerHTML = '';
	}
}

export function update(which: string, what: any): void {
	console.log(`update ${which} with ${what}`);
	aeroval.update = which;

	if (which == 'station') {
		//for MODIS, station are tuples, and seen hre as array
		aeroval.station = typeof what != 'string' ? `(${what[0].toFixed(1)}, ${what[1].toFixed(1)})` : what;
	}
	if (which == 'parameter') {
		aeroval.parameter.dir = what;
		delete aeroval.legend;
		aeroval.isLegendPlotted = false
		aeroval.recObs = aeroval.observation;
		main.topMenu(true, true);
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'experiment') {
		aeroval.freshStart = true;
		delete aeroval.legend;
		aeroval.isLegendPlotted = false
		aeroval.experiment = aeroval.experiments[what];
		aeroval.experiment.name = what;
		aeroval.observation = 'None';
		main.topMenu(true, false);
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'region') {
		aeroval.region = what;
		updateRegion();
		getRegionalTS('mod1', true);
		if (aeroval.model2.dir != 'None') {
			getRegionalTS('mod2', true);
		}
		main.showMenu('region', what, what);
	}
	if (which == 'model1') {
		aeroval.model1 = {
			dir: what,
			name: what,
			var: aeroval.modVars[what],
		};
		aeroval.model = aeroval.model1;
		cleanMap('mod1', true);
		map('mod1', true);
		main.showMenu(which, what, what);
		utils.updateQueryString('model', what);
		main.updateURLs('model', what);
	}
	if (which == 'model2') {
		cleanMap('mod2', true);
		if (what !== 'None') {
			aeroval.model2 = {
				dir: what,
				name: what,
				var: aeroval.modVars[what] ? aeroval.modVars[what] : aeroval.model1.var,
			};
			showMap2();
			map('mod2', true);
			main.showMenu(which, what, what);
			aeroval.map1.invalidateSize();
			aeroval.map2.invalidateSize();
		} else {
			aeroval.model2.dir = what;
			aeroval.model2.var = aeroval.modVars[what];
			hideMap2();
			aeroval.map1.invalidateSize();
			main.showMenu(which, what, what);
			getRegionalTS('mod1', true, false, true);
			cleanStations('mod2');
		}
	}
	if (which == 'date') {
		aeroval[which] = what;
		smartPlotLayers('mod1');
		updateTS();
		updateStations();
		if (aeroval.model2.dir !== 'None') {
			smartPlotLayers('mod2');
		}
	}
	if (which == 'year') {
		aeroval.timeArray = what;
		main.showMenu(which, what);
		map('mod1');
		if (aeroval.model2.dir != 'None') {
			map('mod2');
		}
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}

	if (which == 'layersOpacity') {
		aeroval[which] = what;
		updateModPane('mod1');
		if (aeroval.model2.dir != 'None') {
			updateModPane('mod2');
		}
	}
	if (which == 'bordersColor') {
		aeroval[which] = what;
		const divs = ['mod1', 'mod2'];
		for (const div of divs) {
			if (typeof aeroval[`${div}-${aeroval.mapView}-countries`] != 'undefined') {
				aeroval[`${div}-${aeroval.mapView}-countries`].eachLayer(function (layer: any) {
					layer.setStyle({
						color: colorCountry(),
					});
				});
			}
		}

		//change stations color
		const outlineColor = what == 'light' ? colorCountry('dark') : colorCountry('light');

		if (aeroval.mapView == '2D') {
			updateStations();
		} else if (aeroval.mapView == '3D') {
			var entities = aeroval.viewer.entities;
			aeroval.viewer.entities.suspendEvents();
			for (let i in entities.values) {
				var entity = entities.values[i];
				if (entity.id.split('-')[0] == 'station') {
					aeroval.viewer.entities.values[i].ellipse.outlineColor = Cesium.Color.fromAlpha(Cesium.Color.fromCssColorString(outlineColor), 0.8);
				}
			}
			aeroval.viewer.entities.resumeEvents();
		}
	}
	if (which == 'observation') {
		aeroval[which] = what;
		cleanStations('mod1');
		getRegionalTS('mod1', true);
		if (aeroval.model2.dir != 'None') {
			cleanStations('mod2');
			getRegionalTS('mod2', true);
		}
		getObs();
		utils.updateQueryString(which, what);
		main.updateURLs(which, what);
	}
	if (which == 'stationsRadius') {
		aeroval[which] = what;
		updateStations();
	}
	if (which === 'autoplay') {
		aeroval[which] = what;
		setCookie(which, what, 14)
		updateAutoplay()
	}
	if (which === 'mapType') {
		aeroval[which] = what;
		aeroval.isLegendPlotted = false
		utils.updateQueryString(which, what);
		cleanMap('mod1', true)
		map('mod1');
		if (aeroval.model2.dir != 'None') {
			cleanMap('mod2', true)
			map('mod2');
		}
	}
	if (which === 'colorMap') {
		delete aeroval.legend
		aeroval.ranges[aeroval.parameter.dir].colmap = what;
		aeroval.isLegendPlotted = false
		utils.updateQueryString(which, what);
		cleanMap('mod1', true)
		map('mod1', false)
		if (aeroval.model2.dir != 'None') {
			cleanMap('mod2', true)
			map('mod2', false)
		}
	}
	if (which === 'colorMapType') {
		aeroval[which] = what;
		delete aeroval.legend
		aeroval.isLegendPlotted = false
		utils.updateQueryString(which, what);
		cleanMap('mod1', true)
		map('mod1', false);
		if (aeroval.model2.dir != 'None') {
			cleanMap('mod2', true)
			map('mod2', false);
		}
	}
}

function updateAutoplay(): void {
	if (eval(aeroval.autoplay)) {
		aeroval.intervalId = setInterval(autoplay, aeroval.autoPlayInterval)
	} else {
		clearInterval(aeroval.intervalId);
	}
}

function autoplay(): void {
	// change date ever interval. go back to first date when reach the end
	const chartTS = utils.getChartById('map-ts');
	if (!chartTS) return;
	const time = (chartTS.series[0] as any)?.dataTable.columns.x as number[];
	const currentDateIndex = time.indexOf(aeroval.date)
	if (currentDateIndex < time.length - 1) {
		var newIndex = currentDateIndex + 1
	} else {
		newIndex = 0
	}

	const sliderElement = document.getElementById('m_slider')
	if (sliderElement as HTMLInputElement) {
		(sliderElement as any).value = newIndex
		// dispatch input event
		const event = new Event("input");
		sliderElement?.dispatchEvent(event)
	}

}

function cleanStations(div: 'mod1' | 'mod2'): void {
	if (div == 'mod1') {
		var myMap = aeroval.map1;
		var stations = aeroval.stations1;
		delete aeroval.stations1;
	} else {
		myMap = aeroval.map2;
		stations = aeroval.stations2;
		aeroval.stations2;
	}

	if (typeof stations != 'undefined') {
		myMap.eachLayer(function (layer: any) {
			if (layer.options.pane.includes('obs')) {
				myMap.removeLayer(layer);
				delete myMap._paneRenderers[layer.options.pane];
			}
		});
	}
	document.body.style.cursor = 'default';
}

function getObs() {
	//read json file for mod1
	if (aeroval.observation != 'None') {
		document.body.style.cursor = 'progress';

		const url = `${window.API_ROOT}/scat/${aeroval.project}/${aeroval.experiment.name}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}/${aeroval.model1.dir}/${aeroval.model1.var}/${aeroval.cfg.time_cfg.periods[0].replaceAll('/', '')}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;

		fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json'
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				plotStations(data);
			})
			.catch((error) => {
				console.error('There was a problem with the fetch operation:', error);
			});
	}
}

function mapMenu() {
	/*creates mapMenu*/
	var h = `<div id="mapMenuContent" class="container">
		<div class="mapMenuSubmenu">
		<span class="mapMenuTitle">Settings</span>`

	// plot type
	h += `<span class="mapMenuSubtitle">Map Type</span>
		<div class="btn-group" role="group" style="width: 100%">`
	for (let mapType of aeroval.mapTypes) {
		h += `<input type="radio" class="btn-check" name="mapTypeRadios" autocomplete="off" id="${mapType}" value="${mapType}"`;
		h += aeroval.mapType == mapType ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${mapType}" style="width:${100 / aeroval.mapTypes.length}%">
			${utils.capitalize(mapType)}
			</label>`
	}
	h += '</div>';

	// color map
	const requestedColorMap = aeroval.colorMap ? aeroval.colorMap : aeroval.ranges[aeroval.parameter.dir].colmap
	h += `<span class="mapMenuSubtitle">Color Map</span>
		<div class="btn-group" role="group" style="width: 100%">`
	for (let colorMap of aeroval.colorMaps) {
		h += `<input type="radio" class="btn-check" name="colorMapRadios" autocomplete="off" id="${colorMap}" value="${colorMap}"`;
		h += requestedColorMap == colorMap ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${colorMap}">
			${utils.capitalize(colorMap)}
			</label>`
	}
	h += '</div>';
	aeroval.ranges[aeroval.parameter.dir].colmap = requestedColorMap;

	// color map
	const requestedColorMapType = aeroval.colorMapType ? aeroval.colorMapType : aeroval.ranges[aeroval.parameter.dir].colmap
	h += `<span class="mapMenuSubtitle">Color Map Type</span>
		<div class="btn-group" role="group" style="width: 100%">`
	for (let colorMapType of aeroval.colorMapTypes) {
		h += `<input type="radio" class="btn-check" name="colorMapTypeRadios" autocomplete="off" id="${colorMapType}" value="${colorMapType}"`;
		h += requestedColorMapType == colorMapType ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${colorMapType}">
			${utils.capitalize(colorMapType)}
			</label>`
	}
	h += '</div>';
	aeroval.ranges[aeroval.parameter.dir].colmap = requestedColorMap;


	h += `<span class="mapMenuSubtitle">Observations</span>
		<div id="list-obs" class="btn-group" role="group" style="display: block;">`
	const obs = ['None'].concat(Object.keys(aeroval.menu[aeroval.parameter.dir].obs));

	// initialize observation
	if (aeroval.freshStart) {
		// check if query string
		var queryObs = utils.getQueryString('observation')
		if (queryObs && obs.includes(queryObs)) {
			aeroval.observation = queryObs
		} else {
			aeroval.observation = obs[0];
		}
	} else if (!obs.includes(aeroval.observation) || aeroval.recObs == 'None') {
		aeroval.observation = obs[0];
	}

	// add empty None object to menu
	for (let o of obs) {
		h += `<input type="radio" class="btn-check" name="observationRadios" autocomplete="off" id="${o}" value="${o}"`;
		h += aeroval.observation == o ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${o}">${utils.capitalize(o)}</label>`;
	}
	h += '</div>';

	//station radius slider
	h += `<span class="mapMenuSubtitle">Stations size</span>
		<div class="slidecontainer">
		<input type="range" min="1" max="20" step="1" value="${aeroval.stationsRadius}" class="slider" id="stationsRadiusRange" style="width: 100%">
		</div>`

	/*
	//Map Types
	h += '<span class="mapMenuSubtitle">Map type</span>'
	h += '<div class="btn-group" role="group" style="width: 100%">'
	for (let mapType of aeroval.mapTypes) {
		if (aeroval.mapType == mapType) {
			h += '<input type="radio" class="btn-check" name="mapTypeRadios" autocomplete="off" id="'+mapType + '" value="'+mapType + '" checked>'
		} else {
			h += '<input type="radio" class="btn-check"name="mapTypeRadios" autocomplete="off" id="'+mapType + '" value="'+mapType + '">'
		}
		h += '<label class="btn btn-sm ' + btn + '" for="'+mapType+'" style="width: ' + 100 / aeroval.mapTypes.length + '%">'
		if (mapType == 'grid'){
			h += 'Grid (5x5)'
		} else {
			h += mapType.capitalize()
		}
		h += '</label>'
	}
	h += '</div>'
	
	//Map View
	h += '<span class="mapMenuSubtitle">Map view</span>'
	h += '<div class="btn-group" role="group" style="width: 100%">'
	for (let mapView of aeroval.mapViews) {
		if (aeroval.mapView == mapView) {
			h += '<input type="radio" class="btn-check" name="mapViewRadios" autocomplete="off" id="'+mapView+'" value="'+mapView+'" checked>'
		} else {
			h += '<input type="radio" class="btn-check" name="mapViewRadios" autocomplete="off" id="'+mapView+'" value="'+mapView+'">'
		}
		h += '<label class="btn btn-sm ' + btn + '" for="'+mapView+'" style="width: ' + 100 / aeroval.mapViews.length + '%">'
		h += mapView.capitalize()
		h += '</label>'
	}
	h += '</div>'
	*/

	//opacity slider
	h += `<span class="mapMenuSubtitle">Layers opacity</span>
		<div class="slidecontainer">
		<input type="range" min="0" max="1" step=".1" value=".8" class="slider" id="layersOpacityRange" style="width: 100%">
		</div>`

	//countries borders color
	h += `<span class="mapMenuSubtitle">Countries borders</span>
		<div class="btn-group" role="group" style="width: 100%">`
	for (let bordersColor of aeroval.bordersColors) {
		h += `<input type="radio" class="btn-check" name="bordersColorRadios" autocomplete="off" id="${bordersColor}" value="${bordersColor}"`;
		h += aeroval.bordersColor == bordersColor ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${bordersColor}" style="width:${100 / aeroval.bordersColors.length}%">
			${utils.capitalize(bordersColor)}
			</label>`
	}
	h += '</div>';

	h += `<span class="mapMenuSubtitle">Autoplay</span>
		<div id="list-obs" class="btn-group" role="group" style="display: block;">`
	for (let autoplay of aeroval.autoplays) {
		h += `<input type="radio" class="btn-check" name="autoplayRadios" autocomplete="off" id="${autoplay}" value="${autoplay}"`;
		h += aeroval.autoplay == autoplay ? `checked>` : `>`
		h += `<label class="btn btn-sm btn-${aeroval.settings.theme}" for="${autoplay}" style="width:${100 / aeroval.autoplays.length}%">
			${utils.capitalize(autoplay)}
			</label>`
	}
	h += '</div>';

	h += '</div>';

	const mapMenu = document.getElementById('mapMenu');
	if (mapMenu instanceof HTMLElement) {
		mapMenu.innerHTML = h;
	}

	// menu functions
	const radioButtons = ['autoplay', 'bordersColor', 'mapType', 'colorMap', 'colorMapType', 'observation']
	for (const radioButton of radioButtons) {
		const radioButtonElements = document.querySelectorAll(`input[name='${radioButton}Radios']`);
		radioButtonElements.forEach((radio) => {
			radio.addEventListener('change', (event) => {
				const target = event.target as HTMLInputElement;
				if (target.checked) {
					update(radioButton, target.value);
					event.stopImmediatePropagation();
				}
			});
		});
	}

	const rangesButtons = ['layersOpacity', 'stationsRadius']
	for (const rangeButton of rangesButtons) {
		const rangeElement = document.getElementById(`${rangeButton}Range`) as HTMLInputElement;
		if (rangeElement) {
			rangeElement.addEventListener('input', (event) => {
				const target = event.target as HTMLInputElement;
				update(rangeButton, parseFloat(target.value));
				event.stopImmediatePropagation();
			});
		}
	}

	aeroval.freshStart = false;
}
