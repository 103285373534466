// Import Highcharts modules
import * as Highcharts from 'highcharts';

import { Aeroval } from '../../../types/global';
declare var aeroval: Aeroval;

export function addGuidelines(chart: Highcharts.Chart) {
	// set options
	const color = aeroval.settings.theme == 'dark' ? 'rgba(200,200,200,0.7)' : 'rgba(128,128,128,0.7)';
	const opacity = 0.25;
	const lineWidth = 1.5;
	const showInLegend = false;
	const enableMouseTracking = false;
	const animation = false;
	const zIndex = 0;

	// Using a small non-zero value so the logtransformed value is defined for
	// use on log-axis graph
	const min: number = 0.00000000001;

	// Since axes have the same max this works.
	var [_min, max] = getBoundariesFromChart(chart)
	if (!min || !max) return;


	// add diagonal
	chart.addSeries(
		{
			id: '1:1',
			name: '1:1',
			data: [
				[min, min],
				[max, max],
			],
			showInLegend,
			color,
			opacity,
			lineWidth,
			marker: {
				enabled: false,
			},
			enableMouseTracking,
			states: {
				inactive: {
					enabled: false,
				},
			},
			animation,
			zIndex,
			label: {
				enabled: false,
			},
		} as any,
		false,
		false
	);

	// add "1:0.5"
	chart.addSeries(
		{
			id: '1:0.5',
			name: '1:0.5',
			data: [
				[min, 0.5 * min],
				[max, 0.5 * max],
			],
			showInLegend,
			color,
			opacity,
			lineWidth,
			dashStyle: 'dash',
			marker: {
				enabled: false,
			},
			enableMouseTracking,
			states: {
				inactive: {
					enabled: false,
				},
			},
			animation,
			zIndex,
			label: {
				enabled: false,
			},
		} as any,
		false,
		false
	);

	// add "1:2"
	chart.addSeries(
		{
			id: '1:2',
			name: '1:2',
			data: [
				[min, 2 * min],
				[max, 2 * max],
			],
			showInLegend,
			color,
			opacity,
			lineWidth,
			dashStyle: 'dash',
			marker: {
				enabled: false,
			},
			enableMouseTracking,
			states: {
				inactive: {
					enabled: false,
				},
			},
			animation,
			zIndex,
			label: {
				enabled: false,
			},
		} as any,
		false,
		false
	);

	// set same max and tickInterval and redraw
	chart.yAxis[0].update({ max: max }, false);
	chart.xAxis[0].update({ max: max }, true);
}


function getBoundariesFromChart(chart: Highcharts.Chart): [number | undefined, number | undefined] {
	var yMin: number | undefined = undefined;
	var yMax: number | undefined = undefined;

	if (chart) {
		if (chart.axes[1].userOptions.type === 'logarithmic') {
			yMin = Math.pow(10, (chart.axes[1] as any).min);
			yMax = Math.pow(10, (chart.axes[1] as any).max);
		} else {
			yMin = (chart.axes[1] as any).min;
			yMax = (chart.axes[1] as any).max;
		}
	}
	return [yMin, yMax];
}
