import { getCookie, setCookie } from '../cookies';
import { toggleTheme } from '../theme';
import { togglePresentationMode } from '../cursor';
import { toggleAnimation } from '../animation';
import { toggleLineStyle } from '../line-style';
import { toggleVerticalGridLines } from '../vertical-grid-lines';
import { updateLineWidth } from '../line-width';
import { toggleRegionalStatisticsSingleModelColor } from '../charts/regional-statistics-single-model';

// import interfaces
import { Aeroval } from '../../types/global';
import { CanvasConfig } from '../../types/config';
declare var aeroval: Aeroval;

import canvasConfig from '../../../public/config/canvas.json';

import './style.css'

export function offCanvas(keys: [keyof CanvasConfig]) {
	fillOutOffCanvasContent(canvasConfig, keys);
}

function fillOutOffCanvasContent(data: CanvasConfig, keys: [keyof CanvasConfig]) {
	const offCanvasHeader = `<div class="offcanvas offcanvas-end bg-light bg-opacity-75" tabindex="-1" id="offcanvas-settings" aria-labelledby="offcanvas-settings-label">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvas-settings-label">
                <i class="fas fa-gear"></i>
                Users Settings
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>`;

	var offCanvasBody = `<div class="offcanvas-body">`;
	for (let key of keys) {
		if (data[key].enabled === false) continue;

		offCanvasBody += `<h6 class="offcanvas-title" id="offcanvas-settings-label" title="${data[key].description}">${data[key].title}</h6>`

		if (data[key].type === 'radio') {
			offCanvasBody += `<div class="btn-group" role="group" aria-label="Basic radio toggle button group">`;
			for (let option of data[key].options) {
				offCanvasBody += `<input type="radio" class="btn-check" name="btn-radio-${key}" id="btn-radio-${key}-${option.value}" autocomplete="off">
					<label class="btn btn-light" for="btn-radio-${key}-${option.value}" value="${option.value}">${option.title}</label>`;
			}
			offCanvasBody += `</div>`;
		} else if (data[key].type === 'range') {
			offCanvasBody += `<div style="display: inline-flex; align-items: center;">`;
			offCanvasBody += `<input type="range" id="range-${key}" name="range-${key}" min="${data[key].options[0].value}" max="${data[key].options[1].value}" step="${data[key].options[2].value}" value="${data[key].default}" oninput="document.getElementById('range-${key}-output').value = this.value">`;
			offCanvasBody += `<output id="range-${key}-output" class="mx-3">${data[key].default} (default: ${data[key].default})</output>`
			offCanvasBody += `</div>`;
		}
	}
	offCanvasBody += `</div>`;

	const canvasContent = document.querySelector('#offcanvas-content');
	if (canvasContent instanceof HTMLElement) {
		canvasContent.innerHTML = offCanvasHeader + offCanvasBody;
	}

	// add checked attribute to default option
	for (const key of keys) {
		// check cookies
		const defaultOption = getCookie(key as string) ? getCookie(key as string) : data[key as string].default;

		if (data[key].type === 'radio') {
			const defaultElement = document.querySelector(`#btn-radio-${key as string}-${defaultOption}`);
			if (defaultElement instanceof HTMLInputElement) {
				defaultElement.checked = true;
				if (key === 'theme') {
					toggleTheme(defaultOption);
				}
				if (key === 'presentationMode') {
					togglePresentationMode(defaultOption);
				}
				if (key === 'lineStyle') {
					toggleLineStyle(defaultOption);
				}
				if (key === 'verticalGridLines') {
					toggleVerticalGridLines(defaultOption);
				}
				if (key === 'animation') {
					toggleAnimation(defaultOption);
				}
			}
		} else if (data[key].type === 'range') {
			const rangeElement = document.querySelector(`input[id=range-${key}]`)
			if (rangeElement as HTMLInputElement) {
				(rangeElement as any).value = defaultOption
			}
			const rangeOutputElement = document.querySelector(`#range-${key}-output`)
			if (rangeOutputElement as HTMLInputElement) {
				(rangeOutputElement as any).innerHTML = defaultOption
			}

			if (key === 'lineWidthFactor') {
				updateLineWidth(defaultOption);
			}
		}
		// set aeroval settings
		aeroval.settings[key] = defaultOption;

	}
	// add events
	for (const key of keys) {
		// radio buttons
		document.querySelectorAll(`input[name=btn-radio-${key}]`).forEach((element) => {
			element.addEventListener('change', function (event) {
				const inputElement = event.target as HTMLInputElement;
				const value = inputElement.id.split(`btn-radio-${key}-`)[1];
				setCookie(key as string, value, 365);
				if (key === 'theme') {
					toggleTheme(value);
				}
				if (key === 'presentationMode') {
					togglePresentationMode(value);
				}
				if (key === 'skipAnimation') {
					toggleAnimation(value);
				}
				if (key === 'lineStyle') {
					toggleLineStyle(value);
				}
				if (key === 'verticalGridLines') {
					toggleVerticalGridLines(value);
				}
				if (key === 'regionalStatisticsSingleModelColor') {
					toggleRegionalStatisticsSingleModelColor(value);
				}
			});
		});

		// ranges
		const rangeElement = document.querySelector(`input[id=range-${key}]`)
		if (rangeElement as HTMLInputElement) {
			rangeElement?.addEventListener('change', function (event) {
				const value = (event.target as any).value;
				setCookie(key as string, value, 365);
				updateLineWidth(value);
			})
		}
	}
}
