import * as utils from '../../utils';
import { colormaps } from '../../../public/js-colormaps';

/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

export function discreteColorscale(value: number, scale: number[], colorMapType: 'linear' | 'logarithmic' = 'linear'): string {
	//this export function works with discrete colorscale
	const nColors = scale.length - 1;

	var iLevel = 0;
	for (let i = 0; i < scale.length - 1; i++) {
		if (value >= scale[i] && value < scale[i + 1]) {
			iLevel = i;
			break;
		}
	}
	//if value greater than upper limit
	if (value >= scale[scale.length - 1]) iLevel = scale.length - 1;
	//if value lower than lower limit
	if (value <= scale[0]) iLevel = 0;
	var normalizedValue = iLevel / (nColors - 1);
	normalizedValue = Math.min(normalizedValue, 1)

	if (colorMapType === 'logarithmic') {
		const steepness = 10
		normalizedValue = Math.log(1 + steepness * normalizedValue) / Math.log(1 + steepness)
	}


	// colorMap
	var colorMapName = window.location.pathname.includes('/maps') ? aeroval.ranges[aeroval.parameter.dir].colmap : aeroval.statistic.colmap;
	var reverseColorMap = false
	if (colorMapName.startsWith('reverseColmap(')) {
		colorMapName = colorMapName.split('(')[1].split(')')[0];
		reverseColorMap = true
	} else if (colorMapName.endsWith('_r')) {
		colorMapName = colorMapName.split('_r')[0];
		reverseColorMap = true
	}

	const colorMap = reverseColorMap ? reverseColorscale(colormaps[colorMapName] as Aeroval['colormap']) : colormaps[colorMapName];
	const color = utils.interpolateLinearly(normalizedValue, colorMap);
	//returns hex color
	return utils.rgbToHex(Math.round(color[0] * 255), Math.round(color[1] * 255), Math.round(color[2] * 255));
}

export function continuousColorscale(value: number, scale: number[]): string {
	const nCol = scale.length;
	var iLevel = 0;

	for (let i: number = 0; i < scale.length; i++) {
		if (value > scale[i] && value <= scale[i + 1]) {
			var iLevel = i;
			break;
		}
	}
	//if value greater than upper limit
	if (value >= scale[scale.length - 1]) {
		var iLevel = scale.length - 2;
	}
	//if value lower than lower limit
	if (value <= scale[0]) {
		var iLevel = 0;
	}
	var val = (1 + iLevel) / (nCol - 1);

	// colorMap
	if (aeroval.statistic.colmap.startsWith('reverseColmap(')) {
		var colMapName = aeroval.statistic.colmap.split('(')[1].split(')')[0];
		var colMap = reverseColorscale(colormaps[colMapName] as Aeroval['colormap']);
	} else {
		colMapName = aeroval.statistic.colmap;
		colMap = colormaps[colMapName] as Aeroval['colormap'];
	}
	const color = utils.interpolateLinearly(val, colMap);

	//returns hex color
	return utils.rgbToHex(Math.round(color[0] * 255), Math.round(color[1] * 255), Math.round(color[2] * 255));
}

export function reverseColorscale(colorscale: Aeroval['colormap']): Aeroval['colormap'] {
	const reversedColorscale: Aeroval['colormap'] = [];
	for (let i = colorscale.length - 1; i >= 0; i--) {
		let rgb = colorscale[i][1];
		let x = 1 - i / colorscale.length;
		reversedColorscale.push([x, [rgb[0], rgb[1], rgb[2]]]);
	}
	return reversedColorscale;
}
