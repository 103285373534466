import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
	faFlask,
	faUser,
	faUserAltSlash,
	faDatabase,
	faFolder,
	faEye,
	faLaptop,
	faCalculator,
	faGlobe,
	faSatellite,
	faClock,
	faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';

// Add the imported icons to the library
library.add(faFlask, faUser, faUserAltSlash, faDatabase, faFolder, faEye, faLaptop, faCalculator, faGlobe, faSatellite, faClock, faClockRotateLeft, faPython);

const flask = icon({ prefix: 'fas', iconName: 'flask' });
const user = icon({ prefix: 'fas', iconName: 'user' });
const userAltSlash = icon({ prefix: 'fas', iconName: 'user-alt-slash' });
const database = icon({ prefix: 'fas', iconName: 'database' });
const folder = icon({ prefix: 'fas', iconName: 'folder' });
const eye = icon({ prefix: 'fas', iconName: 'eye' });
const laptop = icon({ prefix: 'fas', iconName: 'laptop' });
const calculator = icon({ prefix: 'fas', iconName: 'calculator' });
const globe = icon({ prefix: 'fas', iconName: 'globe' });
const satellite = icon({ prefix: 'fas', iconName: 'satellite' });
const clock = icon({ prefix: 'fas', iconName: 'clock' });
const clockRotateLeft = icon({ prefix: 'fas', iconName: 'clock-rotate-left' });
const python = icon({ prefix: 'fab', iconName: 'python' });

import { Carousel } from 'bootstrap';

// import page specific functions
import * as utils from '../utils';
import { showPopup } from '../components/popup';
import { highlightCurrentPage } from '../components/project-header';
import { plotMap } from '../components/charts/map';
import { plotRegionalStatisticsSingleModel } from '../components/charts/regional-statistics-single-model';
import { plotRegionalStatisticsMultiModel } from '../components/charts/regional-statistics-multi-model';
import { plotTimeSeriesSingleModel } from '../components/charts/time-series-single-model';
import { plotTimeSeriesMultiModel } from '../components/charts/time-series-multi-model';

import { plotScatterPlotSingleModel } from '../components/charts/scatter-plot-single-model';
import { plotScatterPlotMultiModel } from '../components/charts/scatter-plot-multi-model';

import { plotProfile } from '../components/charts/profile';

import { callbackMenu as evalCallbackMenu, update as evalUpdate } from './evaluation';
import { callbackMenu as interCallbackMenu, update as interUpdate } from './intercomp';
import { callbackMenu as overCallbackMenu, update as overUpdate } from './overall';
import { update as infoUpdate } from './infos';
import { callbackMenu as mapsCallbackMenu, update as mapsUpdate } from './maps';
import { callbackMenu as repoCallbackMenu, update as repoUpdate } from './reports';

// import interfaces
import { Aeroval, Statistics } from '../types/global';
import { MapData, RegStatData, TSData } from '../types/data';
import { plotTimeSeriesStatistics } from '../components/charts/time-series-statistics';
declare var aeroval: Aeroval;

interface MenuEntry {
	name: string;
	logo: string;
	which: string;
	what: string[];
	type: string[];
	title: string[];
	show: string;
	subMenus?: any;
	layers?: string[];
}

utils.initAeroval();
utils.initAPI();

export function getQueryStrings(): void {
	if (window.location.pathname.includes('/evaluation') || window.location.pathname.includes('/intercomp')) {
		var queryStrings: Aeroval['querystrings'] = [
			'project',
			'experiment',
			'station',
			'parameter',
			'layer',
			'observation',
			'model',
			'time',
			'season',
			'frequency',
			'statistic',
			'tsType'
		];
	} else if (window.location.pathname.includes('/overall')) {
		queryStrings = [
			'project',
			'experiment',
			'frequency',
			'region',
			'statistic',
			'tab',
			'sound',
			'parameter',
			'time',
			'model',
			'layer',
			'season',
			'yearAndGroupBy',
			'meanAndStatistic',
		];
	} else if (window.location.pathname.includes('/infos')) {
		queryStrings = ['project', 'experiment', 'station', 'parameter', 'layer', 'observation', 'model', 'time', 'reg', 'tab'];
	} else if (window.location.pathname.includes('/maps')) {
		queryStrings = ['project', 'experiment', 'station', 'parameter', 'layer', 'observation', 'model', 'time', 'reg', 'mapView'];
	} else if (window.location.pathname.includes('/reports')) {
		queryStrings = ['project', 'experiment', 'station', 'model', 'time'];
	} else {
		queryStrings = ['project', 'experiment'];
	}

	for (let queryString of queryStrings) {
		let value = utils.getQueryString(queryString);
		if (value) {
			if (queryString === 'parameter') {
				aeroval.parameter = { dir: value, name: value };
			} else if (queryString == 'statistic') {
				aeroval.statistic = { dir: value, colmap: 'none', decimals: 0, longname: 'none', name: 'none', scale: [], unit: 'none' };
			} else if (queryString == 'model') {
				aeroval.model = { dir: value, name: 'none', var: 'none' };
			} else if (queryString == 'experiment') {
				aeroval.experiment = {
					name: value,
					public: false,
					additional_ts_tabs: [],
				};
			} else {
				aeroval[queryString] = value;
				// If bool, evaluate it
				if (value === 'true' || value === 'false') {
					aeroval[queryString] = true ? value === 'true' : false;
				}
			}
			updateURLs(queryString, value);
		}
	}
}

export function getExperiments(get_settings = true, map_options = true, update_pages = true, update_containers = true, update_tabs = true, show_popup = true): void {
	// Set experiment if not already set
	const url = `${window.API_ROOT}/experiments/${aeroval.project}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.experiments = data;
			var exps = Object.keys(data);
			if (typeof aeroval.experiment == 'undefined' || typeof aeroval.experiment.name == 'undefined' || !exps.includes(aeroval.experiment.name)) {
				//initialize to first experiment of the list
				aeroval.experiment = aeroval.experiments[exps[0]];
				aeroval.experiment.name = exps[0];
				updateURLs('experiment', aeroval.experiment.name);
			} else {
				//keep selected experiment
				var experiment = aeroval.experiment.name;
				aeroval.experiment = data[experiment];
				aeroval.experiment.name = experiment;
			}
			fillExpMenu(exps, update_pages, update_containers, update_tabs, map_options, get_settings, show_popup);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			const contentMain = document.getElementById('content-main');
			if (contentMain) {
				contentMain.style.display = 'none';
			}

			const nomenu = document.getElementById('nomenu');
			if (nomenu) {
				nomenu.style.display = 'inline-flex';
			}
		});
}

export async function getExpSettings(): Promise<void> {
	const statisticsFN = `${window.API_ROOT}/statistics/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	const rangesFN = `${window.API_ROOT}/ranges/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	const regionsFN = `${window.API_ROOT}/regions/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;

	try {
		const [statisticsResponse, rangesResponse, regionsResponse] = await Promise.all([fetch(statisticsFN), fetch(rangesFN), fetch(regionsFN)]);
		const statistics = await statisticsResponse.json();
		const ranges = await rangesResponse.json();
		const regions = await regionsResponse.json();

		callbackStatistics(statistics);
		callbackRanges(ranges);
		callbackRegions(regions);

		utils.getModelStyle(null);
		topMenu();
	} catch (error) {
		console.error('Error loading configurations:', error);
	}
}

function callbackStatistics(data: Aeroval['statistics']) {
	aeroval.statistics = data;
	var exclude_keys = [];
	// remove combined statistics from intercomp and overall pages
	if (window.location.pathname.includes('/overall')) {
		for (let statistic in aeroval.statistics) {
			if (statistic.includes('/')) {
				exclude_keys.push(statistic);
			}
		}
	}
	//remove space and temporal statistics from evalation and intercomp pages
	if (window.location.pathname.includes('/evaluation') || window.location.pathname.includes('/intercomp')) {
		for (let statistic in aeroval.statistics) {
			if (statistic.includes('_spatial') || statistic.includes('_temporal')) {
				exclude_keys.push(statistic);
			}
		}
	}

	//exclude keys which are a mix of obs and mod (if name contains /)
	var keys = Object.keys(aeroval.statistics);
	for (let key of exclude_keys) {
		var index = keys.indexOf(key);
		if (index > -1) {
			delete aeroval.statistics[keys[index]];
			keys.splice(index, 1);
		}
	}

	// select default statistic
	if (typeof aeroval.statistic == 'undefined') {
		aeroval.statistic = aeroval.statistics[Object.keys(aeroval.statistics)[0]];
	}

	const statDir = aeroval.statistic.dir;
	if (keys.includes(aeroval.statistic.dir)) {
		aeroval.statistic = aeroval.statistics[aeroval.statistic.dir];
		aeroval.statistic.dir = statDir;
	} else {
		aeroval.statistic = Object.create(aeroval.statistics[keys[0]]);
		aeroval.statistic.dir = keys[0];
	}
}

function callbackRanges(data: Aeroval['ranges']) {
	aeroval.ranges = data;
}

function callbackRegions(data: Aeroval['regions']) {
	aeroval.regions = data;

	// exclude some regions
	var exclude = ['French Guiana', 'Guadeloupe', 'Martinique', 'Reunion'];
	for (let item of exclude) {
		if (typeof aeroval.regions[item] != 'undefined') {
			delete aeroval.regions[item];
		}
	}

	// if region, but no station, set station as region
	if (typeof aeroval.station == 'undefined' && aeroval.region) {
		if (aeroval.region in aeroval.regions) {
			aeroval.station = aeroval.region;
		} else {
			aeroval.region = Object.keys(aeroval.regions)[0];
		}
		aeroval.station = aeroval.region;
	}
}

function removeModelButtonIfNoScatterPlot(): void {
	// if we hide the scatter plot, remove model button
	if (aeroval.cfg && 'hide_charts' in aeroval.cfg?.webdisp_opts && aeroval.cfg.webdisp_opts.hide_charts.includes('scatterplot')) {
		aeroval.menuButtons = aeroval.menuButtons.filter((e) => e !== 'model');
	}
}

function initializeParameter(parameters: { name: string[]; type: string[]; title: string[] }): void {
	// initialize variable to first parameter
	if (!aeroval.parameter || (aeroval.parameter && !(aeroval.parameter.dir in aeroval.menu))) {
		const firstParameter = parameters.title[0]?.split(':')[0]
		aeroval.parameter = {
			dir: firstParameter,
			name: aeroval.menu[firstParameter]?.name,
		};
	} else {
		aeroval.parameter.name = aeroval.menu[aeroval.parameter.dir].name;
	}
}

function initializeObservationNetwork(observationNetworks: string[]): void {
	if (!(aeroval.observation in aeroval.menu[aeroval.parameter.dir]['obs'])) {
		aeroval.observation = observationNetworks[0];
	}
}

function initializeLayer(layers: string[]) {
	if (!(aeroval.layer in layers) && !layers.includes(aeroval.layer)) {
		aeroval.layer = layers[0];
	}
}

function initializeStatistics(statistics: Statistics): void {
	// select first statistics
	const keys = Object.keys(statistics);
	var wishedStatistic: string | undefined = aeroval.statistic.dir;
	// default statistic
	aeroval.statistic = statistics[keys[0]];
	aeroval.statistic.dir = keys[0];

	if (wishedStatistic) {
		if (utils.getQueryString('statistics')) {
			wishedStatistic = utils.getQueryString('statistics');
		}
		if (wishedStatistic && wishedStatistic in statistics) {
			aeroval.statistic = statistics[wishedStatistic];
			aeroval.statistic.dir = wishedStatistic;
		}
	}
}

function initializeMeanAndStatistics(statistics: Statistics) {
	const keys = Object.keys(statistics);
	if (!aeroval.statistic) {
		aeroval.statistic = statistics[keys[0]];
		aeroval.statistic.dir = keys[0];
	}
}

function initializeRegion(regions: string[]) {
	// set to first region if the region is not in the list
	if (!regions.includes(aeroval.region)) {
		aeroval.region = regions[0];
	}
}

function initializeObsType(obsTypes: string[]) {
	if (!aeroval.obsType && !obsTypes.includes(aeroval.obsType)) {
		aeroval.obsType = obsTypes[0];
	}
}

function initializeFrequency() {
	if (!aeroval.frequency || !aeroval.cfg.time_cfg.freqs.includes(aeroval.frequency)) {
		aeroval.frequency = aeroval.cfg.time_cfg.freqs[0];
	}
}

function initializeTime(times: string[]) {
	if ((typeof aeroval.time != 'undefined' && !times.includes(aeroval.time)) || typeof aeroval.time == 'undefined') {
		aeroval.time = times[0];
	}
}

export function topMenu(fill_menu: boolean = true, callback_menu: boolean = true): void {
	removeModelButtonIfNoScatterPlot();

	const menuElement = document.getElementById('menu') as HTMLElement;
	if (menuElement) {
		var h = '';
		for (let item of aeroval.menuButtons) {
			h += `<div class='menu-but' id='menu-${item}' style='width: ${String(100 / aeroval.menuButtons.length)}%'></div>`;
		}
		menuElement.innerHTML = h;
	}
	const url = `${window.API_ROOT}/menu/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data: Aeroval['menu']) => {
			//store menu in global variable
			aeroval.menu = data;

			const buttonsMenu: { [key: string]: MenuEntry } = {};

			// list parameters
			if (aeroval.menuButtons.includes('parameter')) {
				var parameters = listParameters(data);
				if (aeroval.cfg.webdisp_opts.var_order_menu?.length > 0) {
					parameters = orderParametersByCfgOrder(parameters);
				} else {
					parameters = orderParametersByType(parameters);
				}
				initializeParameter(parameters);
				buttonsMenu['parameter'] = {
					name: 'parameter',
					logo: folder.html[0],
					which: 'parameter',
					what: parameters.name,
					type: parameters.type,
					title: parameters.title,
					show: aeroval.parameter.name,
				};

				//initialize obs network
				const observationNetworks = Object.keys(data[aeroval.parameter.dir]['obs']);
				initializeObservationNetwork(observationNetworks);
				buttonsMenu['observation'] = {
					name: 'Observation',
					logo: eye.html[0],
					which: 'observation',
					what: observationNetworks,
					type: [],
					title: [],
					show: aeroval.observation,
				};

				//get layers for selected variable
				const layers = Object.keys(data[aeroval.parameter.dir]['obs'][aeroval.observation]);
				initializeLayer(layers);
				buttonsMenu['parameter'].layers = layers;

				if (window.location.pathname.includes('evaluation') || window.location.pathname.includes('intercomp')) {
					//if more than 1 layer, enable the profile visualization
					const scatCarousel = document.querySelector(`#scatCarousel`);
					if (scatCarousel instanceof Carousel) {
						if (layers.length > 1 && aeroval.layer !== 'Surface') {
							scatCarousel?.next();
							aeroval.profile = true;
						} else {
							scatCarousel?.prev();
							aeroval.profile = false;
						}
					}
				}

				// add modVars
				// model and sat: must go through object
				const mod: string[] = [];
				const modVar: string[] = [];
				const modId: string[] = [];
				aeroval.modVars = {} as any;
				for (const model in data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer]) {
					const cur = data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer][model];
					if (!aeroval.excludeModels.includes(cur.model_id)) {
						mod.push(model);
						modVar.push(cur.model_var);
						modId.push(cur.model_id);
						if (typeof cur.model_var != 'undefined') {
							aeroval.modVars[model] = cur.model_var;
						} else {
							aeroval.modVars[model] = aeroval.parameter.dir;
						}
					}
				}
			}

			// select par and model
			if (aeroval.menuButtons.includes('model') || aeroval.menuButtons.includes('model1')) {
				if (window.location.pathname.includes('overall') && aeroval.tab === 'heatmap') {
					if (typeof aeroval.parameter == 'undefined') {
						aeroval.parameter = {
							dir: Object.keys(aeroval.menu)[0],
							name: 'undefined',
						};
					}
					// if only dummy model, the model button is removed
					aeroval.model = {
						name: 'dummy',
						dir: 'dummy',
						var: aeroval.parameter.dir,
					};
				} else {
					if (typeof aeroval.parameter == 'undefined') {
						aeroval.parameter = {
							dir: Object.keys(aeroval.menu)[0],
							name: 'undefined',
						};
					}
					// set up obs
					if (!aeroval.observation) {
						aeroval.observation = Object.keys(data[aeroval.parameter.dir]['obs'])[0];
					}
					// set up layer
					if (!aeroval.layer) {
						aeroval.layer = Object.keys(data[aeroval.parameter.dir]['obs'][aeroval.observation])[0];
					}

					//model and sat: must go through object
					const models = [] as string[];
					const modVar = [] as string[];
					const modId = [] as string[];
					aeroval.modVars = {};
					for (const model in data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer]) {
						const cur = data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer][model];
						if (!aeroval.excludeModels.includes(cur.model_id)) {
							models.push(model);
							modVar.push(cur.model_var);
							modId.push(cur.model_id);
							if (typeof cur.model_var != 'undefined') {
								aeroval.modVars[model] = cur.model_var;
							} else {
								aeroval.modVars[model] = aeroval.parameter.dir;
							}
						}
					}

					if (typeof aeroval.model != 'undefined' && models.includes(aeroval.model.dir)) {
						const idx = models.indexOf(aeroval.model.dir);
						//do not update
						aeroval.model.name = aeroval.model.dir;
						aeroval.model.var = modVar[idx];
					} else {
						//takes first model of the list
						aeroval.model = {
							name: models[0],
							dir: models[0],
							var: modVar[0],
						};
					}
					if (typeof aeroval.model.var == 'undefined') {
						aeroval.model.var = aeroval.parameter.dir;
					}

					buttonsMenu['model'] = {
						name: 'Model',
						logo: laptop.html[0],
						which: 'model',
						what: models,
						type: [],
						title: modId,
						show: aeroval.model.name,
					};
				}
			} else if (aeroval.parameter?.dir) {
				// if no model button (trends only), initialize to dummy model
				aeroval.model = {
					name: 'dummy',
					dir: 'dummy',
					var: aeroval.parameter.dir,
				};
			}

			//list model1
			if (aeroval.menuButtons.includes('model1')) {
				//model and sat: must go through object
				const models = [] as string[];
				const modVar = [] as string[];
				const modId = [] as string[];

				for (let model in data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer]) {
					const cur = data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer][model];
					if (!aeroval.excludeModels.includes(cur.model_id)) {
						models.push(model);
						modVar.push(cur.model_var);
						modId.push(cur.model_id);
					}
				}
				if (typeof aeroval.model != 'undefined' && models.includes(aeroval.model.dir)) {
					var idx = models.indexOf(aeroval.model.dir);
					//do not update
					aeroval.model1 = {
						name: aeroval.model.dir,
						dir: aeroval.model.dir,
						var: modVar[idx],
					};
				} else {
					//takes first model of the list
					aeroval.model1 = {
						name: models[0],
						dir: models[0],
						var: modVar[0],
					};
				}
				if (typeof aeroval.model1.var == 'undefined') {
					aeroval.model1.var = aeroval.parameter.dir;
				}

				buttonsMenu['model1'] = {
					name: 'Model (left)',
					logo: laptop.html[0],
					which: 'model1',
					what: models,
					type: [],
					title: modId,
					show: aeroval.model1.name,
				};
			}

			// list model2
			if (aeroval.menuButtons.includes('model2')) {
				//model and sat: must go through object
				var models = [] as string[];
				const modVar = [] as string[];
				var modId = [] as string[];
				for (let model in data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer]) {
					const cur = data[aeroval.parameter.dir]['obs'][aeroval.observation][aeroval.layer][model];
					if (!aeroval.excludeModels.includes(cur.model_id)) {
						models.push(model);
						modVar.push(cur.model_var);
						modId.push(cur.model_id);
					}
				}
				// by default, no model 2
				if (typeof aeroval.model2 == 'undefined') {
					aeroval.model2 = {
						name: 'None',
						dir: 'None',
						var: 'None',
					};
				}
				models = ['None'].concat(models);
				modId = [''].concat(modId);

				buttonsMenu['model2'] = {
					name: 'Model (right)',
					logo: laptop.html[0],
					which: 'model2',
					what: models,
					type: [],
					title: modId,
					show: aeroval.model2.name,
				};
			}

			// list the statistics (already read)
			if (aeroval.menuButtons.includes('statistic')) {
				var statistics = listStatistics(aeroval.statistics);
				initializeStatistics(aeroval.statistics);
				buttonsMenu['statistic'] = {
					name: 'Statistic',
					logo: calculator.html[0],
					which: 'statistic',
					what: statistics.name,
					type: statistics.type,
					title: statistics.title,
					show: aeroval.statistic.name,
				};
			}

			// list the statistics (already read)
			if (aeroval.menuButtons.includes('meanAndStatistic')) {
				const statistics = listMeanAndStatistics(aeroval.statistics);
				initializeMeanAndStatistics(aeroval.statistics);
				buttonsMenu['meanAndStatistic'] = {
					name: 'Statistic',
					logo: calculator.html[0],
					which: 'meanAndStatistic',
					what: statistics.name,
					type: statistics.type,
					title: statistics.title,
					show: aeroval.statistic.name,
				};
			}

			// list regions
			var regions = Object.keys(aeroval.regions);
			initializeRegion(regions);
			if (aeroval.menuButtons.includes('regionAndCustom') && aeroval.cfg.webdisp_opts.regions_how == 'country') {
				var regions = ['ALL'];
			}

			for (const key of ['region', 'regionAndCustom']) {
				if (aeroval.menuButtons.includes(key as Aeroval['menuButton'])) {
					buttonsMenu[key] = {
						name: 'Region',
						logo: globe.html[0],
						which: key,
						what: regions,
						type: [],
						title: [],
						show: aeroval.region,
					};
				}
			}

			//list obsTypes
			if (aeroval.menuButtons.includes('obsType')) {
				const obsTypes = ['All', 'Ground Based', 'Satellite'];
				initializeObsType(obsTypes);
				buttonsMenu['obsType'] = {
					name: 'Observation Type',
					logo: satellite.html[0],
					which: 'obsType',
					what: obsTypes,
					type: [],
					title: [],
					show: aeroval.obsType,
				};
			}

			//list time
			if (utils.includesSubStr(aeroval.menuButtons, 'time') || utils.includesSubStr(aeroval.menuButtons, 'year')) {
				// select frequency
				initializeFrequency();

				//available years
				aeroval.times = listTimes();
				initializeTime(aeroval.times);

				// add subMenus
				var subMenus = [];

				// add seasons
				if (
					('add_seasons' in aeroval.cfg.time_cfg && aeroval.cfg.time_cfg.add_seasons === false) ||
					utils.includesSubStr(aeroval.menuButtons, 'no_seasons')
				) {
					// no season
					aeroval.seasons = ['All'];
				} else {
					// default
					if (utils.includesSubStr(aeroval.menuButtons, 'no_all')) {
						aeroval.seasons = ['DJF', 'MAM', 'JJA', 'SON'];
					} else {
						aeroval.seasons = ['All', 'DJF', 'MAM', 'JJA', 'SON'];
					}
					subMenus.push({
						season: aeroval.seasons,
					});
				}
				if (typeof aeroval.season == 'undefined' && !aeroval.seasons.includes(aeroval.season)) {
					aeroval.season = aeroval.seasons[0];
				}

				if (!utils.includesSubStr(aeroval.menuButtons, 'no_freqs')) {
					// add statistics
					subMenus.push({
						frequency: aeroval.cfg.time_cfg.freqs,
					});
				}

				// groupBy button (overall time series)
				if (utils.includesSubStr(aeroval.menuButtons, 'GroupBy')) {
					aeroval.groupBys = ['None'];
					if (aeroval.cfg.time_cfg.main_freq == 'monthly') {
						aeroval.groupBys.push('Month');
					}
					if (aeroval.cfg.time_cfg.main_freq == 'daily') {
						aeroval.groupBys.push('Month');
						aeroval.groupBys.push('Weekday');
					}
					// initialize
					if (!aeroval.groupBys.includes(aeroval.groupBy)) {
						aeroval.groupBy = aeroval.groupBys[0];
					}
					subMenus = [
						{
							groupBy: aeroval.groupBys,
						},
					];
				}

				const type = [];
				for (let _ in aeroval.times) {
					type.push('Period');
				}

				for (const key of ['time', 'time-no_seasons-no_freqs']) {
					if (aeroval.menuButtons.includes(key as Aeroval['menuButton'])) {
						buttonsMenu[key] = {
							name: 'Time',
							logo: clock.html[0],
							which: key,
							what: aeroval.times,
							type: type,
							title: [],
							show: aeroval.time,
							subMenus: subMenus,
						};
					}
				}

				for (const key of ['year', 'yearAndGroupBy']) {
					if (aeroval.menuButtons.includes(key as Aeroval['menuButton'])) {
						buttonsMenu[key] = {
							name: 'Year',
							logo: clock.html[0],
							which: key,
							what: aeroval.times,
							type: type,
							title: [],
							show: aeroval.time,
							subMenus: subMenus,
						};
					}
				}
			}

			//fill menus and show selected option
			aeroval.buttons = {};
			for (let i in aeroval.menuButtons) {
				const button = aeroval.menuButtons[i];
				const buttonMenu = buttonsMenu[button];
				aeroval.buttons[button] = buttonMenu;
				if (fill_menu) {
					fillMenu(buttonMenu);
				}
			}

			if (callback_menu) {
				updatePages();
				if (window.location.pathname.includes('/evaluation')) {
					evalCallbackMenu();
				} else if (window.location.pathname.includes('/intercomp')) {
					interCallbackMenu();
				} else if (window.location.pathname.includes('/overall')) {
					overCallbackMenu();
				} else if (window.location.pathname.includes('/maps')) {
					mapsCallbackMenu();
				} else if (window.location.pathname.includes('/reports')) {
					repoCallbackMenu();
				} else {
					utils.todo();
				}
			}

			//event on time radio button
			const formMonthly = document.querySelector('#form-monthly');
			if (formMonthly instanceof HTMLInputElement) {
				formMonthly.classList.remove('dropdown-item');
				formMonthly.classList.add('form-check');
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

function listParameters(data: Aeroval['menu']): {
	name: string[];
	type: string[];
	title: string[];
} {
	//get all variables for current experiment
	var parameters = {
		name: [] as string[],
		type: [] as string[],
		title: [] as string[],
	};

	for (let variable in data) {
		var skipVariable = false
		// check if the variable has sto be displayed on this menu
		if (data[variable]?.only_use_in && !data[variable]?.only_use_in?.includes(window.location.pathname.split('/').slice(-2)[0])) {
			var skipVariable = true
		}
		if (skipVariable) continue;

		parameters.name.push(data[variable].name); //par
		parameters.title.push(`${variable}: ${data[variable].longname}`); //parTitle

		const obs0 = Object.keys(data[variable]['obs'])[0];
		const layers = Object.keys(data[variable]['obs'][obs0]);

		//parType
		if (data[variable].type == '2D') {
			parameters.type.push('Column');
		} else if (data[variable].type == '3D') {
			// check if Surface or not
			if (layers.length == 1 && layers[0] == 'Surface') {
				parameters.type.push('Surface');
			} else {
				parameters.type.push('3D');
			}
		} else {
			parameters.type.push('Undefined');
		}
	}
	return parameters;
}

function listStatistics(data: Aeroval['statistics']): {
	name: string[];
	type: string[];
	title: string[];
} {
	const statistics = {
		name: [] as string[],
		title: [] as string[],
		type: [] as string[],
	};
	// some statistics are displayed only in the overall page
	for (const key in data) {
		const statistic = data[key];
		if (statistic.overall_only) {
			if (window.location.pathname.includes('/overall')) {
				if (aeroval.tab == 'median') {
					if (statistic?.forecast) {
						statistics.name.push(statistic.name);
						statistics.title.push(statistic.longname);
					}
				} else {
					statistics.name.push(statistic.name);
					statistics.title.push(statistic.longname);
					if (typeof statistic.category != 'undefined') {
						statistics.type.push(statistic.category);
					} else {
						statistics.type.push('');
					}
				}
			}
		} else {
			if (aeroval.tab == 'median') {
				if (statistic?.forecast) {
					statistics.name.push(statistic.name);
					statistics.title.push(statistic.longname);
				}
			} else {
				statistics.name.push(statistic.name);
				statistics.title.push(statistic.longname);
				if (typeof statistic.category != 'undefined') {
					statistics.type.push(statistic.category);
				} else {
					statistics.type.push('');
				}
			}
		}
	}
	return statistics;
}

function listMeanAndStatistics(data: Aeroval['statistics']): {
	name: string[];
	title: string[];
	type: string[];
} {
	const statistics = {
		name: [] as string[],
		title: [] as string[],
		type: [] as string[],
	};
	for (const key in data) {
		const statistic = data[key];
		if (key != 'refdata_mean') {
			const name = key != 'data_mean' ? data[key].name : 'Mean';
			const title = key != 'data_mean' ? data[key].longname : 'Mean (Obs and Mod)';
			if (window.location.href.includes('overall/')) {
				if (aeroval.tab == 'timeseries') {
					if (typeof statistic.time_series != 'undefined') {
						if (statistic.time_series) {
							statistics.name.push(name);
							statistics.title.push(title);
						}
					} else {
						statistics.name.push(name);
						statistics.title.push(title);
					}
				} else {
					statistics.name.push(name);
					statistics.title.push(title);
				}
				if (typeof statistic.category != 'undefined') {
					statistics.type.push(statistic.category);
				} else {
					statistics.type.push('');
				}
			} else {
				statistics.name.push(name);
				statistics.title.push(title);
				if (typeof statistic.category != 'undefined') {
					statistics.type.push(statistic.category);
				} else {
					statistics.type.push('');
				}
			}
		}
	}
	return statistics;
}

function listTimes(): string[] {
	const times = [];
	for (const period of aeroval.cfg.time_cfg.periods) {
		//if list only years, does not show All (maps.php)
		if (aeroval.menuButtons.includes('yearAndGroupBy') && period == 'All') {
			continue;
		} else {
			// push period if not no-period
			if (utils.includesSubStr(aeroval.menuButtons, 'no_periods') && period.includes('-')) {
				continue;
			} else {
				times.push(period);
			}
		}
	}
	return times;
}

export function updatePages() {

	if (!aeroval.config.pages) return;

	if (aeroval.cfg.webdisp_opts.pages) {
		// pages to be added
		var pagesToAdd: string[] = [];
		if ('pages' in aeroval.cfg.webdisp_opts) {
			pagesToAdd = aeroval.cfg.webdisp_opts.pages;
		}
		// hide other pages
		var pagesToHide: string[] = []
		for (const page in aeroval.config.pages) {
			if (!pagesToAdd.includes(page)) {
				pagesToHide.push(page);
			}
		}
	} else {
		// pages to be hidden
		var pagesToHide: string[] = [];
		if ('hide_pages' in aeroval.cfg.webdisp_opts) {
			pagesToHide = aeroval.cfg.webdisp_opts.hide_pages as string[];
		}
		// pages to be added
		var pagesToAdd: string[] = [];
		if ('add_pages' in aeroval.cfg.webdisp_opts) {
			pagesToAdd = aeroval.cfg.webdisp_opts.add_pages;
		}
	}

	// set display to default value defined in config/pages.json
	for (const element of document.querySelectorAll('#navbarCollapse>*>*')) {
		var a = element.children[0] as HTMLLinkElement;
		if (element instanceof HTMLElement) {
			var page = a.id.split('-link')[0]
			element.style.display = aeroval.config.pages[page].display
			a.classList.remove('disabled')
		}
	}

	// remove pages from header
	for (const element of document.querySelectorAll('#navbarCollapse>*>*')) {
		const a = element.children[0] as HTMLLinkElement;
		if (typeof a != 'undefined') {
			for (const pageToHide of pagesToHide) {
				if ((element instanceof HTMLElement) && (a.href?.includes(pageToHide))) {
					//element.style.display = 'none';
					a.classList.add('disabled')
				}
			}
		}
	}

	// redirection
	const userDir = window.location.pathname.split('/pages')[0];
	const filteredPages = Object.keys(aeroval.config.pages).filter(page => !pagesToHide.includes(page));

	// if currently in an hidden page, redirect
	for (const pageToHide of pagesToHide) {
		if (window.location.href.includes(pageToHide)) {
			alert('The page does not exist for this experiment. You will be redirected to the first page available.');
			window.location.pathname = `${userDir}/pages/${filteredPages[0]}/`;
		}
	}
	// if currently in a reports page and reports are not added, redirect
	if ((window.location.href.includes('reports')) && !(pagesToAdd.some(str => str.includes('reports')))) {
		alert('The page does not exist for this experiment. You will be redirected to the first page available.');
		window.location.pathname = `${userDir}/pages/${filteredPages[0]}/`;
	}

	// check pages to be added: all pages need to be predefined in config pages, then change display from none to block
	const ul = document.querySelector('ul.navbar-nav');
	const queryStrings = new URL(window.location.href).search;
	if (ul) {
		for (let pageToAdd of pagesToAdd) {
			if (pageToAdd.includes('reports')) {
				pageToAdd = 'reports'
			}
			var pageLink = document.getElementById(`${pageToAdd}-link`)
			if (pageLink instanceof HTMLAnchorElement) {
				pageLink.href = `${userDir}/pages/${pageToAdd}/${queryStrings}`;
				var pageList = pageLink.closest('li')
				if (pageList instanceof HTMLElement) {
					pageList.style.display = 'block';
				}
			}
		}
	}
	highlightCurrentPage();
}

export function loadRegStat(updateTS: boolean, updateScat: boolean): void {
	const url = `${window.API_ROOT}/regional_statistics/${aeroval.project}/${aeroval.experiment.name}/${aeroval.frequency}/${aeroval.parameter.dir}/${aeroval.observation}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.data.reg_stat = data as RegStatData;
			// keep this into memory for easier further access
			if (window.location.pathname.includes('/evaluation')) {
				plotRegionalStatisticsSingleModel(data, updateTS, updateScat);
			} else if (window.location.pathname.includes('/intercomp')) {
				plotRegionalStatisticsMultiModel(data, updateTS, updateScat);
			} else {
				utils.todo();
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

async function getMapData(): Promise<MapData | undefined> {
	const keyTime = `${aeroval.time}-${aeroval.season}`.replace('-All', '-all')
	const url = `${window.API_ROOT}/map/${aeroval.project}/${aeroval.experiment.name}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}/${aeroval.model.dir}/${aeroval.model.var}/${aeroval.time.replaceAll('/', '')}/${aeroval.frequency}/${keyTime}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	try {
		const response = await fetch(url, {
			cache: 'no-cache'
		});
		if (response.ok) {
			return response.json()
		}
	} catch (error) {
		console.warn(`mapData: ${error}`);
		return undefined;
	}
}

export async function map(regStat = true, updateTS = true, updateScat = true) {
	document.querySelector('#map')?.classList.add('loading');

	const mapData = await getMapData();
	if (!mapData) return;

	aeroval.data.map = mapData;
	plotMap(aeroval.data.map);
	if (regStat) {
		loadRegStat(updateTS, updateScat);
	} else {
		const mapElement = document.querySelector('#map');
		if (mapElement instanceof HTMLElement) {
			mapElement.classList.remove('loading');
		}
	}
}

async function getTSData(): Promise<TSData | undefined> {
	const url = `${window.API_ROOT}/ts/${aeroval.project}/${aeroval.experiment.name}/${aeroval.station}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	try {
		const response = await fetch(url, {
			cache: 'no-cache'
		});
		if (response.ok) {
			return response.json()
		}
	} catch (error) {
		console.warn(`tsData: ${error}`);
		return undefined;
	}
}

export async function ts(updateTS = true, updateScat = true) {
	const tsElement = document.querySelector(`#${utils.getTSId()}`);
	if (tsElement instanceof HTMLElement) {
		tsElement.classList.remove('noStat');
		tsElement.classList.add('loading');
	}

	const TSData = await getTSData();
	if (TSData) {
		aeroval.data.ts = TSData;
		getExtremeDates(updateTS, updateScat);
		document.querySelector(`#${utils.getTSId()}`)?.classList.remove('loading');
	}
	else {
		document.querySelector(`#${utils.getTSId()}`)?.classList.add('noStat');
		return
	}
}

export function getExtremeDates(updateTS = false, updateScat = false) {
	// initialize aeroval.dateMin and aeroval.dateMax with time series
	var yMin: number | null;
	var yMax: number | null;

	if (aeroval.time.includes('-')) {
		var date_from = aeroval.time.split('-')[0];
		var date_to = aeroval.time.split('-')[1];
		// if the date has a date format (here we assume that the dates (from and to) have the same format)

		if (date_from.includes('/')) {
			aeroval.dateMin = new Date(date_from);
			aeroval.dateMax = new Date(date_to);
			yMin = null;
			yMax = null;
			// else if an integer, this is a single year
		} else {
			var y0 = parseInt(date_from);
			var y1 = parseInt(date_to);
			yMin = Math.min(y0, y1);
			yMax = Math.max(y0, y1);
		}
		//if single date
	} else {
		// if date
		if (aeroval.time.includes('/')) {
			aeroval.dateMin = new Date(aeroval.time);
			var dateMax = new Date(aeroval.time);
			dateMax.setDate(dateMax.getDate() + 1);
			aeroval.dateMax = dateMax;
			yMin = null;
			yMax = null;
		} else {
			// if year
			yMin = parseInt(aeroval.time);
			yMax = yMin;
		}
	}

	// if only years, then set first year to first day and last year to last day
	if (yMin != null && yMax != null) {
		if (aeroval.season == 'DJF') {
			aeroval.dateMin = new Date(yMin - 1, 11, 1);
		} else {
			aeroval.dateMin = new Date(yMin, 0, 1);
		}
		aeroval.dateMax = new Date(yMax, 11, 31);
	}

	if (updateTS) {
		if (window.location.pathname.includes('/evaluation') && aeroval.data.ts) {
			plotTimeSeriesSingleModel(aeroval.data.ts, 'evaluation-ts', false, 'regular');
		} else if (window.location.pathname.includes('/intercomp') && aeroval.data.ts) {
			plotTimeSeriesMultiModel(aeroval.data.ts, 'intercomp-ts', false);
		} else if (window.location.pathname.includes('/overall') && aeroval.data.hm_ts) {
			plotTimeSeriesStatistics(aeroval.data.hm_ts, aeroval.parameter.dir, aeroval.observation, aeroval.layer, aeroval.statistic.dir, 'overall-ts');
		} else {
			utils.todo();
		}
	}
	if (updateScat) {
		if ('hide_charts' in aeroval.cfg.webdisp_opts && aeroval.cfg.webdisp_opts.hide_charts.includes('scatterplot')) {
			return;
		} else {
			if (window.location.pathname.includes('/evaluation') || window.location.pathname.includes('/intercomp')) {
				var layers = Object.keys(aeroval.menu[aeroval.parameter.dir]['obs'][aeroval.observation]);
				// if more than 1 layer, enable the profile visualization
				if (layers.length > 1 && aeroval.layer !== 'Surface') {
					((document.querySelector('#scatCarousel') as any)['bootstrapCarouselInstance'] as bootstrap.Carousel).to(1);
					profile();
				} else {
					((document.querySelector('#scatCarousel') as any)['bootstrapCarouselInstance'] as bootstrap.Carousel).to(0);
					if (window.location.pathname.includes('/evaluation')) {
						plotScatterPlotSingleModel(aeroval.tsType);
					} else if (window.location.pathname.includes('/intercomp')) {
						plotScatterPlotMultiModel('scat');
					} else {
						utils.todo();
					}
				}
			} else {
				utils.todo();
			}
		}
	}
}

export function profile() {
	const url = `${window.API_ROOT}/profiles/${aeroval.project}/${aeroval.experiment.name}/${aeroval.station}/${aeroval.observation}/${aeroval.parameter.dir}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			plotProfile(data);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
		});
}

export function tsWeekly(updateTS = true, updateScat = true) {
	document.querySelector(`#${utils.getTSId()}`)?.classList.remove('noStat');
	document.querySelector(`#${utils.getTSId()}`)?.classList.add('loading');
	const url = `${window.API_ROOT}/ts_weekly/${aeroval.project}/${aeroval.experiment.name}/${aeroval.station}/${aeroval.observation}/${aeroval.parameter.dir}/${aeroval.layer}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.data.ts_weekly = data;
			if (updateTS) {
				if (window.location.pathname.includes('/evaluation')) {
					plotTimeSeriesSingleModel(data, 'evaluation-ts', true, 'weekly');
				} else if (window.location.pathname.includes('/intercomp')) {
					plotTimeSeriesMultiModel(data, 'intercomp-ts', true);
				} else {
					utils.todo();
				}
				document.querySelector(`#${utils.getTSId()}`)?.classList.remove('loading');
			} else if (updateScat) {
				if ('hide_charts' in aeroval.cfg.webdisp_opts && aeroval.cfg.webdisp_opts.hide_charts.includes('scatterplot')) {
					return;
				} else {
					if (window.location.pathname.includes('/evaluation')) {
						plotScatterPlotSingleModel('weekly');
					} else if (window.location.pathname.includes('/intercomp')) {
						plotScatterPlotMultiModel('weekly');
					} else {
						utils.todo();
					}
				}
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			document.querySelector(`#${utils.getTSId()}`)?.classList.add('noStat');
		});
}

//creates mapOptions
export function mapOptions() {
	// in order to plot the map, we need to load first the map_zoom definitions, if it does not exist yet
	if (!('map_config' in window)) {
		fetch('../../config/map.json')
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				aeroval.config.map = data;
				setMapMenu();
			})
			.catch((error) => {
				console.error('There was a problem with the fetch operation:', error);
			});
	} else {
		// if the map_zoom is already loaded, then just update the mapMenu
		setMapMenu();
	}
}

export function setMapMenu() {
	const element = document.getElementById('mapMenu');
	if (element) {
		//europe_projects defined in export functions.js
		var radius = 3.5;
		var country_opacity = 8;
		var marker_symbol = 'circle';
		if (aeroval.config.map && aeroval.cfg.webdisp_opts.map_zoom in aeroval.config.map) {
			radius = aeroval.config.map[aeroval.cfg.webdisp_opts.map_zoom].radius;
			country_opacity = aeroval.config.map[aeroval.cfg.webdisp_opts.map_zoom].country_opacity;
			marker_symbol = aeroval.config.map[aeroval.cfg.webdisp_opts.map_zoom].marker_symbol;
		}

		var h = `<div id='mapMenuContent' class='container'>
			<div class='mapMenuSubmenu'>
			<span class='mapMenuTitle'>Settings</span>
			<div id='mapOptionsContent'>`

		//countries transparency
		h += `<span class='mapMenuSubmenu' title='Countries transparency'>Countries transparency</span>
			<div class='slidecontainer'>
			<input type='range' min='0' max='1' step='.1' value='${country_opacity}' class='slider' range='min' id='opacityRange' style='width: 100%'>
			</div>`
		//points radius
		h += `<span class='mapMenuSubmenu' title='Stations size'>Stations size</span>
			<div class='slidecontainer'>
			<input type='range' min='2' max='10' step='1' value='${radius}' class='slider' range='min' id='stationsRadius' style='width: 100%'>
			</div>`
		//marker symbol
		var markers = ['circle', 'square'];
		h += `<span class='mapMenuSubmenu' title='Stations size'>Stations symbol</span>
			<div id="markerSymbol" class="btn-group" role="group" aria-label="Basic radio toggle button group">`
		for (let marker of markers) {
			if (marker_symbol == marker) {
				h += `<input type="radio" class="btn-check" name="btnradio" value="${marker}" id="markerSymbol${utils.capitalize(
					marker
				)}" autocomplete="off" checked>`;
			} else {
				h += `<input type="radio" class="btn-check" name="btnradio" value="${marker}" id="markerSymbol${utils.capitalize(
					marker
				)}" autocomplete="off">`;
			}
			h += `<label class="btn btn-${aeroval.settings.theme}" for="markerSymbol${utils.capitalize(marker)}">${marker}</label>`;
		}
		h += `</div>
			</div>
			</div>
			</div>
			</div>`
		const mapMenu = document.getElementById('mapMenu');
		if (mapMenu instanceof HTMLElement) {
			mapMenu.innerHTML = h;
		}

		/*sliders*/
		document.querySelector('#opacityRange')?.addEventListener('input', function (event) {
			event.stopImmediatePropagation();
			if (aeroval.data.map) {
				plotMap(aeroval.data.map);
			}
		});

		document.querySelector('#stationsRadius')?.addEventListener('input', function (event) {
			event.stopImmediatePropagation();
			if (aeroval.data.map) {
				plotMap(aeroval.data.map);
			}
		});

		document.querySelector('#markerSymbol')?.addEventListener('input', function (event) {
			event.stopImmediatePropagation();
			if (aeroval.data.map) {
				plotMap(aeroval.data.map);
			}
		});
	}
}

export function updateURLs(which: string, what: string): void {
	// prevents browser from storing history with each change:
	var url = new URL(window.location.href);
	url.searchParams.set(which, what);
	history.replaceState(null, 'null', url);

	// set global variable
	const headerListChildren = document.querySelectorAll('#header-list > *');
	const headerDivs: string[] = [];

	headerListChildren.forEach((child) => {
		headerDivs.push(child.children[0].id);
	});

	for (let header_div of headerDivs) {
		var header_link = document.getElementById(header_div) as HTMLAnchorElement;
		if (header_link) {
			var url = new URL(header_link.href);
			url.searchParams.set(which, what);
			header_link.href = url.toString();
		}
	}
}

export function fillExpMenu(exps: string[], update_pages: boolean, update_containers: boolean, update_tabs: boolean, map_options: boolean, get_settings: boolean, show_popup: boolean): void {
	var which = 'experiment';
	var what = exps;
	var whichName = 'Experiments';
	var logo = `${flask.html}`;

	var h = `${logo}
		<span id='menuSelect-${which}' style='float: none; margin-bottom: 10px;' title='Experiments provide diverse models and observations datasets. More details can be found in the Information page.'>${whichName}</span>
		<div id='list-experiment' class='btn-group' role='group'>`
	for (let i in what) {
		const display = aeroval.experiments[what[i]].public ? 'initial' : 'none';
		if (what[i] == aeroval.experiment.name) {
			h += `<input type='radio' class='btn-check' name='options' id='exp-${i}' autocomplete='off' checked>`;
		} else {
			h += `<input type='radio' class='btn-check' name='options' id='exp-${i}' autocomplete='off'>`;
		}
		h += `<label class='btn btn-sm btn-${aeroval.settings.theme}' for='exp-${i}' style='display: ${display}'>${what[i]}</label>`;
	}
	h += '</div>';
	const thisMenu = document.getElementById('menu-' + which);
	if (thisMenu instanceof HTMLElement) {
		thisMenu.innerHTML = h;
	}

	//event on bootstrap buttons
	document.querySelectorAll('#list-experiment label').forEach((label) => {
		label.addEventListener('click', () => {
			const thisLabel = label as HTMLInputElement;
			aeroval.experiment.name = thisLabel.textContent || thisLabel.innerText;
			const pathname = window.location.pathname;
			if (pathname.includes('/evaluation')) {
				evalUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else if (pathname.includes('/intercomp')) {
				interUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else if (pathname.includes('/overall')) {
				overUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else if (pathname.includes('/infos')) {
				infoUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else if (pathname.includes('/maps')) {
				mapsUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else if (pathname.includes('/reports')) {
				repoUpdate(which, thisLabel.textContent || thisLabel.innerText);
			} else {
				utils.todo();
			}
			showExperiment(update_pages, update_containers, update_tabs, map_options, get_settings, show_popup);
		});
	});
	showExperiment(update_pages, update_containers, update_tabs, map_options, get_settings, show_popup);
}

async function getConfigModificationTime() {
	const url = `${window.API_ROOT}/mtime_config/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	const response = await fetch(url, {
		cache: 'no-cache'
	});
	if (response.ok) {
		return response.json()
	}
}

export async function showExperiment(update_pages: boolean, update_containers: boolean, update_tabs: boolean, map_options: boolean, get_settings: boolean, show_popup: boolean): Promise<void> {

	// get modification date
	const configModificationDate = await getConfigModificationTime()

	//display information
	const url = `${window.API_ROOT}/cfg/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			aeroval.cfg = data;
			const pyaerocomVersion = data?.exp_info?.pyaerocom_version
			var h = '';

			h += (!data.exp_info.exp_name || data.exp_info.exp_name.length == 0)
				? '<b>No title available</b>'
				: `<b>${data.exp_info.exp_name}</b>`;

			h += "<div style='font-size: smaller'>";
			h += (!data.exp_info.exp_descr || data.exp_info.exp_descr.length == 0)
				? '<p>No summary available</p>'
				: `<div id='expSummary' class='container'>
						<p class='collapse' id='collapseExample' aria-expanded='false'>${data.exp_info.exp_descr}</p>
						<a role='button' class='collapsed' data-bs-toggle='collapse' href='#collapseExample' aria-expanded='false' aria-controls='collapseExample'></a>
						</div>`
			h += '</div>';

			h += (!data.exp_info.exp_pi || data.exp_info.exp_pi.length == 0)
				? `<div style='font-size: small' title='experiment PI'><span class='me-2'>${userAltSlash.html}</span></i>Not available</div>`
				: `<div style='font-size: small' title='experiment PI'><span class='me-2'>${user.html}</span></i>${data.exp_info.exp_pi}</div>`;

			h += configModificationDate
				? `<div style='font-size: small' title='last modification date'><span class='me-2'>${clockRotateLeft.html}</span></i>${new Date(configModificationDate).toJSON().split('T')[0]}</div>`
				: `<div style='font-size: small' title='last modification date'><span class='me-2'>${clockRotateLeft.html}</span></i>Not available</div>`;

			h += pyaerocomVersion
				? `<div style='font-size: small' title='pyaerocom version'><span class='me-2'>${python.html}</span></i>${pyaerocomVersion}</div>`
				: `<div style='font-size: small' title='pyaerocom version'><span class='me-2'>${python.html}</span></i>Not available</div>`;

			const experimentDescription = document.getElementById('description-experiment');
			if (experimentDescription instanceof HTMLElement) {
				experimentDescription.innerHTML = h;
			}

			// add data policy
			var h = '<div id="data-policy">';
			h += `${database.html}<span>Data Policy</span>
				<p style="font-size: smaller">
				AeroVal makes use of several observation networks. Before using the data, please check the specific network data policies on the 
				<a href="/infos/?project=${aeroval.project}&experiment=${aeroval.experiment.name}">Information</a> page.</p>
				</div>`
			const dataPolicy = document.getElementById('data-policy');
			if (dataPolicy instanceof HTMLElement) {
				dataPolicy.innerHTML = h;
			}

			// additionnal actions
			if (update_pages) {
				updatePages();
			}
			if (update_tabs) {
				updateTabs();
			}
			if (update_containers) {
				updateContainers();
			}
			if (map_options) {
				mapOptions();
			}
			if (get_settings) {
				getExpSettings();
			}
			if (show_popup) {
				showPopup();
			}
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			const experimentDescription = document.getElementById('description-experiment');
			if (experimentDescription instanceof HTMLElement) {
				experimentDescription.innerHTML = '<p>Experiment description missing</p>';
			}
		});
}

function orderParametersByType(parameters: any): any {
	// Initialize orderedParameters as a copy of the original parameters
	const orderedParameters: any = {};

	// Get unique types from parameters.type
	const uniqueTypes = Array.from(new Set(parameters.type));

	// Initialize arrays in orderedParameters for each field (e.g., name, title, type)
	for (const paramField in parameters) {
		orderedParameters[paramField] = [];
	}

	// Group the parameters by type and store them in order
	for (const uniqueType of uniqueTypes) {
		for (let i = 0; i < parameters.type.length; i++) {
			if (parameters.type[i] === uniqueType) {
				// For each field (e.g., name, title, type), push the corresponding value
				for (const paramField in parameters) {
					orderedParameters[paramField].push(parameters[paramField][i]);
				}
			}
		}
	}

	return orderedParameters;
}

function orderParametersByCfgOrder(parameters: any): any {
	const orderedParameters = {
		name: [] as string[],
		title: [] as string[],
		type: [] as string[]
	};
	// Preprocess cfgOrder for faster lookups and preserve the order
	const cfgOrder = aeroval.cfg.webdisp_opts.var_order_menu;

	// Iterate over cfgOrder to maintain the specified order
	for (const cfgParameter of cfgOrder) {
		for (let i = 0; i < parameters.title.length; i++) {
			const titleBase = parameters.title[i].split(':')[0]; // Extract base of title

			if (cfgParameter === titleBase) {
				// Push parameters in the correct order
				orderedParameters.name.push(parameters.name[i]);
				orderedParameters.title.push(parameters.title[i]);
				orderedParameters.type.push(parameters.type[i]);
			}
		}
	}
	return orderedParameters;
}

export function fillMenu(menu: MenuEntry): void {
	//position of dropdown menu
	var h = `<div class='dropdown'>
		<button class='btn dropdown-toggle btn-${aeroval.settings.theme}' type='button' data-bs-toggle='dropdown' data-bs-auto-close='outside' id='dropdownMenuButton-${menu.which}' aria-expanded='false'>
		${menu.logo}
		<span id='menuSelect-${menu.which}' class='menuSelect'>${menu.name}</span>
		</button>`

	h += `<ul class='dropdown-menu' id='list-${menu.which}'>`;

	var previousType: string | undefined = undefined;
	for (let i: number = 0; i < menu.what.length; i++) {
		if (typeof menu.type != 'undefined' && menu.type.length > 0) {
			//divide into categories
			if (i == 0 || menu.type[i] != previousType) {
				h += `<li class='dropdown-header subtitle'><span>${menu.type[i]}</span></li>`;
			}
			previousType = menu.type[i];
		}

		// add dropdown-item
		var what: string | undefined = menu.what[i];
		if (menu.which == 'parameter') {
			what = getDir(menu.which, menu.what[i]);
		} else if (menu.which == 'statistic' || menu.which == 'meanAndStatistic') {
			what = getDir(menu.which, menu.what[i]);
		}
		var id = `${menu.which}:${what}`;
		if ((menu.layers) && (menu.layers.length > 1)) {
			for (const layer of menu.layers) {
				const id = `${'layer'}:${layer}&${menu.which}:${what}`
				h += `<li><a class="dropdown-item dropdown-togg subdropdown" data-bs-toggle="dropdown" id=${id} href="#">${menu.what[i]} (${layer})</a></li>`
			}
		} else {
			h += `<li><a class='dropdown-item' id='${id}' href='#' title='${menu?.title[i]}'>${menu.what[i]}</a></li>`;
		}
	}

	// add subMenus if exist
	if (menu.subMenus) {
		for (const subMenu of menu.subMenus) {
			const subKeys = Object.keys(subMenu);
			// disable season subMenu if stats are yearly
			for (const subKey of subKeys) {
				// write Statistics instead of stats
				if (subKey === 'frequency') {
					h += "<li class='dropdown-header subtitle'><span>Statistics</span></li>";
				} else {
					h += `<li class='dropdown-header subtitle'><span>${utils.capitalize(subKey)}</span></li>`;
				}
				h += `<div id='subMenu-${subKey}' class='container menuContainer'>`;
				h += "<div class='btn-group btn-group-toggle' data-toggle='buttons'>";
				const buttons = subMenu[subKey];
				for (const j in buttons) {
					const button = buttons[j];
					if (aeroval[subKey] == subMenu[subKey][j]) {
						h += `<input type='radio' class='btn-check' name='${subKey}Radios' autocomplete='off' id='${button}' value='${button}' checked>`;
					} else {
						h += `<input type='radio' class='btn-check' name='${subKey}Radios' autocomplete='off' id='${button}' value='${button}'>`;
					}
					h += `<label class='btn btn-sm btn-${aeroval.settings.theme}' for='${button}' style='width: ${100 / buttons.length}%'>${button}</label>`;
				}
				h += `</div>
					</div>`
			}
		}
	}

	// add ts type for weekly visualization
	if (
		menu.which == 'time' &&
		aeroval.cfg.statistics_opts.use_diurnal &&
		aeroval.cfg.time_cfg.freqs.includes('hourly') &&
		(aeroval.frequency == 'daily' || aeroval.frequency == 'hourly') &&
		window.location.pathname.includes('/evaluation')
	) {
		var tsTypes: string[] = ['regular', 'weekly'];
		var tsTypesTitles: string[] = ['regular', 'diurnal (week)']
		var subKey = 'tsType';
		h += `<li class='dropdown-header subtitle'><span>Time series type</span></li>
			<div id='subMenu-${subKey}' class='container menuContainter'>
			<div class='btn-group btn-group-toggle' data-toggle='buttons'>`
		for (let i in tsTypes) {
			if (aeroval.tsType == tsTypes[i]) {
				h += `<input type='radio' class='btn-check' name='${subKey}Radios' autocomplete='off' id='tsType-${tsTypes[i]}' value='${tsTypes[i]}' checked>`;
			} else {
				h += `<input type='radio' class='btn-check' name='${subKey}Radios' autocomplete='off' id='tsType-${tsTypes[i]}' value='${tsTypes[i]}'>`;
			}
			h += `<label class='btn btn-sm btn-${aeroval.settings.theme}' for='tsType-${tsTypes[i]}' style='width: ${100 / tsTypes.length}%'>${tsTypesTitles[i]}</label>`;
		}
		h += `</div>
			</div>`

		//add frequency if tsType is weekly
		if (aeroval.tsType == 'weekly') {
			aeroval.granularity = 'year';
		}
	}
	//add custom region
	if (menu.which == 'regionAndCustom') {
		h += `<div id='customRegionContainer'>
			<span class='mapMenuTitle' style='font-weight: bold;'>Custom Region</span>
			<span style='display: inline-flex'>Draw your own custom region</span>
			<div id='customRegionButton'></div>
			</div>`
	}
	h += `</ul>
		</li>
		<div class='showMenu' id='show-${menu.which}'></div>`;

	var menuElement = document.getElementById(`menu-${menu.which}`);
	if (menuElement instanceof HTMLElement) {
		menuElement.innerHTML = h;
	}

	//event on bootstrap menus
	const elements = document.querySelectorAll(`#list-${menu.which} a`);
	elements.forEach((element) => {
		element.addEventListener('click', function () {
			selectMenu(element.id);
		});
	});

	//show selected option
	showMenu(menu.which, menu.show);
}

export function selectMenu(id: string) {
	var wait = id.includes('&');
	for (let subId of id.split('&')) {
		var which = subId.split(':')[0];
		var what = subId.split(':')[1];

		if (which == 'parameter') {
			aeroval.parameter.name = what;
		}
		if (which == 'model') {
			aeroval.model.name = what;
		}
		if (which == 'observation') {
			aeroval.observation = what;
		}
		if (which == 'layer') {
			aeroval.layer = what;
		}

		if (window.location.pathname.includes('/evaluation')) {
			evalUpdate(which.split('@')[0], what, wait);
		} else if (window.location.pathname.includes('/intercomp')) {
			interUpdate(which.split('@')[0], what, wait);
		} else if (window.location.pathname.includes('/overall')) {
			overUpdate(which.split('@')[0], what);
		} else if (window.location.pathname.includes('/maps')) {
			mapsUpdate(which.split('@')[0], what);
		} else if (window.location.pathname.includes('reports')) {
			repoUpdate(which.split('@')[0], what);
		} else {
			utils.todo();
		}
	}
}

export function fixSubmenuClasses(menu: MenuEntry) {
	if (menu.subMenus) {
		for (var subMenu of menu.subMenus) {
			if (menu.which == 'time') {
				var subKeys = ['season', 'frequencies', 'tsType'].concat(Object.keys(subMenu));
			} else {
				var subKeys = Object.keys(subMenu);
			}
			for (let subKey of subKeys) {
				//events on radio buttons
				const inputs = document.querySelectorAll(`input[name="${subKey}Radios"]`);
				inputs.forEach((input) => {
					input.addEventListener('change', (event) => {
						const target = event.target as HTMLInputElement;
						if (window.location.pathname.includes('/evaluation')) {
							evalUpdate(target.name.split('Radios')[0], target.value);
						} else if (window.location.pathname.includes('/intercomp')) {
							interUpdate(target.name.split('Radios')[0], target.value);
						} else if (window.location.pathname.includes('/overall')) {
							overUpdate(target.name.split('Radios')[0], target.value);
						} else if (window.location.pathname.includes('reports')) {
							repoUpdate(target.name.split('Radios')[0], target.value);
						} else {
							utils.todo();
						}
						event.stopImmediatePropagation();
					});
				});
				const subMenu = document.querySelector(`#subMenu-${subKey} > div`);
				if (subMenu instanceof HTMLElement) {
					subMenu.classList.add('btn-group', 'btn-group-toggle');
					subMenu.classList.remove('dropdown-item');
					subMenu.style.width = '100%';
				}
			}
		}
	}
	//custom region
	const customRegionContainer = document.querySelector('#customRegionContainer>*');
	if (customRegionContainer) {
		customRegionContainer.classList.remove('dropdown-item');
	}
}

export function showMenu(which: string, what: string, what2?: string): void {
	if (typeof what2 === 'undefined') {
		what2 = what;
	}
	let compTxt: string = what2;

	if (which === 'parameter' && aeroval.layer !== 'Column' && aeroval.layer !== 'Surface') {
		compTxt += ` (${aeroval.layer})`;
	}

	//get list of items from which
	const list = document.getElementById('list-' + which)?.children;
	if (list) {
		Array.from(list).forEach((element: Element) => {
			if (element.className !== 'dropdown-header subtitle') {
				let txt: string | null = (element.children[0] as HTMLElement)?.textContent;
				if (typeof txt === 'string' && utils.capitalize(txt) === utils.capitalize(compTxt)) {
					(element.children[0] as HTMLElement)?.classList.add('select');
				} else {
					(element.children[0] as HTMLElement)?.classList.remove('select');
				}
			}
		});
	}

	// specific case for time selection: show season if not all
	if (which.includes('time') && aeroval.season !== 'All') {
		what += ` (${aeroval.season})`;
	}
	if (which === 'parameter' && aeroval.layer !== 'Column' && aeroval.layer !== 'Surface') {
		what += ` (${aeroval.layer})`;
	}

	const menuElement = document.getElementById(`menuSelect-${which}`);
	if (menuElement instanceof HTMLElement) {
		menuElement.innerHTML = what;
	}

	//remove dropdown-item classes in subMenus
	fixSubmenuClasses(aeroval.buttons[which]);

	// check if seasons should be enabled or not (yearly)
	if (which.includes('time')) {
		checkSeasonMenu();
	}
}

export function checkSeasonMenu() {
	if (aeroval.frequency === 'yearly') {
		// disable seasons
		const seasonBtn = document.querySelector('#subMenu-season > .btn-group-toggle > .btn');
		if (seasonBtn) {
			seasonBtn.classList.add('disabled');
		}
		// add alert
		const noSeasonAlert = document.createElement('div');
		noSeasonAlert.classList.add('alert', `alert-${aeroval.settings.theme}`);
		noSeasonAlert.setAttribute('role', 'alert');
		noSeasonAlert.id = 'no-season';
		noSeasonAlert.textContent = 'Seasons selection is not available for yearly statistics';
		const subMenuSeason = document.querySelector('#subMenu-season');
		if (subMenuSeason) {
			subMenuSeason.parentNode?.insertBefore(noSeasonAlert, subMenuSeason);
		}
	} else {
		// enable seasons
		const seasonBtn = document.querySelector('#subMenu-season > .btn-group-toggle  > .btn');
		if (seasonBtn) {
			seasonBtn.classList.remove('disabled');
		}
		// remove alert
		const noSeasonAlert = document.querySelector('#no-season');
		if (noSeasonAlert) {
			noSeasonAlert.remove();
		}
	}
}

export function getDir(which: string, what: string): string | undefined {
	//retrieve the dir name for given name in which field
	var dir: string | undefined = undefined;
	if (which === 'parameter') {
		for (let item in aeroval.menu) {
			if (aeroval.menu[item]['name'] == what) {
				dir = item;
			}
		}
	}
	if (which === 'statistic' || which === 'meanAndStatistic') {
		for (let statistic in aeroval.statistics) {
			if (aeroval.statistics[statistic]['name'] == what) {
				dir = statistic;
			}
		}
		if (what === 'Mean') {
			dir = 'data_mean';
		}
	}
	return dir;
}

export function updateContainers() {
	//if no scatterplot, hide scat and strecth time series
	const containerTS = document.querySelector('#containerTS');
	const containerScat = document.querySelector('#containerScat');

	if ('hide_charts' in aeroval.cfg.webdisp_opts) {
		if (aeroval.cfg.webdisp_opts.hide_charts.includes('scatterplot')) {
			if (containerTS instanceof HTMLElement) {
				containerTS.classList.remove('col-md-6', 'col-sm-6');
				containerTS.classList.add('col-md-12', 'col-sm-12');
			}
			if (containerScat instanceof HTMLElement) {
				containerScat.style.display = 'none';
			}
		} else {
			if (containerTS instanceof HTMLElement) {
				containerTS.classList.remove('col-md-12', 'col-sm-12');
				containerTS.classList.add('col-md-6', 'col-sm-6');
			}
			if (containerScat instanceof HTMLElement) {
				containerScat.style.display = 'initial';
			}
		}
	} else {
		if (containerTS instanceof HTMLElement) {
			containerTS.classList.remove('col-md-12', 'col-sm-12');
			containerTS.classList.add('col-md-6', 'col-sm-6');
		}
		if (containerScat instanceof HTMLElement) {
			containerScat.style.display = 'initial';
		}
	}
}

export function updateTabs() {
	// show/hide target plot
	if ('statistics_opts' in aeroval.cfg) {
		const targetChartElement = document.querySelector('#nav-target-tab');
		if (targetChartElement instanceof HTMLElement) {
			if (aeroval.cfg.statistics_opts.use_fairmode === true) {
				targetChartElement.style.display = 'initial';
			} else {
				targetChartElement.style.display = 'none';
			}
		}

		const medianChartElement = document.querySelector('#nav-median-tab');
		if (medianChartElement instanceof HTMLElement) {
			if (aeroval.cfg.statistics_opts.forecast_evaluation === true) {
				medianChartElement.style.display = 'initial';
			} else {
				medianChartElement.style.display = 'none';
			}
		}
	} else {
		const targetChartElement = document.querySelector('#nav-target-tab');
		if (targetChartElement instanceof HTMLElement) {
			targetChartElement.style.display = 'none';
		}
		const medianChartElement = document.querySelector('#nav-median-tab');
		if (medianChartElement instanceof HTMLElement) {
			medianChartElement.style.display = 'none';
		}
	}
}

/*some global variables*/
aeroval.globalRegions = ['WORLD', 'ALL'];
aeroval.observationTypes = {
	'Ground Based': ['Aeronet', 'EBAS', 'EEA', 'AirNow', 'MarcoPolo'],
	Satellite: ['AATSR', 'MODIS', 'MERGED-FMI'],
};
aeroval.excludeModels = ['EMEP.u3all', 'EMEPcorrSS25']; //hidden in all pages: menus and charts
aeroval.excludeObs = [
	'EBAS-d:drysox_Surface',
	'EBAS-m:drysox_Surface',
	'EBAS-d:dryrdn_Surface',
	'EBAS-m:dryrdn_Surface',
	'EBAS-d:dryoxn_Surface',
	'EBAS-m:dryoxn_Surface',
	'EEA-rural:dryo3_Surface',
]; //only hidden in the heatmap
