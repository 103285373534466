import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
	faCopy,
	faDownload
} from '@fortawesome/free-solid-svg-icons';

// Add the imported icons to the library
library.add(faCopy, faDownload);

const copy = icon({ prefix: 'fas', iconName: 'copy' });
const download = icon({ prefix: 'fas', iconName: 'download' });

import { getExperiments, updateURLs, getQueryStrings } from './main';
import { initAPI, updateQueryString } from '../utils';

import { Tooltip } from 'bootstrap';

/*import interfaces and extend window variable*/
import { Aeroval } from '../types/global';
declare var aeroval: Aeroval;

declare global {
	interface Window {
		API_ROOT: string;
		DATA_PATH: string;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	if (window.location.pathname.includes('pages/infos')) {
		init();
	}
});

function init() {

	aeroval.tabs = ['datasets', 'statistics', 'experiment']

	console.log('initialize infos page');
	initAPI();
	// set some defaults
	getQueryStrings();
	updateURLs('project', aeroval.project);
	getExperiments(false, false);
	getConfig();

	// Set up actions on tab change
	document.querySelectorAll('button[data-bs-toggle="tab"]').forEach((tabButton) => {
		tabButton.addEventListener('shown.bs.tab', (e: Event) => {
			const target = e.target as HTMLElement;
			const tabId = target.id.split('-')[1];
			aeroval.tab = tabId;
			// delete aeroval zoom
			delete aeroval.zoom;
			// update header urls
			updateURLs('tab', aeroval.tab);
		});
	});

	// Show active tab
	const tabs = aeroval.tabs;
	const isTabInTabs = tabs.includes(aeroval.tab)
	const selectedTab = isTabInTabs ? aeroval.tab : tabs[0]

	tabs.forEach((tab: string) => {
		const tabButton = document.querySelector(`#nav-${tab}-tab`);
		const tabContent = document.querySelector(`#nav-${tab}`);
		if (tabButton && tabContent) {
			if (tab === selectedTab) {
				tabButton.classList.add('active');
				tabContent.classList.add('show', 'active');
				aeroval.tab = selectedTab
				updateQueryString('tab', selectedTab)
			} else {
				tabButton.classList.remove('active');
				tabContent.classList.remove('show', 'active');
			}
		}
	});


}

export function update(which: string, what: string) {
	getConfig();

	if (which == 'experiment') {
		updateQueryString(which, what);
		updateURLs(what, what);
	}
}

function getConfig() {
	const url = `${window.API_ROOT}/cfg/${aeroval.project}/${aeroval.experiment.name}${window.DATA_PATH && `?data_path=${window.DATA_PATH}`}`;
	fetch(url, {
		method: 'GET',
		headers: {
			'Accept': 'application/json'
		}
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			fillConfig(url, data);
		})
		.catch((error) => {
			console.error('There was a problem with the fetch operation:', error);
			const element = document.getElementById('infoCfg');
			if (element !== null) {
				element.innerHTML = '<p>Configuration file missing</p>';
			}
		});
}

function copyToClipboard(): void {
	const divElement = document.getElementById(`content-cfg`);

	if (!divElement) {
		return;
	}

	// Create a temporary textarea element
	const tempTextarea = document.createElement("textarea");
	tempTextarea.value = divElement.innerText || divElement.textContent || "";

	// Prevent scrolling to the bottom of the page
	tempTextarea.style.position = "absolute";
	tempTextarea.style.left = "-9999px";

	document.body.appendChild(tempTextarea);
	tempTextarea.select();

	try {
		navigator.clipboard.writeText(tempTextarea.value);

		// Show confirmation message
		const copyBtn = document.getElementById(`copy-btn`);
		if (copyBtn) {
			showBootstrapTooltip(copyBtn, "Copied!");
		}


	} catch (err) {
		console.error("Failed to copy content:", err);
	}

	// Clean up
	document.body.removeChild(tempTextarea);
}

function showBootstrapTooltip(targetElement: HTMLElement, message: string): void {
	// Set up Bootstrap tooltip attributes
	targetElement.setAttribute("data-bs-toggle", "tooltip");
	targetElement.setAttribute("data-bs-placement", "top");
	targetElement.setAttribute("title", message);

	// Initialize and show the tooltip
	const tooltip = new Tooltip(targetElement);
	tooltip.show();

	// Hide the tooltip after 2 seconds
	setTimeout(() => {
		tooltip.hide();
		tooltip.dispose();
		targetElement.removeAttribute("data-bs-toggle");
		targetElement.removeAttribute("data-bs-placement");
		targetElement.removeAttribute("title");
	}, 2000);
}


function fillConfig(url: string, data: any) {
	var h = 'Here is the configuration file used for processing the data visualized in the different pages.</br>';
	//project
	var keys = Object.keys(data);
	if (keys.length > 0) {
		h += `<pre id="content-cfg"></pre>
			<div class=btn-group style="margin: 10px 5px;">
			<a id="copy-btn" class="btn btn-sm btn-${aeroval.settings.theme}"><span class="mx-2">${copy.html}</span>Copy</a>
			<a id="dwnld-btn" class="btn btn-sm btn-${aeroval.settings.theme}" href="${url.split('?')[0]}?data_path=${window.DATA_PATH}"><span class="mx-2">${download.html}</span>Download</a>
			</div>
			<div class="collapse" id="collapseConfig">
			<div class="card card-body">
			<ul>`
		for (let key of keys) {
			var content = data[key];
			if (typeof content === 'object' && content !== null && !Array.isArray(content)) {
				const subKeys = Object.keys(content);
				h += `<li>${key}:    </li>`;
				h += '<ul>';
				for (const subKey of subKeys) {
					const subContent = content[subKey];
					if (typeof subContent === 'object' && subContent !== null && !Array.isArray(subContent)) {
						const subSubKeys = Object.keys(subContent);
						h += `<li>${subKey}:    </li>`;
						h += '<ul>';
						for (const subSubKey of subSubKeys) {
							const subSubContent = subContent[subSubKey];
							if (typeof subSubContent === 'object' && subSubContent !== null && !Array.isArray(subSubContent)) {
								const subSubSubKeys = Object.keys(subSubContent);
								h += `<li>${subSubKey}:    </li>`;
								h += '<ul>';
								for (const subSubSubKey of subSubSubKeys) {
									const subSubSubContent = subSubContent[subSubSubKey];
									if (typeof subSubSubKey !== 'undefined') {
										h += `<li>${subSubSubKey}:    <i>${printList(subSubSubContent)}</i></li>`;
									}
								}
								h += '</ul>';
							} else {
								h += `<li>${subSubKey}:    <i>${printList(subSubContent)}</i></li>`;
							}
						}
						h += '</ul>';
					} else {
						h += `<li>${subKey}:    <i>${printList(subContent)}</i></li>`;
					}
				}
				h += '</ul>';
			} else {
				h += `<li>${key}:    ${printList(content)}</li>`;
			}
		}
		h += '</ul>';
		h += '</div>';
		h += '</div>';
	} else {
		h += '<p>Configuration file missing</p>';
	}

	var element = document.getElementById('infoCfg');
	if (element !== null) {
		element.innerHTML = h;
	}

	var element = document.getElementById('content-cfg');
	if (element !== null) {
		element.innerHTML = syntaxHighlight(JSON.stringify(data, null, 2));
	}

	const copyBtn = document.getElementById("copy-btn");
	if (copyBtn) {
		copyBtn.addEventListener("click", copyToClipboard);
	}
}

function printList(content: string) {
	if (Array.isArray(content)) {
		return content.join(', ');
	} else {
		return content;
	}
}

/*
function fillTable(data: any) {
	var colNames = ['Name', 'ID', 'Variable', 'Obs ID', 'Mod-Variable', 'Obs-Freq', 'Mod-Freq', 'Colocation Frequency', 'Filter', 'Start', 'Stop'];
	var models = Object.keys(data);
	var h = '';
	h += 'List of output available in this experiment</br>';
	h += '<table class="table table-hover table-sm" style="margin-top: 10px">';
	//h += '<caption>Click on a row to display model information</caption>'
	h += '<thead>';
	for (var i = 0; i < colNames.length; i++) {
		h += '<th scope="col">' + colNames[i] + '</th>';
	}
	h += '<thead>';
	h += '<tbody>';
	for (var i = 0; i < models.length; i++) {
		h += '<tr>';
		var model = data[models[i]];
		//loop through all variables
		var vars = Object.keys(model.obs);
		//here, need to get the number of rows for all the variables
		var nRow = 0;
		for (let variable of vars) {
			var observationsArray = Object.keys(model.obs[variable]);
			for (let obs of observationsArray) {
				nRow++;
			}
		}
		h += '<td rowspan=' + nRow + ' data-collapsable-parent="' + i + '">' + models[i] + '</td>';
		h += '<td rowspan=' + nRow + ' data-collapsable-parent="' + i + '">' + model.id + '</td>';
		for (let variable of vars) {
			//here, loop over the different observation networks
			var observationsArray = Object.keys(model.obs[variable]);
			h += '<td rowspan=' + observationsArray.length + ' data-collapsable-child="' + i + '">' + variable + '</td>';
			for (let obs of observationsArray) {
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].obs_id + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].model_var + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].ts_type_src_obs + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].ts_type_src_mod + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].ts_type + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].filter_name + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].start_str + '</td>';
				h += '<td data-collapsable-child="' + i + '">' + model.obs[variable][obs].stop_str + '</td>';
				h += '</tr>';
			}
		}
		h += '</tr>';
	}
	h += '</tbody';
	h += '</table>';

	var element = document.getElementById('infoTable');
	if (element !== null) {
		element.innerHTML = h;
	}

	document.querySelectorAll('[data-collapsable-parent]').forEach(function (parent) {
		parent.addEventListener('click', function () {
			var child = parent.getAttribute('data-collapsable-parent');
			document.querySelectorAll('[data-collapsable-child="' + child + '"]').forEach(function (child) {
				if (child instanceof HTMLElement) {
					if (child.style.display === 'none') {
						child.style.display = 'block';
					} else {
						child.style.display = 'none';
					}
				}
			});
		});

		parent.classList.add('pointing');
	});
}
*/

function syntaxHighlight(json: any) {
	json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
	return json.replace(
		/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
		function (match: string) {
			var cls = 'number';
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = 'key';
				} else {
					cls = 'string';
				}
			} else if (/true|false/.test(match)) {
				cls = 'boolean';
			} else if (/null/.test(match)) {
				cls = 'null';
			}
			return '<span class="' + cls + '">' + match + '</span>';
		}
	);
}
