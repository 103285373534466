import * as Highcharts from 'highcharts';
import 'highcharts/modules/boost';
import '../../highcharts-options';

import * as utils from '../../../utils';
import { addGuidelines } from '../guidelines';

import { Aeroval } from '../../../types/global';
import { ScatData } from '../../../types/data';
declare var aeroval: Aeroval;

export function plotScatterPlotOverall(data: ScatData, preview = false, selectedPixel: any): void {
	var series_data = [];
	var series_data_boost = [];
	var turbo_mode = false;
	var too_many_points = false;

	// time selection
	const keyTime = utils.getKeyTime(aeroval.time, aeroval.season);

	// set min and max
	var minThreshold = 1e-9;
	var boostThreshold = 10000;

	var min = 1e9;
	var max = 1e-9;

	for (let station in data) {
		// push month per month
		for (let j in data[station][keyTime]['obs']) {
			var x = data[station][keyTime]['obs'][j];
			var y = data[station][keyTime]['mod'][j];
			var date = data[station][keyTime]['date'][j];

			if (x != null && y != null) {
				// filter negarive values for logarithmic axis
				if (x <= 0) {
					x = minThreshold;
				}
				if (y <= 0) {
					y = minThreshold;
				}

				// determine min and max
				if (x < min || y < min) {
					min = Math.min(x, y);
				}
				if (x > max || y > max) {
					max = Math.max(x, y);
				}

				// set up point name
				if (aeroval.cfg.time_cfg.main_freq == 'monthly') {
					var iMonth = new Date(date).getMonth() + 1;
					var name = `${station}-${new Date(date).getFullYear()}/${String(iMonth).padStart(2, '0')}`;
				} else if (aeroval.cfg.time_cfg.main_freq == 'daily') {
					name = `${station}-${new Date(date).toDateString()}`;
				} else if (aeroval.cfg.time_cfg.main_freq == 'hourly') {
					name = `${station}-${new Date(date).toISOString()}`;
				} else {
					name = station;
				}
				//console.log(data[station][keyTime])
				if (aeroval.globalRegions.includes(aeroval.region) || data[station]['region'].includes(aeroval.region)) {
					series_data.push({
						x,
						y,
						name,
					});
					// boost mode
					series_data_boost.push([x, y]);
				} else if (aeroval.region == Object.keys(aeroval.regions)[0]) {
					series_data.push({
						x,
						y,
						name,
					});
					//boost mode
					series_data_boost.push([x, y]);
				}
				if (preview && series_data.length > boostThreshold) {
					var too_many_points = true;
					break;
				}
			}
		}
		if (too_many_points) {
			break;
		}
	}

	//in preview mode, use arrays
	if (preview || series_data.length >= boostThreshold) {
		turbo_mode = true;
	}
	if (preview) {
		series_data = series_data_boost;
	}

	//markers color
	var color = 'rgba(128,128,128,0.3)';
	if (aeroval.settings.theme == 'dark') {
		color = 'rgba(200,200,200,0.3)';
	}

	// init axis
	var xAxis = {
		type: aeroval.axisType,
		gridLineWidth: 1,
		startOnTick: false,
		endOnTick: true,
		min: Math.max(0, utils.getLowerPowerOfTen(min) as number),
		max,
	} as any;
	var yAxis = {
		type: aeroval.axisType,
		gridLineWidth: 1,
		startOnTick: false,
		endOnTick: true,
		min: Math.max(0, utils.getLowerPowerOfTen(min) as number),
		max,
	} as any;

	var titleText: string | undefined = undefined;
	var subTitleText: string | undefined = undefined;
	var height: number | undefined = undefined;
	var width: number | undefined = undefined;
	var spacingLeft: number | undefined = undefined;
	var spacingBottom: number | undefined = undefined;

	if (preview) {
		var div = 'preview_scatterplot';
		height = 200;
		width = 200;
		spacingLeft = 0;
		spacingBottom = 10;
		xAxis.title = {
			text: 'Obs',
		};
		yAxis.title = {
			text: 'Mod',
		};
		var txt1 = '';
		var txt2 = '';
		var txt3 = '';
	} else {
		div = 'scatt';
		if (window.screen.width > 360) {
			height = 500;
		}

		//set title
		titleText = getTitle();

		subTitleText = `${aeroval.cfg.time_cfg.main_freq} data`;
		xAxis.title = {
			text: `${selectedPixel.obs}:${selectedPixel.var}`,
		};
		yAxis.title = {
			text: `${selectedPixel.mod}:${selectedPixel.var}`,
		};
		var txt0 = `${aeroval.frequency} statistics`;
		var txt1 = `N: ${aeroval.statsForSelectedPixel.n}`;
		var txt2 = `NMB: ${(aeroval.statsForSelectedPixel.nmb * 100).toFixed(2)} %`;
		var txt3 = `R: ${aeroval.statsForSelectedPixel.r.toFixed(2)}`;
	}

	// make sure the rendering div is available
	if (!document.getElementById(div)) return;

	Highcharts.chart(
		{
			chart: {
				renderTo: div,
				type: 'scatter',
				height,
				width,
				spacingLeft,
				spacingBottom,
				zooming: { type: 'xy' },
			},
			boost: {
				useGPUTranslations: true,
				usePreallocated: true,
			},
			exporting: {
				enabled: false,
			},
			title: {
				text: titleText,
			},
			subtitle: {
				text: subTitleText,
			},
			xAxis,
			yAxis,
			tooltip: {
				pointFormatter: function () {
					return `<b>${this.name}</b>`;
				},
			},
			plotOptions: {
				series: {
					allowPointSelect: true,
					color: '#a0a0a0',
					marker: {
						radius: 2,
					},
					cursor: 'pointer',
					point: {
						events: {
							click: function () {
								location.href = `/pages/evaluation/?project=${aeroval.project}&experiment=${aeroval.experiment.name}&station=${this.name.split('-')[0]
									}&parameter=${selectedPixel.var}&observation=${selectedPixel.obs}&model=${selectedPixel.mod}&layer=${selectedPixel.layer}`;
							},
							mouseOver: function () {
								if ((this.series as any).halo) {
									(this.series as any).halo
										.attr({
											class: 'highcharts-tracker',
										})
										.toFront();
								}
							},
						},
					},
					turboThreshold: 0,
					boostThreshold,
				},
			},
			series: [
				{
					name: `${aeroval.cfg.time_cfg.main_freq} data`,
					data: series_data,
					id: 'idx',
					color,
					showInLegend: false,
					animation: false,
				},
			] as any,
		},
		function (chart: Highcharts.Chart) {
			//on complete
			if (preview && turbo_mode) {
				chart.renderer
					.text('Preview mode: </br> ' + boostThreshold + ' first points only', chart.plotLeft + 0, 25)
					.css({
						fontSize: '12px',
						fontWeight: 'bold',
					})
					.attr({
						zIndex: 99,
					})
					.add();
			} else {
				chart.renderer
					.text(txt0, chart.plotLeft + 20, 80)
					.css({
						fontSize: '12px',
						fontWeight: 'bold',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text(txt1, chart.plotLeft + 20, 95)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text(txt2, chart.plotLeft + 20, 110)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();
				chart.renderer
					.text(txt3, chart.plotLeft + 20, 125)
					.css({
						fontSize: '12px',
					})
					.attr({
						zIndex: 99,
					})
					.add();

				// add Guidelines if not preview
				if (!preview) {
					addGuidelines(chart);
				}
				if (turbo_mode) {
					chart.redraw();
				}
			}
		}
	);
	document.body.style.cursor = 'default';
}

function getTitle(): string {
	var title = `${aeroval.region} - ${aeroval.time}`;
	if (aeroval.season !== 'All') {
		title += ` (${aeroval.season})`;
	}
	return title;
}
