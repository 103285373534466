/*import interfaces*/
import { Aeroval } from '../../types/global';
declare var aeroval: Aeroval;

import { globeJS } from '../../components/globe';

import aerovalAnimation from '../../assets/images/logo/full_logo_animated.svg';

export function toggleAnimation(value: string): void {
    aeroval.settings.animation = value;
}

export function showAnimation(theme: Aeroval['theme'], delay: number, fade: number): void {

    const height = window.screen.width <= 360 ? 50 : 100;
    const isDarkTheme = theme === 'dark';
    const bgColor = isDarkTheme ? '#282b34' : '#FFFFFF'; // Replace with your custom background colors for dark and light themes.

    const h = `
        <div class="row d-flex justify-content-center align-items-center h-100 w-100" style="z-index: 10; position: absolute; margin: 0;">
            <object 
                type="image/svg+xml" 
                data=${aerovalAnimation} 
                style="height: ${height}px; display: block; margin: auto; transform: translateY(-60px);}" 
                id="svg-animation">
            </object>
        </div>`;

    const animationElement = document.querySelector('#animation');
    if (animationElement instanceof HTMLElement) {
        animationElement.innerHTML = h;

        // Wait for the SVG to load
        const svgObject = animationElement.querySelector('#svg-animation') as HTMLObjectElement;
        svgObject.onload = () => {
            const svgDoc = svgObject.contentDocument; // Access the SVG's internal document
            if (svgDoc) {
                const svgElement = svgDoc.querySelector('svg'); // Select the root <svg> element
                if (svgElement) {
                    svgElement.style.backgroundColor = bgColor; // Set the background color
                    if (theme === 'dark') {
                        const elementToInvert = svgElement.querySelector('#evp4yo5ipsm2');
                        if (elementToInvert) {
                            (elementToInvert as HTMLElement).style.filter = 'invert(1)';
                        };
                    }
                }
            }
        };
        setTimeout(function () {
            animationElement.classList.add('fadeOut');
        }, delay - fade);

        setTimeout(function () {
            globeJS();
        }, delay - 100);

        setTimeout(function () {
            const contentMainIndexElement = document.querySelector('#index');
            if (contentMainIndexElement instanceof HTMLElement) {
                contentMainIndexElement.setAttribute('style', 'min-height: calc(100% - 250px); visibility: visible');
            }

            animationElement.classList.remove('d-flex', 'justify-content-center', 'align-items-center');
            animationElement.style.display = 'none';
        }, delay + fade);
    }
}